import { MailFilled, MailOutlined } from "@ant-design/icons";
import { Typography, Input } from "@thepiquelab/archus-components-web";
import React, { useState } from "react";
import { colors } from "../constant";
import { Radio, Space } from "antd";
import { RadioChangeEvent } from "antd/lib/radio";
import { NodeProps } from "./StartNode/Node";

function Message({ nodeDatum }: NodeProps) {
  const isfailMessageNode = (
    nodeDatum.attributes?.childrenType as string
  )?.includes("failNode");
  const [value, setValue] = useState(isfailMessageNode ? 3 : 1);
  const { TextArea } = Input;
  const onChange = (e: RadioChangeEvent) => {
    console.log("radio checked", e.target.checked);
    setValue(e.target.value);
  };
  const values = [
    {
      title: "✅ Can Attend",
      value: 1,
    },
    {
      title: "⚠️ Can Attend (Conditional)",
      value: 2,
    },
    {
      title: "❌ Not Eligible",
      value: 3,
    },
  ];
  return (
    <div
      className="flex flex-col p-4 border-default border-gray-400 rounded-default mt-4"
      style={{ width: "448px", height: "262px" }}
    >
      <div className="flex flex-row mb-2">
        <MailFilled style={{ color: colors.primary.blue }} />
        <Typography.Heading level={4} className="c-ml-2">
          Message
        </Typography.Heading>
      </div>
      <Space direction="vertical">
        {values.map((item) => {
          return (
            <div className="flex flex-row py-1">
              <div
                onClick={() => {
                  setValue(item.value);
                }}
                className={`w-4 h-4 rounded-full mr-3 ${
                  value === item.value
                    ? "border-4 border-primary-blue"
                    : "bg-primary-mainbg"
                }`}
              />
              <Typography.Text>{item.title}</Typography.Text>
            </div>
          );
        })}
        <TextArea
          rows={4}
          className={"c-border c-rounded-lg c-bg-gray-300 c-mt-3"}
          placeholder="This student is in {Level} this year."
          maxLength={100}
        />
      </Space>
    </div>
  );
}

export default Message;
