import { observer } from "mobx-react";
import React from "react";
import StartNode from "./StartNode/Node";
import Tree from "react-d3-tree";
import { CustomNodeElementProps } from "react-d3-tree/lib/types/types/common";
import { useAppStore } from "../mobx";
import { isEmpty } from "lodash";

function Flow() {
  const { currentNodes } = useAppStore();
  const drawStepPath = function (linkData: any, orientation: any) {
    var source = linkData.source,
      target = linkData.target;
    var deltaY = target.y - source.y;
    return "M"
      .concat(source.x, ",")
      .concat(source.y, " V")
      .concat(source.y + deltaY / 2, " H")
      .concat(target.x, " V")
      .concat(target.y);
  };
  const renderForeignObjectNode = ({
    nodeDatum,
    toggleNode,
    foreignObjectProps,
  }: CustomNodeElementProps & {
    foreignObjectProps: { width: number; height: number };
  }) => {
    let foreignObjectProps1 = {
      ...foreignObjectProps,
      x: -230,
      y: -100,
    };
    if (
      !isEmpty(nodeDatum.attributes?.childrenType) &&
      (nodeDatum.attributes?.childrenType as string).includes("Node") &&
      isEmpty(nodeDatum.attributes?.type)
    ) {
      foreignObjectProps1 = {
        width: 480,
        height: 330,
        x: -230,
        y: -100,
      };
    }
    return (
      <g>
        <foreignObject {...foreignObjectProps1}>
          <StartNode nodeDatum={nodeDatum} />
        </foreignObject>
      </g>
    );
  };
  const nodeSize = { x: 480, y: 250 };
  const foreignObjectProps = {
    width: nodeSize.x,
    height: nodeSize.y,
    x: -230,
    y: -100,
  };
  return (
    <div
      id="treeWrapper"
      className="flex flex-col items-center pt-8 w-screen h-screen"
    >
      <Tree
        data={currentNodes}
        centeringTransitionDuration={800}
        // dimensions={{ width: 1000, height: 1000 }}
        orientation="vertical"
        translate={{ x: 450, y: 150 }}
        collapsible={false}
        pathFunc={drawStepPath}
        separation={{ siblings: 4, nonSiblings: 4 }}
        depthFactor={340}
        renderCustomNodeElement={(rd3tProps: CustomNodeElementProps) =>
          renderForeignObjectNode({ ...rd3tProps, foreignObjectProps })
        }
      />
    </div>
  );
}

export default observer(Flow);
