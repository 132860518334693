import { Typography } from "@thepiquelab/archus-components-web";
import { observer } from "mobx-react";
import React from "react";

function ContentTitle() {
  return (
    <div className="flex flex-row items-center">
      <img src="P4.svg" className="w-8 h-8 mr-2" />
      <Typography.Heading level={2}>
        P4 Grade Accelerator Program
      </Typography.Heading>
    </div>
  );
}

export default observer(ContentTitle);
