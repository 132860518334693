import { Typography } from "@thepiquelab/archus-components-web";
import { observer } from "mobx-react";
import React from "react";

function StartMessage() {
  return (
    <div
      className="border-dashed border-primary-navyLight rounded-default p-4 flex flex-col justify-center items-center mt-4"
      style={{ borderWidth: "1px", borderColor: "rgba(232, 235, 241, 1)" }}
    >
      <Typography.Heading level={3}>Start flow</Typography.Heading>
      <Typography.Text
        size="middle"
        className="c-mt-4 c-text-primary-navyLight"
      >
        Click the settings icon to select a starting node.
      </Typography.Text>
    </div>
  );
}

export default observer(StartMessage);
