import {
  CheckCircleFilled,
  CloseCircleFilled,
  FlagFilled,
  SettingFilled,
} from "@ant-design/icons";
import { Typography } from "@thepiquelab/archus-components-web";
import { observer } from "mobx-react";
import React, { useEffect, useMemo, useState } from "react";
import { colors } from "../../constant";
import { FlowNodeType } from "../../generated/graphql";
import { useAppStore } from "../../mobx";
import { LevelType } from "../../mobx/app.store";
import LevelCard from "../LevelCard/LevelCard";
import StartMessage from "../StartMessage";
import uuid from "react-uuid";
import { isEmpty } from "lodash";
import { Dropdown, MenuProps } from "antd";
import "./Node.less";
import { TreeNodeDatum } from "react-d3-tree/lib/types/types/common";
import Message from "../Message";

const items: MenuProps["items"] = [
  {
    label: LevelType.Level,
    key: FlowNodeType.Level,
  },
  {
    label: LevelType.Enrollment,
    key: FlowNodeType.Enrollment,
  },
];
export interface NodeProps {
  nodeDatum: TreeNodeDatum;
}
function Node({ nodeDatum }: NodeProps) {
  const {
    setLevelDropDown,
    showLevelDropDown,
    currentNodes,
    updateNodeWithType,
    searchTree,
    levels,
    enrollments,
  } = useAppStore();
  const [currentId, setCurrentId] = useState("");
  useEffect(() => {
    if (isEmpty(currentId)) {
      setCurrentId(uuid());
    }
  }, [currentId]);
  // const marginLeft = `-${260 * childrens + 1}px`;
  const handleMenuClick: MenuProps["onClick"] = (e) => {
    // message.info("Click on menu item.");
    console.log("click", e);
    updateNodeWithType(nodeDatum, e.key as FlowNodeType);
    // updateNode(node, [{ academicLevelId: e.key }]);
  };
  const menuProps = {
    items,
    onClick: handleMenuClick,
  };
  const isMessageNode =
    !isEmpty(nodeDatum.attributes?.childrenType) &&
    (nodeDatum.attributes?.childrenType as string)?.includes("Node") &&
    isEmpty(nodeDatum.attributes?.type);
  const isPassMessageNode = (
    nodeDatum.attributes?.childrenType as string
  )?.includes("passNode");
  const isfailMessageNode = (
    nodeDatum.attributes?.childrenType as string
  )?.includes("failNode");
  const title = useMemo(() => {
    if (nodeDatum.name === "start") {
      return "Start";
    } else {
      const parentInfo = searchTree(
        currentNodes,
        nodeDatum?.attributes?.parentName as string
      );
      let key = parentInfo?.attributes?.levelCheckExpressions ?? "";
      let node = levels.find((i) => i.key === key);
      let value = node?.label ?? "";
      if (parentInfo?.attributes?.type === "Enrollment") {
        let key = parentInfo?.attributes?.enrollmentCheckExpressions ?? "";
        node = enrollments.find((i) => i?.key === key);
        value = node?.label ?? "";
      }
      return isfailMessageNode ? `Not ${value}` : value;
    }
  }, [currentNodes, nodeDatum, isfailMessageNode]);
  return (
    <div className="flex flex-col items-center">
      <div
        className="flex bg-white rounded-default p-4 flex-col"
        style={{ width: "480px" }}
        id={currentId}
      >
        <div className="flex flex-row justify-between flex-1">
          <div className="flex flex-row items-center">
            {nodeDatum.name === "start" && (
              <FlagFilled style={{ color: colors.primary.red }} />
            )}
            {isPassMessageNode && (
              <CheckCircleFilled style={{ color: colors.semantics.green }} />
            )}
            {isfailMessageNode && (
              <CloseCircleFilled style={{ color: colors.semantics.red }} />
            )}
            <Typography.Heading level={4} className="ml-2">
              {title}
            </Typography.Heading>
          </div>
          <div>
            <Dropdown menu={menuProps}>
              <SettingFilled
                style={{ color: colors.primary.navy }}
                onClick={() => {
                  setLevelDropDown(!showLevelDropDown);
                }}
              />
            </Dropdown>
          </div>
        </div>
        {!isEmpty(nodeDatum?.attributes?.type) && (
          <LevelCard nodeDatum={nodeDatum} />
        )}
        {nodeDatum.name === "start" && isEmpty(nodeDatum?.attributes?.type) && (
          <StartMessage />
        )}
        {!isEmpty(nodeDatum.attributes?.childrenType) &&
          (nodeDatum.attributes?.childrenType as string).includes("Node") &&
          isEmpty(nodeDatum.attributes?.type) && (
            <Message nodeDatum={nodeDatum} />
          )}
      </div>
    </div>
  );
}

export default observer(Node);
