import { isEmpty, omit } from "lodash";
import { toJS } from "mobx";
import { RawNodeDatum } from "react-d3-tree/lib/types/types/common";
import uuid from "react-uuid";
import {
  EligibilityFlowEnrollmentComparatorType,
  FlowNodeInput,
  FlowNodeType,
  LogicalOperator,
  ResultOutcome,
} from "../generated/graphql";
export const changeType = (
  attributes: Record<string, string | number | boolean>,
  type: string
) => {
  attributes.type = type;
  if (attributes.type === "Level") {
    attributes.levelCheckExpressions = "";
  }
  if (attributes.type === "Enrollment") {
    attributes.enrollmentCheckExpressions = "";
  }
  return attributes;
};
export const changeLevel = (
  attributes: Record<string, string | number | boolean>,
  key: string,
  type: string
) => {
  attributes[key] = type;

  return attributes;
};
export const changeValueWithType = (
  obj: RawNodeDatum,
  name: string,
  type: string
) => {
  if (typeof obj === "object") {
    if (obj.name === name) {
      obj.attributes = changeType(obj?.attributes ?? {}, type);
      obj.children = [];

      return obj;
    }
    if (isEmpty(obj.children)) {
      obj.children = [];
      return obj;
    }
    for (let index = 0; index < (obj.children?.length ?? 0); index++) {
      const chidren = obj?.children ?? [];
      const element = chidren[index];
      if (element.name === name) {
        element.attributes = changeType(element.attributes ?? {}, type);
      } else {
        changeValueWithType(element, name, type);
      }
    }
  }
  return obj;
};
export const changeValueWithLevel = (
  obj: RawNodeDatum,
  key: string,
  name: string,
  level: string
) => {
  if (typeof obj === "object") {
    if (obj.name === name) {
      obj.attributes = changeLevel(obj?.attributes ?? {}, key, level);
      if (
        key === "levelCheckExpressions" ||
        key === "enrollmentCheckExpressions"
      ) {
        obj.children = [
          {
            name: uuid(),
            children: [],
            attributes: {
              type: "",
              childrenType: "passNode",
              levelCheckExpressions: "",
              enrollmentCheckExpressions: "",
              parentName: obj.name,
            },
          },
          {
            name: uuid(),
            children: [],
            attributes: {
              type: "",
              childrenType: "failNode",
              levelCheckExpressions: "",
              enrollmentCheckExpressions: "",
              parentName: obj.name,
            },
          },
        ];
      }
      return obj;
    }
    if (isEmpty(obj.children)) {
      return obj;
    }
    for (let index = 0; index < (obj.children?.length ?? 0); index++) {
      const chidren = obj?.children ?? [];
      const element = chidren[index];
      if (element.name === name) {
        element.attributes = changeLevel(element.attributes ?? {}, key, level);
        if (
          key === "levelCheckExpressions" ||
          key === "enrollmentCheckExpressions"
        ) {
          element.children = [
            {
              name: uuid(),
              children: [],
              attributes: {
                type: "",
                childrenType: "passNode",
                levelCheckExpressions: "",
                enrollmentCheckExpressions: "",
                parentName: element.name,
              },
            },
            {
              name: uuid(),
              children: [],
              attributes: {
                type: "",
                childrenType: "failNode",
                levelCheckExpressions: "",
                enrollmentCheckExpressions: "",
                parentName: element.name,
              },
            },
          ];
        }
      } else {
        changeValueWithLevel(element, key, name, level);
      }
    }
  }
  return obj;
};
export const convertNodesToParams = (obj: RawNodeDatum) => {
  let newObject: any = toJS(obj);
  if (typeof obj === "object") {
    const attributes = obj.attributes;
    newObject = omit(newObject, "attributes", "children", "name");

    if (attributes?.type === "Level") {
      newObject.logicalOperator = LogicalOperator.Or;
      newObject.type = attributes?.type;
      newObject.levelCheckExpressions = [
        { academicLevelId: obj.attributes?.levelCheckExpressions },
      ];
    }
    if (attributes?.type === "Enrollment") {
      newObject.logicalOperator = LogicalOperator.Or;
      newObject.type = attributes?.type;
      newObject.enrollmentCheckExpressions = [
        { type: obj.attributes?.enrollmentCheckExpressions },
      ];
    }

    const passNode = obj.children?.find(
      (i) => i.attributes?.childrenType === "passNode"
    );
    const failNode = obj.children?.find(
      (i) => i.attributes?.childrenType === "failNode"
    );
    if (passNode) {
      newObject.passNode = convertNodesToParams(passNode);
    }
    if (failNode) {
      newObject.failNode = convertNodesToParams(failNode);
    }
    if (
      obj.attributes?.childrenType === "failNode" ||
      obj.attributes?.childrenType === "passNode"
    ) {
      if (isEmpty(newObject.children)) {
        newObject.type = FlowNodeType.Result;
      }
      newObject.result = {
        message: "test",
        outcome: ResultOutcome.CanAttend,
      };
    }
    if (!isEmpty(obj.attributes?.type)) {
      newObject.type = obj.attributes?.type;
    }
  }

  console.log("newObject====================================");
  console.log(newObject);
  console.log("====================================");
  return newObject;
};
