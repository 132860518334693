import { CaretDownOutlined } from "@ant-design/icons";
import { Typography, Button } from "@thepiquelab/archus-components-web";
import React, { useMemo, useState } from "react";
import { Dropdown, message, Space, Tooltip, MenuProps } from "antd";
import { colors } from "../../constant";
import { useAppStore } from "../../mobx";
import { isEmpty } from "lodash";
import { TreeNodeDatum } from "react-d3-tree/lib/types/types/common";
import { EligibilityFlowEnrollmentComparatorType } from "../../generated/graphql";
interface LevelCardProps {
  nodeDatum: TreeNodeDatum;
}

function LevelCard({ nodeDatum }: LevelCardProps) {
  const {
    updateNodeWithLevelId,
    levels,
    enrollments,
    updateNodeWithEnrollmentId,
  } = useAppStore();
  const [levelId, setLevelId] = useState("");

  const items: MenuProps["items"] = [...levels];
  const items1: MenuProps["items"] = [...enrollments];

  const handleMenuClick: MenuProps["onClick"] = (e) => {
    // message.info("Click on menu item.");
    console.log("click", e);
    if (nodeDatum.attributes?.type === "Level") {
      updateNodeWithLevelId(nodeDatum, e.key);
    }
    if (nodeDatum.attributes?.type === "Enrollment") {
      updateNodeWithEnrollmentId(nodeDatum, e.key);
    }
    // updateNode(node, [{ academicLevelId: e.key }]);
  };
  // const menuProps = {
  //   items,
  //   onClick: handleMenuClick,
  // };
  const menuProps = useMemo(() => {
    if (nodeDatum.attributes?.type === "Level") {
      return {
        items,
        onClick: handleMenuClick,
      };
    }
    if (nodeDatum.attributes?.type === "Enrollment") {
      return {
        items: items1,
        onClick: handleMenuClick,
      };
    }
    return {
      items,
      onClick: handleMenuClick,
    };
  }, [nodeDatum]);
  const value = useMemo(() => {
    let key = nodeDatum?.attributes?.levelCheckExpressions ?? "";
    let node = levels.find((i) => i.key === key);
    let value = node?.label ?? "";
    if (nodeDatum.attributes?.type === "Enrollment") {
      let key = nodeDatum?.attributes?.enrollmentCheckExpressions ?? "";
      node = enrollments.find((i) => i?.key === key);
      value = node?.label ?? "";
    }
    if (isEmpty(value)) {
      value = `Select ${nodeDatum?.attributes?.type}`;
    }
    return value;
  }, [nodeDatum, levels, enrollments]);

  return (
    <div className="flex flex-col">
      <div
        style={{ width: "448px" }}
        className="flex flex-col p-4 border-default border-gray-400 rounded-default mt-4"
      >
        <div className="flex flex-row items-center">
          <img src="level.svg" />
          <Typography.Heading level={4} className="c-ml-2">
            {nodeDatum.attributes?.type === "Level" && "Level"}
            {nodeDatum.attributes?.type === "Enrollment" && "Enrollment"}
          </Typography.Heading>
        </div>
        <div className="flex flex-row items-center mt-4 justify-between">
          <Typography.Heading level={4} className="c-mr-2">
            Equal to
          </Typography.Heading>
          <Dropdown
            overlayStyle={{ maxHeight: "200px" }}
            autoAdjustOverflow={true}
            menu={menuProps}
          >
            <div className="bg-gray-400 rounded-lg px-4">
              <Space
                style={{ width: "300px" }}
                className="flex flex-row justify-between h-12"
              >
                <Typography.Text
                  size="middle"
                  className="c-text-primary-navyLight"
                >
                  {value}
                </Typography.Text>
                <CaretDownOutlined style={{ color: colors.primary.navy }} />
              </Space>
            </div>
          </Dropdown>
        </div>
      </div>
    </div>
  );
}

export default LevelCard;
