import React, { useEffect } from "react";
import "./App.less";
import ToolHeader from "./components/ToolHeader";
import ToolContent from "./components/ToolContent";
import { observer } from "mobx-react";
import createApolloClient from "./ApolloClient";
import { ApolloProvider } from "@apollo/client";
import { enableLogging } from "mobx-logger";
import { useGetAcademicLevelsLazyQuery } from "./generated/graphql";
enableLogging({ action: true, reaction: true });
const token =
  "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IlBMY2xEOFFuUnpqMFBZOEN1OEFFSiJ9.eyJodHRwczovL2VycC50aGVwaWxhYi5jb20vcm9sZXMiOlsiU1VQRVJfQURNSU4iLCJBRE1JTiJdLCJpc3MiOiJodHRwczovL3RoZXBpbGFiLmF1LmF1dGgwLmNvbS8iLCJzdWIiOiJhdXRoMHw2MjY4YWI2YzVhNGZmZDAwNjk1OGExZWIiLCJhdWQiOlsiaHR0cHM6Ly9lcnAudGhlcGlsYWIuY29tIiwiaHR0cHM6Ly90aGVwaWxhYi5hdS5hdXRoMC5jb20vdXNlcmluZm8iXSwiaWF0IjoxNjczOTM5MjM1LCJleHAiOjE2NzQwMjU2MzUsImF6cCI6Im1qREZkd2lpUFNqVE41cGZudXNEUHpQU1dGMHNmNnBhIiwic2NvcGUiOiJvcGVuaWQgcHJvZmlsZSBlbWFpbCBvZmZsaW5lX2FjY2VzcyIsIm9yZ19pZCI6Im9yZ184RXFCYTlpNXhDVkVueWN1IiwicGVybWlzc2lvbnMiOlsiYWRtaW4iLCJicmFuY2g6YWRtaW4iLCJjZW50cmU6YWRtaW4iXX0.Pz0nIXRhjemVi6v3Q9sZw8b3HrX1l4oV7CVs1oej0RU6k5EGj_OxfGleWQAC3LgEXxLg1qKJY0J4Dwd5KuvTD6rYUrORUgpMLw8n3WSxQ9MDalwgeeHjBElHJEtrFtcsybbyJp8HoXroyE3Jg8DMQxXGi1nYAhY4_wGH84jaTSVzbnps7d6v-aFGXBPQkwfhR0o7eDMJ63mtqKchamwBrzp_7DDg7FBGrPEhn1xpJtXByLd7Ewx4t1xlsjYNb4Wyv-TEUK8mi6kuNXrDpagc96Bx1XcthYXzUNaIWK6-Ims_8wHe-9u7HNCKrujMHRIgjtqH4feSd4dHfPVerdmsVA";
function App() {
  return (
    <ApolloProvider client={createApolloClient(token)}>
      <div className="App">
        <ToolHeader />
        <ToolContent />
      </div>
    </ApolloProvider>
  );
}

export default observer(App);
