import React from "react";
import mainStore from "./mainStore";

//创建rootStore的Context
export const rootStoreContext = React.createContext(mainStore);

export const useAppStore = () => {
  const rootStore = React.useContext(rootStoreContext);
  return rootStore.appStore;
};
