import { Button, Typography } from "@thepiquelab/archus-components-web";
import { isEmpty } from "lodash";
import { observer } from "mobx-react";
import React, { useMemo } from "react";
import { useCreateEligibilityFlowMutation } from "../generated/graphql";
import { useAppStore } from "../mobx";
import { convertNodesToParams } from "../mobx/utils";

function ToolHeader() {
  const { currentNodes, currentCourseId } = useAppStore();
  const [createEligibilityFlow] = useCreateEligibilityFlowMutation();
  const disabled = isEmpty(currentNodes.children);
  return (
    <div className="flex flex-col" style={{ marginBottom: "34px" }}>
      <div
        className="flex flex-shrink-0 flex-row"
        style={{ minWidth: "415px" }}
      >
        <Typography.Text>{`${"P4 Grade Accelerator Program"}${" > "}`}</Typography.Text>
        <Typography.Heading
          level={3}
          style={{ marginTop: 0, marginLeft: "8px" }}
        >{`${"Course Eligibility"}`}</Typography.Heading>
      </div>
      <div className="flex flex-shrink-0 flex-row mt-5 justify-between">
        <Typography.Heading
          level={1}
          style={{ marginTop: 0, minWidth: "415px" }}
        >
          Manage Course Eligibility Flow
        </Typography.Heading>
        <div className="flex flex-row">
          <Button size="middle" className="c-mr-2">
            Reset
          </Button>
          <Button disabled={disabled} size="middle" className="c-mr-2">
            Test Flow
          </Button>
          <Button
            disabled={disabled}
            onClick={async () => {
              const parems = convertNodesToParams(currentNodes);
              await createEligibilityFlow({
                variables: {
                  input: {
                    courseId: currentCourseId,
                    startNode: parems,
                  },
                },
              });
            }}
            type="primary"
            size="middle"
            className="c-mr-2"
          >
            Save Flow
          </Button>
        </div>
      </div>
    </div>
  );
}

export default observer(ToolHeader);
