import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: any;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
  /** The `Decimal` scalar type to represent currency values */
  Decimal: any;
  /** Json object scalar type */
  Object: any;
  /** Mongo object id scalar type */
  ObjectId: any;
  /** A time string at UTC, such as 10:15:30Z, compliant with the `full-time` format outlined in section 5.6 of the RFC 3339profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Time: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type AcademicLevel = {
  __typename?: 'AcademicLevel';
  _id: Scalars['ObjectId'];
  alias: Scalars['String'];
  classes?: Maybe<Array<Class>>;
  courses?: Maybe<Array<Course>>;
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  educationStage: EducationStage;
  isArchived: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  isVisible: Scalars['Boolean'];
  lastModifiedBy?: Maybe<User>;
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  lastModifiedUser?: Maybe<User>;
  name: Scalars['String'];
  nextLevel?: Maybe<AcademicLevel>;
  orderNo?: Maybe<Scalars['Float']>;
  seatsAnalyticsByAcademicLevel?: Maybe<SeatAnalytics>;
  systemId?: Maybe<Scalars['String']>;
};


export type AcademicLevelClassesArgs = {
  filterInput?: InputMaybe<ClassFilterInput>;
};


export type AcademicLevelCoursesArgs = {
  type?: InputMaybe<Array<CourseType>>;
};


export type AcademicLevelSeatsAnalyticsByAcademicLevelArgs = {
  filterInput?: InputMaybe<ClassFilterInput>;
};

export type AcademicWeek = {
  __typename?: 'AcademicWeek';
  endDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
  weekNumber: Scalars['Float'];
};

export type AcademicWeekData = {
  __typename?: 'AcademicWeekData';
  selectionData?: Maybe<Array<SelectionData>>;
};

export type AcademicWeekInput = {
  weekNumber: Scalars['Int'];
};

export type AcademicYear = {
  __typename?: 'AcademicYear';
  _id: Scalars['ObjectId'];
  academicWeekEnd: WeekDay;
  academicWeekStart: WeekDay;
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  groupAssignment?: Maybe<GroupAssignment>;
  holidays?: Maybe<Array<Holiday>>;
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<User>;
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  lastModifiedUser?: Maybe<User>;
  period: DateRange;
  term?: Maybe<Term>;
  terms?: Maybe<Array<Term>>;
  year: Scalars['Int'];
};


export type AcademicYearTermArgs = {
  academicLevelId: Scalars['ObjectId'];
};

export type AccessDevice = {
  __typename?: 'AccessDevice';
  id?: Maybe<Scalars['String']>;
  ip?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
};

export type Account = {
  __typename?: 'Account';
  code: Scalars['String'];
  name: Scalars['String'];
};

export type AccountInput = {
  code: Scalars['String'];
  name: Scalars['String'];
};

export enum AccountType {
  Bank = 'BANK',
  Current = 'CURRENT',
  Currliab = 'CURRLIAB',
  Depreciatn = 'DEPRECIATN',
  Directcosts = 'DIRECTCOSTS',
  Equity = 'EQUITY',
  Expense = 'EXPENSE',
  Fixed = 'FIXED',
  Inventory = 'INVENTORY',
  Liability = 'LIABILITY',
  Noncurrent = 'NONCURRENT',
  Otherincome = 'OTHERINCOME',
  Overheads = 'OVERHEADS',
  Payg = 'PAYG',
  Paygliability = 'PAYGLIABILITY',
  Prepayment = 'PREPAYMENT',
  Revenue = 'REVENUE',
  Sales = 'SALES',
  Superannuationexpense = 'SUPERANNUATIONEXPENSE',
  Superannuationliability = 'SUPERANNUATIONLIABILITY',
  Termliab = 'TERMLIAB',
  Wagesexpense = 'WAGESEXPENSE'
}

export type Activities = {
  isExercise?: InputMaybe<Scalars['Boolean']>;
  isHomework?: InputMaybe<Scalars['Boolean']>;
};

export type AddAttendanceRemarksInput = {
  attendanceId: Scalars['ObjectId'];
  remarks: Scalars['String'];
};

export type AddInvoiceLineItemInput = {
  invoiceId: Scalars['ObjectId'];
  lineItems: Array<LineItemInput>;
};

export type AddRewardCatalogueInput = {
  avatar: Scalars['String'];
  category: Scalars['String'];
  description: Scalars['String'];
  galleries?: InputMaybe<Array<Scalars['String']>>;
  isVisible: Scalars['Boolean'];
  name: Scalars['String'];
  points: Scalars['Int'];
  stock: Scalars['Int'];
};

/** Reward points input to add for one student item */
export type AddRewardPointsInput = {
  /** Linked gradable (Exercise or Homework) ID if points are from award */
  gradableId?: InputMaybe<Scalars['ObjectId']>;
  /** Amount of reward points */
  points: Scalars['Float'];
  /** Other notes associated with the reward transaction */
  remarks?: InputMaybe<Scalars['String']>;
  /** Student ID on where reward points will be added */
  studentId: Scalars['ObjectId'];
  /** Type of reward history */
  type?: InputMaybe<RewardHistoryType>;
};

export type AddXeroAccountInput = {
  code: Scalars['String'];
  name: Scalars['String'];
  type: AccountType;
};

export type Address = {
  __typename?: 'Address';
  country?: Maybe<Scalars['String']>;
  line1: Scalars['String'];
  link?: Maybe<Scalars['String']>;
  postalCode: Scalars['String'];
  unit?: Maybe<Scalars['String']>;
};

export type AddressInput = {
  country?: InputMaybe<Scalars['String']>;
  line1: Scalars['String'];
  link?: InputMaybe<Scalars['String']>;
  postalCode: Scalars['String'];
  unit?: InputMaybe<Scalars['String']>;
};

export type Admin = {
  __typename?: 'Admin';
  _id: Scalars['ObjectId'];
  alias?: Maybe<Scalars['String']>;
  alternateUserId?: Maybe<Scalars['String']>;
  avatarUrl?: Maybe<Scalars['String']>;
  branches?: Maybe<Array<Branch>>;
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  department?: Maybe<Department>;
  designation?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  fullName: Scalars['String'];
  gender?: Maybe<Scalars['String']>;
  initials?: Maybe<Scalars['String']>;
  isArchived: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<User>;
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  lastModifiedUser?: Maybe<User>;
  linkUser?: Maybe<User>;
  mobile: Telephone;
  officePhone?: Maybe<Telephone>;
  salutation: Scalars['String'];
  userId?: Maybe<Scalars['String']>;
};

export type AdminFilterInput = {
  branchIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  departmentIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  mobile?: InputMaybe<TelephoneInput>;
  name?: InputMaybe<Scalars['String']>;
};

export type AdminPage = {
  __typename?: 'AdminPage';
  items: Array<Admin>;
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export type Allocation = {
  __typename?: 'Allocation';
  _id: Scalars['ObjectId'];
  adminFee: Scalars['Decimal'];
  amount: Scalars['Decimal'];
  balance: Scalars['Decimal'];
  bankAccountNumber?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  chequeNumber?: Maybe<Scalars['String']>;
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  isDeleted: Scalars['Boolean'];
  isVoid?: Maybe<Scalars['Boolean']>;
  lastDigits?: Maybe<Scalars['String']>;
  lastModifiedBy?: Maybe<User>;
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  lastModifiedUser?: Maybe<User>;
  linkedInvoice?: Maybe<Invoice>;
  method?: Maybe<Scalars['String']>;
  payment?: Maybe<Payment>;
  paymentProcessor?: Maybe<Scalars['String']>;
  processedDate?: Maybe<Scalars['DateTime']>;
  reference?: Maybe<Scalars['String']>;
  refundedDate?: Maybe<Scalars['DateTime']>;
  type: AllocationType;
};

export enum AllocationType {
  Refund = 'Refund',
  Used = 'Used'
}

export type AlternativeLessonsForChangeAttendingClassInput = {
  lessonId: Scalars['ObjectId'];
  studentId: Scalars['ObjectId'];
};

export type Applicable = {
  isApplicable: Scalars['Boolean'];
};

export type ApplyDiscountInput = {
  discountAmount?: InputMaybe<Scalars['Decimal']>;
  discountRate?: InputMaybe<Scalars['Decimal']>;
  discountType: DiscountType;
  lineItemId: Scalars['ObjectId'];
  remarks?: InputMaybe<Scalars['String']>;
};

export type ApproveRegistrationInput = {
  group?: InputMaybe<EnrollmentGroupType>;
  id: Scalars['ObjectId'];
  isChangeToWaitingList?: InputMaybe<Scalars['Boolean']>;
  preferredLearningArrangement?: InputMaybe<LearningArrangementType>;
};

export type Archivable = {
  isArchived: Scalars['Boolean'];
};

export type ArchiveFinanceInput = {
  id: Scalars['ObjectId'];
  isArchived: Scalars['Boolean'];
};

export type Arrangement = {
  __typename?: 'Arrangement';
  _id: Scalars['ObjectId'];
  amountUsed?: Maybe<Scalars['Decimal']>;
  cancelInfo?: Maybe<TransferCancelInfo>;
  cancellation?: Maybe<Array<Arrangement>>;
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  credit?: Maybe<Credit>;
  creditLeft?: Maybe<Scalars['Decimal']>;
  currentStep?: Maybe<Scalars['Int']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  emailStatus?: Maybe<ReminderStatus>;
  endLesson?: Maybe<Scalars['DateTime']>;
  enrollment?: Maybe<Enrollment>;
  enrollmentId?: Maybe<Scalars['ObjectId']>;
  fromClass?: Maybe<Class>;
  fromLesson?: Maybe<Lesson>;
  incompleteDeadline: Scalars['DateTime'];
  invoice?: Maybe<Invoice>;
  isCancelHasFullLesson?: Maybe<Scalars['Boolean']>;
  isCancellable?: Maybe<Scalars['Boolean']>;
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<User>;
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  lastModifiedUser?: Maybe<User>;
  lessonsNeedNotToPay?: Maybe<Array<Lesson>>;
  lessonsNeedToPay?: Maybe<Array<Lesson>>;
  linkRegistration?: Maybe<Registration>;
  makeUpCreditInfo?: Maybe<MakeUpCreditInfo>;
  name?: Maybe<Scalars['String']>;
  newEnrollment?: Maybe<Enrollment>;
  newEnrollmentId?: Maybe<Scalars['ObjectId']>;
  originInvoice?: Maybe<Invoice>;
  processedBy?: Maybe<User>;
  processedDateTime?: Maybe<Scalars['DateTime']>;
  remarks?: Maybe<Scalars['String']>;
  reminders?: Maybe<Array<Reminder>>;
  selectedLessons?: Maybe<Array<SelectedLesson>>;
  startLesson?: Maybe<Scalars['DateTime']>;
  status?: Maybe<ArrangementStatus>;
  student: Student;
  toClass?: Maybe<Class>;
  toLesson?: Maybe<Lesson>;
  type: ArrangementType;
  useCredits?: Maybe<Scalars['Boolean']>;
  waStatus?: Maybe<ReminderStatus>;
  willAttendLessons?: Maybe<Array<Lesson>>;
  withdrawReasons?: Maybe<WithdrawReasons>;
};

export type ArrangementFilterInput = {
  createdBy?: InputMaybe<Scalars['ObjectId']>;
  dateRange?: InputMaybe<DateTimeRangeInput>;
  fromClassIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Array<ArrangementStatus>>;
  studentIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  toClassIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  types?: InputMaybe<Array<ArrangementType>>;
};

export type ArrangementPage = {
  __typename?: 'ArrangementPage';
  items: Array<Arrangement>;
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export enum ArrangementStatus {
  Cancelled = 'Cancelled',
  Complete = 'Complete',
  Draft = 'Draft',
  Incomplete = 'Incomplete',
  PaymentOverdue = 'PaymentOverdue',
  PendingPayment = 'PendingPayment'
}

export enum ArrangementType {
  Additional = 'Additional',
  Makeup = 'Makeup',
  Transfer = 'Transfer',
  Withdrawals = 'Withdrawals'
}

export type Attendance = {
  __typename?: 'Attendance';
  _id: Scalars['ObjectId'];
  attendanceDateTime?: Maybe<Scalars['DateTime']>;
  class?: Maybe<Class>;
  classId: Scalars['ObjectId'];
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  eligibleArrangements?: Maybe<Array<ArrangementType>>;
  endDateTime: Scalars['DateTime'];
  enrollment?: Maybe<Enrollment>;
  group?: Maybe<EnrollmentGroupType>;
  isCollection?: Maybe<Scalars['Boolean']>;
  isDeleted: Scalars['Boolean'];
  isFirstLesson?: Maybe<Scalars['Boolean']>;
  isLastLesson?: Maybe<Scalars['Boolean']>;
  lastModifiedBy?: Maybe<User>;
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  lastModifiedUser?: Maybe<User>;
  leaveDateTime?: Maybe<Scalars['DateTime']>;
  lesson?: Maybe<Lesson>;
  lessonId: Scalars['ObjectId'];
  lessonMode?: Maybe<LearningArrangementType>;
  linkArrangement?: Maybe<Arrangement>;
  linkInvoice?: Maybe<Invoice>;
  linkedAttendance?: Maybe<Attendance>;
  previousLessonAttendance?: Maybe<Attendance>;
  remarks?: Maybe<Array<AttendanceRemark>>;
  startDateTime: Scalars['DateTime'];
  status?: Maybe<AttendanceStatus>;
  student?: Maybe<Student>;
  systemRemarks?: Maybe<Scalars['String']>;
  type: AttendanceType;
};

export type AttendanceRemark = {
  __typename?: 'AttendanceRemark';
  /** Unique identifier for the Attendance Remark record */
  _id?: Maybe<Scalars['ObjectId']>;
  /** Admin user that added the attendance remark */
  admin?: Maybe<Admin>;
  content: Scalars['String'];
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  isDeleted: Scalars['Boolean'];
  /** Boolean flag to indicate that record has been edited */
  isEdited?: Maybe<Scalars['Boolean']>;
  lastModifiedBy?: Maybe<User>;
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  lastModifiedUser?: Maybe<User>;
};

export enum AttendanceStatus {
  Absent = 'Absent',
  Cancelled = 'Cancelled',
  Holding = 'Holding',
  MakeupOut = 'MakeupOut',
  None = 'None',
  NotAttending = 'NotAttending',
  Present = 'Present'
}

export enum AttendanceType {
  Additional = 'Additional',
  MakeupIn = 'MakeupIn',
  Regular = 'Regular',
  Reservation = 'Reservation',
  TransferOut = 'TransferOut',
  Trial = 'Trial',
  Withdrawn = 'Withdrawn'
}

export type AuditLog = {
  __typename?: 'AuditLog';
  _id: Scalars['ObjectId'];
  accessDevice?: Maybe<AccessDevice>;
  changes?: Maybe<Array<Changes>>;
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<AuditLogDescriptionType>;
  isArchived: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<User>;
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  lastModifiedUser?: Maybe<User>;
  operationType: AuditLogOperationType;
  principal?: Maybe<User>;
  principalId: Scalars['ObjectId'];
  resourceId?: Maybe<Scalars['ObjectId']>;
  resourceName: Scalars['String'];
  resourceType?: Maybe<AuditLogResourceType>;
  timestamp?: Maybe<Scalars['DateTime']>;
};

export enum AuditLogDescriptionType {
  Approved = 'Approved',
  Archive = 'Archive',
  Cancelled = 'Cancelled',
  ChangeToRegistration = 'ChangeToRegistration',
  Link = 'Link',
  LinkRemove = 'LinkRemove',
  SetAttendance = 'SetAttendance',
  Unarchive = 'Unarchive',
  UpdateRecord = 'UpdateRecord',
  Void = 'Void'
}

export type AuditLogFilterInput = {
  dateTimeRange?: InputMaybe<DateRangeInput>;
  descriptions?: InputMaybe<Array<AuditLogDescriptionType>>;
  fieldNames?: InputMaybe<Array<Scalars['String']>>;
  operationTypes?: InputMaybe<Array<AuditLogOperationType>>;
  principalId?: InputMaybe<Scalars['ObjectId']>;
  principalType?: InputMaybe<Array<UserRole>>;
  resourceTypes?: InputMaybe<Array<AuditLogResourceType>>;
};

export enum AuditLogOperationType {
  Create = 'Create',
  Delete = 'Delete',
  Update = 'Update'
}

export type AuditLogPage = {
  __typename?: 'AuditLogPage';
  items: Array<AuditLog>;
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export enum AuditLogResourceType {
  Admin = 'Admin',
  Attendance = 'Attendance',
  Classes = 'Classes',
  Course = 'Course',
  Credit = 'Credit',
  Invoice = 'Invoice',
  Lessons = 'Lessons',
  Makeup = 'Makeup',
  Parameter = 'Parameter',
  Parent = 'Parent',
  Receipt = 'Receipt',
  Refund = 'Refund',
  Registration = 'Registration',
  Student = 'Student',
  Submission = 'Submission',
  Teacher = 'Teacher',
  Template = 'Template',
  Transfer = 'Transfer',
  Withdrawal = 'Withdrawal'
}

export type BalanceHistory = {
  __typename?: 'BalanceHistory';
  _id: Scalars['ObjectId'];
  amount: Scalars['Decimal'];
  balance: Scalars['Decimal'];
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  invoice?: Maybe<Invoice>;
  invoiceId: Scalars['ObjectId'];
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<User>;
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  lastModifiedUser?: Maybe<User>;
  parent: Parent;
  parentId: Scalars['ObjectId'];
  payment?: Maybe<Payment>;
  paymentId: Scalars['ObjectId'];
  refund?: Maybe<Refund>;
  refundId: Scalars['ObjectId'];
  type: BalanceHistoryType;
};

export type BalanceHistoryFilterInput = {
  parentId?: InputMaybe<Scalars['ObjectId']>;
  types?: InputMaybe<Array<BalanceHistoryType>>;
};

export type BalanceHistoryPage = {
  __typename?: 'BalanceHistoryPage';
  items: Array<BalanceHistory>;
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export enum BalanceHistoryType {
  BalanceRefund = 'BalanceRefund',
  FeePayment = 'FeePayment',
  Receipt = 'Receipt',
  VoidBalanceRefund = 'VoidBalanceRefund',
  VoidFeePayment = 'VoidFeePayment',
  VoidReceipt = 'VoidReceipt'
}

export enum BillingFrequency {
  Perclass = 'Perclass',
  Permonth = 'Permonth',
  Perterm = 'Perterm'
}

export type Branch = {
  __typename?: 'Branch';
  _id: Scalars['ObjectId'];
  address: Address;
  alias: Scalars['String'];
  centre: Centre;
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  isArchived: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<User>;
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  lastModifiedUser?: Maybe<User>;
  name: Scalars['String'];
  systemId?: Maybe<Scalars['String']>;
  venues?: Maybe<Array<Venue>>;
};


export type BranchVenuesArgs = {
  showArchived?: InputMaybe<Scalars['Boolean']>;
};

/** Reward points input to add for multiple student items */
export type BulkAddRewardPointsInput = {
  /** Linked gradable (Exercise or Homework) ID if points are from award */
  gradableId?: InputMaybe<Scalars['ObjectId']>;
  /** Multiple student items along with points to be added */
  items: Array<RewardPointsItemInput>;
  /** Other notes associated with the reward transaction */
  remarks?: InputMaybe<Scalars['String']>;
  /** Type of reward history */
  type?: InputMaybe<RewardHistoryType>;
};

/** Reward points input to deduct for multiple student items */
export type BulkDeductRewardPointsInput = {
  /** Linked gradable (Exercise or Homework) ID if points are from award */
  gradableId?: InputMaybe<Scalars['ObjectId']>;
  /** Multiple student items along with points to be deducted */
  items: Array<RewardPointsItemInput>;
  /** Reward order to redeem */
  orderDetail?: InputMaybe<RewardDetailInput>;
  /** Other notes associated with the reward transaction */
  remarks?: InputMaybe<Scalars['String']>;
  /** Type of reward history */
  type?: InputMaybe<RewardHistoryType>;
};

export type BulkSendReminderInput = {
  emailInput?: InputMaybe<EmailReminderInput>;
  invoiceIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  parentIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  types: Array<ReminderType>;
  whatsappInput?: InputMaybe<WhatsappReminderInput>;
};

export type CancellationEffects = {
  __typename?: 'CancellationEffects';
  creditNoteToReverse?: Maybe<Array<Credit>>;
  creditNotesToVoid?: Maybe<Array<Credit>>;
  invoicesToVoid?: Maybe<Array<Invoice>>;
  isCreateNewCreditNote?: Maybe<Scalars['Boolean']>;
  receiptsToVoid?: Maybe<Array<Receipt>>;
};

export enum CapacitySimulationType {
  Additional = 'Additional',
  Makeup = 'Makeup',
  Regular = 'Regular',
  Reservation = 'Reservation',
  TransferIn = 'TransferIn',
  TransferOut = 'TransferOut',
  Trial = 'Trial',
  Withdrawals = 'Withdrawals'
}

export type Centre = {
  __typename?: 'Centre';
  _id: Scalars['ObjectId'];
  branches?: Maybe<Array<Branch>>;
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  departments?: Maybe<Array<Department>>;
  isArchived: Scalars['Boolean'];
  isDefault: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<User>;
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  lastModifiedUser?: Maybe<User>;
};


export type CentreBranchesArgs = {
  showArchived?: InputMaybe<Scalars['Boolean']>;
};


export type CentreDepartmentsArgs = {
  showArchived?: InputMaybe<Scalars['Boolean']>;
};

export type CentreAssignments = {
  __typename?: 'CentreAssignments';
  classScores?: Maybe<ScoreStats>;
  gradable?: Maybe<GradableUnion>;
  levelScores?: Maybe<ScoreStats>;
  submission?: Maybe<Submission>;
};

export type ChangeAcademicLevelArchive = {
  id: Scalars['ObjectId'];
  isArchived: Scalars['Boolean'];
};

export type ChangeAcademicLevelVisibility = {
  id: Scalars['ObjectId'];
  isVisible: Scalars['Boolean'];
};

export type ChangeAdminArchiveInput = {
  id: Scalars['ObjectId'];
  isArchived: Scalars['Boolean'];
};

export type ChangeAttendingClassForRegistrationInput = {
  fromLessonId: Scalars['ObjectId'];
  registrationId: Scalars['ObjectId'];
  toLessonId: Scalars['ObjectId'];
};

export type ChangeAttendingClassForTransferInput = {
  arrangementId: Scalars['ObjectId'];
  fromLessonId: Scalars['ObjectId'];
  toLessonId: Scalars['ObjectId'];
};

export type ChangeBranchArchiveInput = {
  id: Scalars['ObjectId'];
  isArchived: Scalars['Boolean'];
};

/** An input that is used to update the assigned LessonFlow of one or many classes. The "ids" field must contain one or more IDs. */
export type ChangeClassLessonFlowInput = {
  /** Class ID to update. */
  id: Scalars['ObjectId'];
  /** The ID of the new LessonFlow to assign to the classes specified. */
  lessonFlowId?: InputMaybe<Scalars['ObjectId']>;
};

export type ChangeClassVisibilityInput = {
  id: Scalars['ObjectId'];
  isVisible: Scalars['Boolean'];
};

export type ChangeClassWaitableInput = {
  id: Scalars['ObjectId'];
  isWaitable: Scalars['Boolean'];
};

export type ChangeDepartmentArchiveInput = {
  id: Scalars['ObjectId'];
  isArchived: Scalars['Boolean'];
};

export type ChangeSchoolArchiveInput = {
  id: Scalars['ObjectId'];
  isArchived: Scalars['Boolean'];
};

export type ChangeSchoolVisibilityInput = {
  id: Scalars['ObjectId'];
  isVisible: Scalars['Boolean'];
};

export type ChangeUserArchiveInput = {
  id: Scalars['ObjectId'];
  isArchived: Scalars['Boolean'];
};

export type ChangeVenueArchiveInput = {
  /** Id of the venue */
  id: Scalars['ObjectId'];
  /** Indicator that the venue is archived, archiving the venue means it cannot be associated to a class */
  isArchived: Scalars['Boolean'];
};

export type Changes = {
  __typename?: 'Changes';
  fieldName?: Maybe<Scalars['String']>;
  newValue?: Maybe<Scalars['String']>;
  oldValue?: Maybe<Scalars['String']>;
};

export enum CheckEligibilityStatus {
  AttendingOrAttended = 'AttendingOrAttended',
  CanAttend = 'CanAttend',
  CanAttendWithConditional = 'CanAttendWithConditional',
  MustAttend = 'MustAttend',
  NotEligible = 'NotEligible'
}

export type CheckReport = {
  __typename?: 'CheckReport';
  reportContent?: Maybe<Scalars['String']>;
  rule: Scalars['String'];
  status?: Maybe<CheckEligibilityStatus>;
};

export type Class = {
  __typename?: 'Class';
  _id: Scalars['ObjectId'];
  academicLevels: Array<AcademicLevel>;
  academicWeeks: Array<AcademicWeek>;
  academicYear: AcademicYear;
  academicYearId: Scalars['ObjectId'];
  activeEnrollments?: Maybe<Array<Enrollment>>;
  capacity?: Maybe<ClassCapacity>;
  capacityUsed?: Maybe<Scalars['Int']>;
  classCode?: Maybe<Scalars['String']>;
  course: Course;
  courseId: Scalars['ObjectId'];
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  dailySessionTimeSlots?: Maybe<Array<TimeRangeOutput>>;
  dateRange?: Maybe<DateRange>;
  defaultLessonDay?: Maybe<WeekDay>;
  defaultLessonEnd?: Maybe<Scalars['DateTime']>;
  defaultLessonStart?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  digitalClassAccessCredentials?: Maybe<DigitalClassAccessCredentials>;
  eligibility?: Maybe<Array<ClassEligibility>>;
  enrollments?: Maybe<Array<Enrollment>>;
  firstLessonDate?: Maybe<Scalars['DateTime']>;
  isAdHoc?: Maybe<Scalars['Boolean']>;
  isArchived: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  isSimulation?: Maybe<Scalars['Boolean']>;
  isSkipScheduledHoliday?: Maybe<Scalars['Boolean']>;
  isVisible: Scalars['Boolean'];
  isWaitable: Scalars['Boolean'];
  lastLessonDate: Scalars['DateTime'];
  lastModifiedBy?: Maybe<User>;
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  lastModifiedUser?: Maybe<User>;
  learningArrangement: LearningArrangementType;
  /** The Lesson Flow assigned to the class. */
  lessonFlow?: Maybe<LessonFlow>;
  lessonNumber?: Maybe<Scalars['Int']>;
  lessonPlan?: Maybe<Scalars['String']>;
  lessons?: Maybe<Array<Lesson>>;
  maximumClassCapacity?: Maybe<Scalars['Int']>;
  maximumMakeUpCapacity?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  numberOfLessons?: Maybe<Scalars['Int']>;
  registrations?: Maybe<Array<Registration>>;
  repeat?: Maybe<Scalars['Int']>;
  runNumber?: Maybe<Scalars['Int']>;
  seats?: Maybe<Array<Seat>>;
  shorthand?: Maybe<Scalars['String']>;
  status?: Maybe<ClassStatus>;
  teacher: Teacher;
  term?: Maybe<Term>;
  type?: Maybe<ClassType>;
  venue?: Maybe<Venue>;
  voidRemarks?: Maybe<Scalars['String']>;
};


export type ClassEligibilityArgs = {
  linkRegistrationId?: InputMaybe<Scalars['ObjectId']>;
  studentId?: InputMaybe<Scalars['ObjectId']>;
};


export type ClassEnrollmentsArgs = {
  status?: InputMaybe<Array<EnrollmentStatus>>;
  types?: InputMaybe<Array<EnrollmentType>>;
};


export type ClassLessonsArgs = {
  skipHoliday?: InputMaybe<Scalars['Boolean']>;
};

export type ClassCapacity = {
  __typename?: 'ClassCapacity';
  class?: Maybe<Scalars['Int']>;
  enrolled?: Maybe<Scalars['Int']>;
  makeUp?: Maybe<Scalars['Int']>;
  seats?: Maybe<Array<Seat>>;
  seatsLeft?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  waiting?: Maybe<Scalars['Int']>;
};

export type ClassEligibility = {
  __typename?: 'ClassEligibility';
  dateRanges?: Maybe<Array<DateTimeRange>>;
  type: RegistrationType;
};

export type ClassFilterInput = {
  academicLevelIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  academicYearIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  academicYears?: InputMaybe<Array<Scalars['Int']>>;
  courseIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  dateRange?: InputMaybe<DateTimeRangeInput>;
  isAdHoc?: InputMaybe<Scalars['Boolean']>;
  isFull?: InputMaybe<Scalars['Boolean']>;
  isVisible?: InputMaybe<Scalars['Boolean']>;
  learningArrangements?: InputMaybe<Array<LearningArrangementType>>;
  lessonDays?: InputMaybe<Array<WeekDay>>;
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Array<ClassStatus>>;
  teacherIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  types?: InputMaybe<Array<ClassType>>;
  venueIds?: InputMaybe<Array<Scalars['ObjectId']>>;
};

export type ClassPage = {
  __typename?: 'ClassPage';
  items: Array<Class>;
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export type ClassSeat = {
  __typename?: 'ClassSeat';
  checkFirstLesson?: Maybe<Scalars['Boolean']>;
  date?: Maybe<Scalars['DateTime']>;
  hasPaid?: Maybe<Scalars['Boolean']>;
  isSimulation?: Maybe<Scalars['Boolean']>;
  seatType?: Maybe<SeatType>;
  status?: Maybe<EnrollmentStatus>;
  student?: Maybe<Student>;
  type?: Maybe<EnrollmentType>;
};

export enum ClassStatus {
  Completed = 'Completed',
  Created = 'Created',
  Incomplete = 'Incomplete',
  Ongoing = 'Ongoing',
  Void = 'Void'
}

/** Object defining submission statistics by Class */
export type ClassSubmissionStats = {
  __typename?: 'ClassSubmissionStats';
  /** Object ID of Class */
  _id: Scalars['ObjectId'];
  /** Abbreviated Class name */
  abbreviation: Scalars['String'];
  /** Class name */
  className: Scalars['String'];
  /** Score statistics object containing min, max, and avg scores of the Class */
  score: ScoreStats;
};

/** Input parameters in getting score statistics by class */
export type ClassSubmissionStatsInput = {
  /** Filter class score statistics by Academic Level ID */
  academicLevelId?: InputMaybe<Scalars['ObjectId']>;
  /** Filter class score statistics by Academic Year ID */
  academicYearId?: InputMaybe<Scalars['ObjectId']>;
  /** Only filter the class score statistisc of these teachers */
  teacherIds?: InputMaybe<Scalars['ObjectId']>;
  /** Filter class score statistics by Worksheet ID */
  worksheetId?: InputMaybe<Scalars['ObjectId']>;
};

export enum ClassType {
  Recurring = 'Recurring',
  Workshop = 'Workshop'
}

export type ClassValidationVenueOrTeacherInput = {
  academicWeekNumber?: InputMaybe<Scalars['Int']>;
  academicYearId: Scalars['ObjectId'];
  courseType: CourseType;
  dailySessionTimeSlots?: InputMaybe<Array<TimeRangeInput>>;
  dateRange?: InputMaybe<DateRangeInput>;
  defaultLessonDay?: InputMaybe<WeekDay>;
  defaultLessonEnd?: InputMaybe<Scalars['DateTime']>;
  defaultLessonStart?: InputMaybe<Scalars['DateTime']>;
  excludeClassId?: InputMaybe<Scalars['ObjectId']>;
  firstLessonDate?: InputMaybe<Scalars['Date']>;
  isAdHoc?: InputMaybe<Scalars['Boolean']>;
  isSkipScheduledHoliday?: InputMaybe<Scalars['Boolean']>;
  numberOfLessons?: InputMaybe<Scalars['Int']>;
  repeat?: InputMaybe<Scalars['Int']>;
  teacherId: Scalars['ObjectId'];
  venueId?: InputMaybe<Scalars['ObjectId']>;
};

/** The collect object that lists the description of a collect item in the LessonFlowItem. */
export type Collect = {
  __typename?: 'Collect';
  /** The ID of the Collect. */
  _id: Scalars['ObjectId'];
  /** The description of the collect formatted in markdown. */
  description: Scalars['String'];
};

export type CompleteMailMergeInput = {
  id: Scalars['ObjectId'];
  senderId: Scalars['ObjectId'];
};

export type ConnectXeroAccountForCourseInput = {
  courseId: Scalars['ObjectId'];
  liabilityAccount: AccountInput;
  revenueAccount: AccountInput;
};

export type ConnectXeroAccountInput = {
  code: Scalars['String'];
  id: Scalars['ObjectId'];
  name: Scalars['String'];
};

export type Consent = {
  __typename?: 'Consent';
  PhotographyOrVideography?: Maybe<Scalars['Boolean']>;
  accountCondition?: Maybe<Scalars['Boolean']>;
  statements?: Maybe<Scalars['Boolean']>;
  termCondition?: Maybe<Scalars['Boolean']>;
};

export type ConsentInput = {
  PhotographyOrVideography?: InputMaybe<Scalars['Boolean']>;
  accountCondition?: InputMaybe<Scalars['Boolean']>;
  statements?: InputMaybe<Scalars['Boolean']>;
  termCondition?: InputMaybe<Scalars['Boolean']>;
};

export type ConsentOutput = {
  __typename?: 'ConsentOutput';
  PhotographyOrVideography?: Maybe<Scalars['Boolean']>;
  accountCondition?: Maybe<Scalars['Boolean']>;
  statements?: Maybe<Scalars['Boolean']>;
  termCondition?: Maybe<Scalars['Boolean']>;
};

export type Content = {
  __typename?: 'Content';
  academicWeek?: Maybe<AcademicWeek>;
  gradable?: Maybe<GradableUnion>;
  submissions: Array<Submission>;
};

export type Counter = {
  __typename?: 'Counter';
  done?: Maybe<Scalars['Float']>;
  overdue?: Maybe<Scalars['Float']>;
  pending?: Maybe<Scalars['Float']>;
};

export type CountryCode = {
  __typename?: 'CountryCode';
  _id: Scalars['ObjectId'];
  areaCode: Scalars['String'];
  countryCode?: Maybe<Scalars['String']>;
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<User>;
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  lastModifiedUser?: Maybe<User>;
  order?: Maybe<Scalars['Int']>;
};

export type Course = {
  __typename?: 'Course';
  _id: Scalars['ObjectId'];
  academicLevels: Array<AcademicLevel>;
  academicStreams?: Maybe<Array<Scalars['String']>>;
  avatarUrl?: Maybe<Scalars['String']>;
  classes?: Maybe<Array<Class>>;
  courseFee?: Maybe<CourseFee>;
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  disclaimer?: Maybe<Array<Scalars['String']>>;
  duration?: Maybe<Scalars['Float']>;
  enrollments: Array<Enrollment>;
  isArchived: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  isSkipScheduledHoliday?: Maybe<Scalars['Boolean']>;
  lastModifiedBy?: Maybe<User>;
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  lastModifiedUser?: Maybe<User>;
  learningResourceUrl?: Maybe<Scalars['String']>;
  lessonFlow?: Maybe<LessonFlow>;
  liabilityAccount?: Maybe<Account>;
  makeUpCredits: MakeUpCredits;
  name: Scalars['String'];
  nameShort: Scalars['String'];
  nextRunNumber?: Maybe<Scalars['Int']>;
  numberOfLessons?: Maybe<Scalars['Int']>;
  onGoingClassNumber: Scalars['Int'];
  revenueAccount?: Maybe<Account>;
  status?: Maybe<CourseStatus>;
  termsAndConditions?: Maybe<Scalars['String']>;
  topics: Array<Topic>;
  type: CourseType;
  voidRemarks?: Maybe<Scalars['String']>;
  xeroAccountsSyncStatus?: Maybe<Scalars['Boolean']>;
};


export type CourseClassesArgs = {
  academicYearId?: InputMaybe<Scalars['ObjectId']>;
  status?: InputMaybe<Array<ClassStatus>>;
};

export type CourseFee = {
  __typename?: 'CourseFee';
  amount: Scalars['Decimal'];
  billingFrequency?: Maybe<BillingFrequency>;
  isApplicable: Scalars['Boolean'];
  otherFees?: Maybe<Array<Fee>>;
  type: CourseFeeType;
};

export type CourseFeeInput = {
  amount: Scalars['Decimal'];
  billingFrequency?: InputMaybe<BillingFrequency>;
  isApplicable: Scalars['Boolean'];
  otherFees?: InputMaybe<Array<OtherFeeInput>>;
  type: CourseFeeType;
};

export type CourseFeeOutput = {
  __typename?: 'CourseFeeOutput';
  amount: Scalars['Decimal'];
  billingFrequency?: Maybe<BillingFrequency>;
  isApplicable: Scalars['Boolean'];
  otherFees?: Maybe<Array<OtherFeeOutput>>;
  type: CourseFeeType;
};

export enum CourseFeeType {
  Perclass = 'Perclass',
  Perlesson = 'Perlesson',
  Permonth = 'Permonth',
  Perterm = 'Perterm'
}

export type CourseFilterInput = {
  academicLevelIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  courseIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Array<CourseStatus>>;
  teacherId?: InputMaybe<Scalars['ObjectId']>;
  type?: InputMaybe<Array<CourseType>>;
};

export type CoursePage = {
  __typename?: 'CoursePage';
  items: Array<Course>;
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export enum CourseStatus {
  Completed = 'Completed',
  Created = 'Created',
  Ongoing = 'Ongoing',
  Terminated = 'Terminated',
  Void = 'Void'
}

export enum CourseType {
  Regular = 'Regular',
  Workshop = 'Workshop'
}

export type CreateAcademicLevelInput = {
  alias: Scalars['String'];
  educationStage: EducationStage;
  isVisible: Scalars['Boolean'];
  name: Scalars['String'];
};

export type CreateAcademicYearInput = {
  academicLevelIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  term1Period?: InputMaybe<DateRangeInput>;
  term2Period?: InputMaybe<DateRangeInput>;
  term3Period?: InputMaybe<DateRangeInput>;
  term4Period?: InputMaybe<DateRangeInput>;
  year: Scalars['Int'];
};

export type CreateAdminInput = {
  alias: Scalars['String'];
  alternateUserId?: InputMaybe<Scalars['String']>;
  avatarUrl?: InputMaybe<Scalars['String']>;
  branchIds: Array<Scalars['ObjectId']>;
  departmentId: Scalars['ObjectId'];
  designation: Scalars['String'];
  email: Scalars['String'];
  fullName: Scalars['String'];
  gender: Scalars['String'];
  initials: Scalars['String'];
  mobile: TelephoneInput;
  officePhone?: InputMaybe<TelephoneInput>;
  salutation: Scalars['String'];
  userId?: InputMaybe<Scalars['String']>;
};

export type CreateAdminOutput = {
  __typename?: 'CreateAdminOutput';
  alias: Scalars['String'];
  alternateUserId?: Maybe<Scalars['String']>;
  avatarUrl?: Maybe<Scalars['String']>;
  branchIds: Array<Scalars['ObjectId']>;
  departmentId: Scalars['ObjectId'];
  designation: Scalars['String'];
  email: Scalars['String'];
  fullName: Scalars['String'];
  gender: Scalars['String'];
  initials: Scalars['String'];
  mobile: TelephoneOutput;
  officePhone?: Maybe<TelephoneOutput>;
  salutation: Scalars['String'];
  userId?: Maybe<Scalars['String']>;
};

export type CreateArrangementInput = {
  fromClassId?: InputMaybe<Scalars['ObjectId']>;
  fromLessonId?: InputMaybe<Scalars['ObjectId']>;
  group?: InputMaybe<EnrollmentGroupType>;
  preferredLearningArrangement?: InputMaybe<LearningArrangementType>;
  remarks?: InputMaybe<Scalars['String']>;
  studentId: Scalars['ObjectId'];
  toClassId?: InputMaybe<Scalars['ObjectId']>;
  toLessonId?: InputMaybe<Scalars['ObjectId']>;
  type: ArrangementType;
  useCredits?: InputMaybe<Scalars['Boolean']>;
  withdrawReasons?: InputMaybe<WithdrawReasonsInput>;
};

export type CreateBranchInput = {
  address: AddressInput;
  alias: Scalars['String'];
  centreId: Scalars['ObjectId'];
  name: Scalars['String'];
};

export type CreateClassInput = {
  academicWeekNumber?: InputMaybe<Scalars['Int']>;
  academicYearId: Scalars['ObjectId'];
  classCode: Scalars['String'];
  courseId: Scalars['ObjectId'];
  courseType: CourseType;
  dailySessionTimeSlots?: InputMaybe<Array<TimeRangeInput>>;
  dateRange?: InputMaybe<DateRangeInput>;
  defaultLessonDay?: InputMaybe<WeekDay>;
  defaultLessonEnd?: InputMaybe<Scalars['DateTime']>;
  defaultLessonStart?: InputMaybe<Scalars['DateTime']>;
  digitalClassAccessCredentials?: InputMaybe<DigitalClassAccessCredentialsInput>;
  firstLessonDate?: InputMaybe<Scalars['Date']>;
  isAdHoc?: InputMaybe<Scalars['Boolean']>;
  isSkipScheduledHoliday?: InputMaybe<Scalars['Boolean']>;
  learningArrangement: LearningArrangementType;
  lessonNumber?: InputMaybe<Scalars['Int']>;
  lessonPlan?: InputMaybe<Scalars['String']>;
  maximumClassCapacity?: InputMaybe<Scalars['Int']>;
  maximumMakeUpCapacity?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  numberOfLessons?: InputMaybe<Scalars['Int']>;
  repeat?: InputMaybe<Scalars['Int']>;
  runNumber?: InputMaybe<Scalars['Int']>;
  shorthand?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<ClassStatus>;
  teacherId: Scalars['ObjectId'];
  venueId?: InputMaybe<Scalars['ObjectId']>;
};

export type CreateClassOutput = {
  __typename?: 'CreateClassOutput';
  academicWeekNumber?: Maybe<Scalars['Int']>;
  academicYearId: Scalars['ObjectId'];
  classCode: Scalars['String'];
  courseId: Scalars['ObjectId'];
  courseType: CourseType;
  dailySessionTimeSlots?: Maybe<Array<TimeRangeOutput>>;
  dateRange?: Maybe<DateRangeOutput>;
  defaultLessonDay?: Maybe<WeekDay>;
  defaultLessonEnd?: Maybe<Scalars['DateTime']>;
  defaultLessonStart?: Maybe<Scalars['DateTime']>;
  digitalClassAccessCredentials?: Maybe<DigitalClassAccessCredentialsOutput>;
  firstLessonDate?: Maybe<Scalars['Date']>;
  isAdHoc?: Maybe<Scalars['Boolean']>;
  isSkipScheduledHoliday?: Maybe<Scalars['Boolean']>;
  learningArrangement: LearningArrangementType;
  lessonNumber?: Maybe<Scalars['Int']>;
  lessonPlan?: Maybe<Scalars['String']>;
  maximumClassCapacity?: Maybe<Scalars['Int']>;
  maximumMakeUpCapacity?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  numberOfLessons?: Maybe<Scalars['Int']>;
  repeat?: Maybe<Scalars['Int']>;
  runNumber?: Maybe<Scalars['Int']>;
  shorthand?: Maybe<Scalars['String']>;
  status?: Maybe<ClassStatus>;
  teacherId: Scalars['ObjectId'];
  venueId?: Maybe<Scalars['ObjectId']>;
};

/** Input used to Create collect details of a LessonFlowItem. */
export type CreateCollectInput = {
  /** The text of the collect generated from a rich text editor. */
  description: Scalars['String'];
  /** The ID of the LessonFlowItem to insert the collect to. */
  lessonFlowItemId: Scalars['ObjectId'];
};

export type CreateCourseInput = {
  academicLevelIds: Array<Scalars['ObjectId']>;
  academicStreams?: InputMaybe<Array<Scalars['String']>>;
  avatarUrl?: InputMaybe<Scalars['String']>;
  courseFee: CourseFeeInput;
  description?: InputMaybe<Scalars['String']>;
  disclaimer: Array<Scalars['String']>;
  duration: Scalars['Float'];
  isSkipScheduledHoliday?: InputMaybe<Scalars['Boolean']>;
  learningResourceUrl?: InputMaybe<Scalars['String']>;
  makeUpCredits?: InputMaybe<MakeUpCreditInput>;
  name: Scalars['String'];
  nameShort: Scalars['String'];
  numberOfLessons?: InputMaybe<Scalars['Int']>;
  termsAndConditions: Scalars['String'];
  type: CourseType;
};

export type CreateCourseOutput = {
  __typename?: 'CreateCourseOutput';
  academicLevelIds: Array<Scalars['ObjectId']>;
  academicStreams?: Maybe<Array<Scalars['String']>>;
  avatarUrl?: Maybe<Scalars['String']>;
  courseFee: CourseFeeOutput;
  description?: Maybe<Scalars['String']>;
  disclaimer: Array<Scalars['String']>;
  duration: Scalars['Float'];
  isSkipScheduledHoliday?: Maybe<Scalars['Boolean']>;
  learningResourceUrl?: Maybe<Scalars['String']>;
  makeUpCredits?: Maybe<MakeUpCreditOutput>;
  name: Scalars['String'];
  nameShort: Scalars['String'];
  numberOfLessons?: Maybe<Scalars['Int']>;
  termsAndConditions: Scalars['String'];
  type: CourseType;
};

export type CreateDepartmentInput = {
  centreId: Scalars['ObjectId'];
  designations?: InputMaybe<Array<Scalars['String']>>;
  name: Scalars['String'];
};

export type CreateDiscountInput = {
  discountAmount?: InputMaybe<Scalars['Decimal']>;
  discountName: Scalars['String'];
  discountRate?: InputMaybe<Scalars['Decimal']>;
  discountType: DiscountType;
};

export type CreateEligibilityFlowInput = {
  courseId: Scalars['ObjectId'];
  startNode: FlowNodeInput;
};

export type CreateExerciseInput = {
  content: Scalars['String'];
  duration?: InputMaybe<Scalars['Int']>;
  lessonNumber: Scalars['Int'];
  needSubmit?: InputMaybe<Scalars['Boolean']>;
  order: Scalars['Int'];
  totalBreakdown?: InputMaybe<MarkBreakDownInput>;
  totalGrade?: InputMaybe<Scalars['Int']>;
  workSheetId: Scalars['ObjectId'];
};

export type CreateFeedbackInput = {
  lessonFlowId?: InputMaybe<Scalars['ObjectId']>;
  lessonFlowItemId?: InputMaybe<Scalars['ObjectId']>;
  quoted: Scalars['String'];
  text: Scalars['String'];
  type: FeedbackType;
};

/** Input in creating a single Feedback Seen Receipt */
export type CreateFeedbackSeenReceiptInput = {
  /** Linked feedback ID from `feedback` collection */
  feedbackId: Scalars['ObjectId'];
  /** Linked Lesson Flow ID */
  lessonFlowId?: InputMaybe<Scalars['ObjectId']>;
  /** Feedback type (e.g. NOTE or ERROR) */
  type: FeedbackType;
};

/** Input in creating multiple Feedback Seen Receipts */
export type CreateFeedbackSeenReceiptInputs = {
  /** Array of Feedback seen receipt records */
  items: Array<CreateFeedbackSeenReceiptInput>;
};

export type CreateFinanceInput = {
  isVisible: Scalars['Boolean'];
  paymentMethod: Scalars['String'];
};

export type CreateGroupAssignmentInput = {
  academicYearId: Scalars['ObjectId'];
  items?: InputMaybe<Array<GroupAssignmentItemInput>>;
};

/** Arguments to be used when creating a new handout. */
export type CreateHandoutInput = {
  /** The Academic Week this handout is for. */
  academicWeek: LessonFlowAcademicWeekInput;
  /** Used to determine where the Handout is intended to be sent. */
  channel: HandoutChannel;
  /** The ID of the course this handout is for. */
  courseId: Scalars['ObjectId'];
  /** The enrollment type this handout is for. */
  enrollmentType: EnrollmentType;
  /**  A file to be attached to the course. This is used for handouts that are of type `HandoutType.Answers` */
  file?: InputMaybe<HandoutFileInput>;
  /** The instructions for the handout. This is formatted in Markdown. */
  instructions?: InputMaybe<Scalars['String']>;
  /**  This determines who the handout is intended for. */
  type: HandoutType;
  /** The id of the worksheet linked to this handout. */
  worksheetId?: InputMaybe<Scalars['ObjectId']>;
};

export type CreateHolidayInput = {
  name: Scalars['String'];
  period: DateRangeInput;
  type?: InputMaybe<Scalars['String']>;
};

export type CreateHomeworkInput = {
  content: Scalars['String'];
  /** The duration of the `homework` in minutes. */
  duration?: InputMaybe<Scalars['Int']>;
  lessonNumber: Scalars['Int'];
  needSubmit?: InputMaybe<Scalars['Boolean']>;
  order: Scalars['Int'];
  totalBreakdown?: InputMaybe<MarkBreakDownInput>;
  totalGrade?: InputMaybe<Scalars['Int']>;
  workSheetId?: InputMaybe<Scalars['ObjectId']>;
};

export type CreateLessonFlowInput = {
  academicWeek?: InputMaybe<LessonFlowAcademicWeekInput>;
  academicYearId: Scalars['ObjectId'];
  /** List of Course IDs to apply the LessonFlow to. */
  applyToCourses?: InputMaybe<Array<Scalars['ObjectId']>>;
  courseId?: InputMaybe<Scalars['ObjectId']>;
  lessonFlowItemIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  name: Scalars['String'];
};

export type CreateLessonFlowItemInput = {
  academicWeek: LessonFlowAcademicWeekInput;
  lessonFlowId: Scalars['ObjectId'];
  lessonNumber: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
  order: Scalars['Int'];
};

export type CreateLessonInput = {
  academicWeekNumber?: InputMaybe<Scalars['Int']>;
  additional: Scalars['Boolean'];
  classId: Scalars['ObjectId'];
  lessonEnd: Scalars['DateTime'];
  lessonNotes?: InputMaybe<Scalars['String']>;
  lessonStart: Scalars['DateTime'];
  teacherId: Scalars['ObjectId'];
  venueId?: InputMaybe<Scalars['ObjectId']>;
};

export type CreateLogInput = {
  attachment?: InputMaybe<Scalars['String']>;
  log: Scalars['String'];
  type: LogType;
  userId: Scalars['ObjectId'];
};

export type CreateMailMergeInput = {
  templateId: Scalars['ObjectId'];
};

export type CreateMerchandiseInput = {
  dateIssued?: InputMaybe<Scalars['DateTime']>;
  distributionChannel?: InputMaybe<Scalars['String']>;
  item: Scalars['String'];
  remarks?: InputMaybe<Scalars['String']>;
  status: MerchandiseStatus;
  studentId: Scalars['ObjectId'];
};

export type CreateNoteInput = {
  note: Scalars['String'];
  userId: Scalars['ObjectId'];
};

export type CreateParentInput = {
  address?: InputMaybe<UserAddressInput>;
  alias: Scalars['String'];
  alternateUserId?: InputMaybe<Scalars['String']>;
  alternativeEmails?: InputMaybe<Array<Scalars['String']>>;
  avatarUrl?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  fullName: Scalars['String'];
  gender: Scalars['String'];
  homePhone?: InputMaybe<TelephoneInput>;
  linkedWhatsappAccountId: Scalars['ObjectId'];
  mobile: TelephoneInput;
  officePhone?: InputMaybe<TelephoneInput>;
  remarks?: InputMaybe<Scalars['String']>;
  salutation: Scalars['String'];
  userId?: InputMaybe<Scalars['String']>;
  whatsAppName: Scalars['String'];
};

export type CreateParentOutput = {
  __typename?: 'CreateParentOutput';
  address?: Maybe<UserAddressOutput>;
  alias: Scalars['String'];
  alternateUserId?: Maybe<Scalars['String']>;
  alternativeEmails?: Maybe<Array<Scalars['String']>>;
  avatarUrl?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  fullName: Scalars['String'];
  gender: Scalars['String'];
  homePhone?: Maybe<TelephoneOutput>;
  linkedWhatsappAccountId: Scalars['ObjectId'];
  mobile: TelephoneOutput;
  officePhone?: Maybe<TelephoneOutput>;
  remarks?: Maybe<Scalars['String']>;
  salutation: Scalars['String'];
  userId?: Maybe<Scalars['String']>;
  whatsAppName: Scalars['String'];
};

export type CreatePaymentRecordInput = {
  amount: Scalars['Decimal'];
  bankName?: InputMaybe<Scalars['String']>;
  chequeNumber?: InputMaybe<Scalars['String']>;
  depositDate?: InputMaybe<Scalars['DateTime']>;
  invoiceIds: Array<Scalars['ObjectId']>;
  isUseRemainingCredit?: InputMaybe<Scalars['Boolean']>;
  lastDigits?: InputMaybe<Scalars['String']>;
  method?: InputMaybe<Scalars['String']>;
  /** The date when the payment was made */
  paymentDate?: InputMaybe<Scalars['DateTime']>;
  paymentProcessor?: InputMaybe<Scalars['String']>;
  reference?: InputMaybe<Scalars['String']>;
  remarks?: InputMaybe<Scalars['String']>;
};

export type CreatePromotionInput = {
  academicLevelIds: Array<Scalars['ObjectId']>;
  promoteToLevelId: Scalars['ObjectId'];
  scheduledDate: Scalars['DateTime'];
  selectedStudentIds?: InputMaybe<Array<Scalars['ObjectId']>>;
};

export type CreateReferenceDataInput = {
  category: ReferenceDataCategory;
  value: Scalars['String'];
};

export type CreateRefundInput = {
  adminFee?: InputMaybe<Scalars['Decimal']>;
  amountRefunded: Scalars['Decimal'];
  bankAccountNumber?: InputMaybe<Scalars['String']>;
  bankName?: InputMaybe<Scalars['String']>;
  chequeNumber?: InputMaybe<Scalars['String']>;
  lastDigits?: InputMaybe<Scalars['String']>;
  parentId: Scalars['ObjectId'];
  payNowNumber?: InputMaybe<Scalars['String']>;
  paymentProcessor?: InputMaybe<Scalars['String']>;
  processedDate?: InputMaybe<Scalars['DateTime']>;
  reason?: InputMaybe<Scalars['String']>;
  reference?: InputMaybe<Scalars['String']>;
  refundMethod: Scalars['String'];
  refundedDate: Scalars['DateTime'];
};

export type CreateRegistrationInput = {
  classId: Scalars['ObjectId'];
  currentStep: Scalars['Int'];
  dueDate?: InputMaybe<Scalars['DateTime']>;
  group?: InputMaybe<EnrollmentGroupType>;
  linkRegistrationId?: InputMaybe<Scalars['ObjectId']>;
  preferredLearningArrangement?: InputMaybe<LearningArrangementType>;
  remarks?: InputMaybe<Scalars['String']>;
  selectedLessons?: InputMaybe<Array<SelectedLessonInput>>;
  startDate: Scalars['DateTime'];
  studentId: Scalars['ObjectId'];
  type: RegistrationType;
};

export type CreateSchoolInput = {
  alias?: InputMaybe<Scalars['String']>;
  educationStage: EducationStage;
  isVisible: Scalars['Boolean'];
  name: Scalars['String'];
};

export type CreateSubmissionInput = {
  breakdown?: InputMaybe<MarkBreakDownInput>;
  classId: Scalars['ObjectId'];
  enrollmentId: Scalars['ObjectId'];
  gradableId: Scalars['ObjectId'];
  grade?: InputMaybe<Scalars['Int']>;
};

export type CreateTaskInput = {
  assignedClassIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  deadline: Scalars['DateTime'];
  name: Scalars['String'];
};

export type CreateTeacherInput = {
  alias: Scalars['String'];
  alternateUserId?: InputMaybe<Scalars['String']>;
  avatarUrl?: InputMaybe<Scalars['String']>;
  branchIds: Array<Scalars['ObjectId']>;
  colorCode?: InputMaybe<Scalars['String']>;
  departmentId: Scalars['ObjectId'];
  designation?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  fullName: Scalars['String'];
  gender: Scalars['String'];
  initials: Scalars['String'];
  mobile: TelephoneInput;
  officePhone?: InputMaybe<TelephoneInput>;
  salutation: Scalars['String'];
  userId?: InputMaybe<Scalars['String']>;
};

export type CreateTeacherOutput = {
  __typename?: 'CreateTeacherOutput';
  alias: Scalars['String'];
  alternateUserId?: Maybe<Scalars['String']>;
  avatarUrl?: Maybe<Scalars['String']>;
  branchIds: Array<Scalars['ObjectId']>;
  colorCode?: Maybe<Scalars['String']>;
  departmentId: Scalars['ObjectId'];
  designation?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  fullName: Scalars['String'];
  gender: Scalars['String'];
  initials: Scalars['String'];
  mobile: TelephoneOutput;
  officePhone?: Maybe<TelephoneOutput>;
  salutation: Scalars['String'];
  userId?: Maybe<Scalars['String']>;
};

export type CreateTemplateInput = {
  arrangementType?: InputMaybe<TemplateArrangementType>;
  content?: InputMaybe<Scalars['String']>;
  courseType?: InputMaybe<CourseType>;
  emailMessage?: InputMaybe<Scalars['String']>;
  status: TemplateStatus;
  subject?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  type: TemplateType;
  whatsAppMessage?: InputMaybe<Scalars['String']>;
};

export type CreateTermInput = {
  academicYearId: Scalars['ObjectId'];
};

export type CreateTopicInput = {
  courseId: Scalars['ObjectId'];
  name: Scalars['String'];
};

export type CreateUserCustomiseColumnInput = {
  columnName: Scalars['String'];
  columnShowName?: InputMaybe<Scalars['String']>;
  isRequired: Scalars['Boolean'];
  isVisible: Scalars['Boolean'];
  ownerId?: InputMaybe<Scalars['ObjectId']>;
  tableName: Scalars['String'];
  tableShowName?: InputMaybe<Scalars['String']>;
  weight: Scalars['Int'];
};

export type CreateVenueInput = {
  /** Address of the Venue */
  address: AddressInput;
  /** Id of the branch where the venue is located */
  branchId: Scalars['ObjectId'];
  /** Maximum number of students that a venue can hold */
  capacity?: InputMaybe<Scalars['Int']>;
  /** Name of the Venue, this is the combination of the venue unit and classroom number e.g 02-5A CR1 */
  name: Scalars['String'];
};

export type CreateWaitingListsForStudentInput = {
  classIds: Array<Scalars['ObjectId']>;
  startDate: Scalars['DateTime'];
  studentId: Scalars['ObjectId'];
};

export type CreateWhatsappAccountInput = {
  name: Scalars['String'];
  number: PhoneNumberInput;
};

export type CreateWorkSheetInput = {
  abbreviation: Scalars['String'];
  academicYearId: Scalars['ObjectId'];
  courseId: Scalars['ObjectId'];
  reminders?: InputMaybe<WorkSheetRemindersInput>;
  title: Scalars['String'];
  topicId: Scalars['ObjectId'];
};

export type Credit = {
  __typename?: 'Credit';
  _id: Scalars['ObjectId'];
  allocations?: Maybe<Array<Allocation>>;
  balance: Scalars['Decimal'];
  contact?: Maybe<Parent>;
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  creditSource?: Maybe<Payment>;
  creditType: CreditType;
  deletedAt?: Maybe<Scalars['DateTime']>;
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<User>;
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  lastModifiedUser?: Maybe<User>;
  lineItem?: Maybe<Array<CreditLineItem>>;
  linkedInvoices?: Maybe<Array<Invoice>>;
  parentId?: Maybe<Scalars['ObjectId']>;
  referenceNumber: Scalars['String'];
  sourceType?: Maybe<CreditSourceType>;
  status: CreditStatus;
  total: Scalars['Decimal'];
  used: Scalars['Decimal'];
};

export type CreditFilterInput = {
  creditTypes?: InputMaybe<Array<CreditType>>;
  dateRange?: InputMaybe<DateTimeRangeInput>;
  invoiceIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  name?: InputMaybe<Scalars['String']>;
  parentId?: InputMaybe<Scalars['ObjectId']>;
  paymentMethods?: InputMaybe<Array<Scalars['String']>>;
  status?: InputMaybe<Array<CreditStatus>>;
};

export type CreditLineItem = {
  __typename?: 'CreditLineItem';
  amountDue?: Maybe<Scalars['Decimal']>;
  amountPaid?: Maybe<Scalars['Decimal']>;
  amountUsed?: Maybe<Scalars['Decimal']>;
  className?: Maybe<Scalars['String']>;
  createDate?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  invoiceString?: Maybe<Scalars['String']>;
  lessons?: Maybe<Array<CreditLineItemLesson>>;
  lessonsFee?: Maybe<Scalars['Decimal']>;
  lineAmount?: Maybe<Scalars['Decimal']>;
  method?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  recordDate?: Maybe<Scalars['DateTime']>;
  reference?: Maybe<Scalars['String']>;
  toClassName?: Maybe<Scalars['String']>;
  type?: Maybe<CreditLineItemType>;
  typeNumber?: Maybe<Scalars['String']>;
  unitAmount?: Maybe<Scalars['Decimal']>;
};

export type CreditLineItemLesson = {
  __typename?: 'CreditLineItemLesson';
  endAt?: Maybe<Scalars['DateTime']>;
  lessonDay?: Maybe<WeekDay>;
  perLessonPrice?: Maybe<Scalars['Decimal']>;
  startAt?: Maybe<Scalars['DateTime']>;
};

export enum CreditLineItemType {
  Deduction = 'Deduction',
  FromInvoicePaid = 'FromInvoicePaid'
}

export type CreditPage = {
  __typename?: 'CreditPage';
  items: Array<Credit>;
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export enum CreditSourceType {
  ClassCancellation = 'ClassCancellation',
  OverPayment = 'OverPayment',
  Transfer = 'Transfer',
  Withdrawal = 'Withdrawal'
}

export enum CreditStatus {
  Draft = 'Draft',
  Outstanding = 'Outstanding',
  PartialRefund = 'PartialRefund',
  Refunded = 'Refunded',
  RunOut = 'RunOut',
  Used = 'Used',
  Void = 'Void'
}

export enum CreditType {
  CreditNote = 'CreditNote',
  OverPayment = 'OverPayment'
}

export type Csv = {
  __typename?: 'Csv';
  url: Scalars['String'];
};

export type DateRange = {
  __typename?: 'DateRange';
  end: Scalars['Date'];
  start: Scalars['Date'];
};

export type DateRangeInput = {
  end: Scalars['Date'];
  start: Scalars['Date'];
};

export type DateRangeOutput = {
  __typename?: 'DateRangeOutput';
  end: Scalars['Date'];
  start: Scalars['Date'];
};

export type DateTimeRange = {
  __typename?: 'DateTimeRange';
  end: Scalars['DateTime'];
  start: Scalars['DateTime'];
};

export type DateTimeRangeInput = {
  end: Scalars['DateTime'];
  start: Scalars['DateTime'];
};

/** Reward points input to deduct for onr student item */
export type DeductRewardPointsInput = {
  /** Linked gradable (Exercise or Homework) ID if points are from award */
  gradableId?: InputMaybe<Scalars['ObjectId']>;
  /** Reward order to redeem */
  orderDetail?: InputMaybe<RewardDetailInput>;
  /** Amount of reward points */
  points: Scalars['Float'];
  /** Other notes associated with the reward transaction */
  remarks: Scalars['String'];
  /** Student ID on where reward points will be added */
  studentId: Scalars['ObjectId'];
  /** Type of reward history */
  type?: InputMaybe<RewardHistoryType>;
};

/** Input for delete attendance remarks mutation */
export type DeleteAttendanceRemarksInput = {
  /** Record ID of the parent attendance record to delete */
  attendanceId: Scalars['ObjectId'];
  /** Remark ID in the attendance remarks set to delete */
  remarkId: Scalars['ObjectId'];
};

/** Input used to Delete a collect item of a LessonFlowItem. */
export type DeleteCollectInput = {
  /** The ID of the collect to delete. */
  collectId: Scalars['ObjectId'];
  /** The ID of the LessonFlowItem containing the collect to find. */
  lessonFlowItemId: Scalars['ObjectId'];
};

export type DeleteSubmissionInput = {
  submissionId: Scalars['ObjectId'];
};

export type DeleteTopicInput = {
  topicId: Scalars['ObjectId'];
};

export type DeleteUserCustomiseColumnInput = {
  columnName: Scalars['String'];
  id?: InputMaybe<Scalars['ObjectId']>;
  ownerId: Scalars['ObjectId'];
  tableName: Scalars['String'];
};

export type Department = {
  __typename?: 'Department';
  _id: Scalars['ObjectId'];
  centre: Centre;
  centreId: Scalars['ObjectId'];
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  designations?: Maybe<Array<Scalars['String']>>;
  isArchived: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<User>;
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  lastModifiedUser?: Maybe<User>;
  name: Scalars['String'];
  systemId?: Maybe<Scalars['String']>;
};

export type DigitalClassAccessCredentials = {
  __typename?: 'DigitalClassAccessCredentials';
  googleMeetId?: Maybe<Scalars['String']>;
  googleMeetLink?: Maybe<Scalars['String']>;
  zoomMeetingId?: Maybe<Scalars['String']>;
  zoomMeetingLink?: Maybe<Scalars['String']>;
  zoomMeetingPassword?: Maybe<Scalars['String']>;
};

export type DigitalClassAccessCredentialsInput = {
  googleMeetId?: InputMaybe<Scalars['String']>;
  googleMeetLink?: InputMaybe<Scalars['String']>;
  zoomMeetingId?: InputMaybe<Scalars['String']>;
  zoomMeetingLink?: InputMaybe<Scalars['String']>;
  zoomMeetingPassword?: InputMaybe<Scalars['String']>;
};

export type DigitalClassAccessCredentialsOutput = {
  __typename?: 'DigitalClassAccessCredentialsOutput';
  googleMeetId?: Maybe<Scalars['String']>;
  googleMeetLink?: Maybe<Scalars['String']>;
  zoomMeetingId?: Maybe<Scalars['String']>;
  zoomMeetingLink?: Maybe<Scalars['String']>;
  zoomMeetingPassword?: Maybe<Scalars['String']>;
};

export type Discount = {
  __typename?: 'Discount';
  _id: Scalars['ObjectId'];
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  discountAmount?: Maybe<Scalars['Decimal']>;
  discountName?: Maybe<Scalars['String']>;
  discountRate?: Maybe<Scalars['Decimal']>;
  discountType?: Maybe<DiscountType>;
  isArchived: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  isVisible: Scalars['Boolean'];
  lastModifiedBy?: Maybe<User>;
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  lastModifiedUser?: Maybe<User>;
};

export enum DiscountType {
  Amount = 'Amount',
  Percentage = 'Percentage'
}

/** Input for edit attendance remarks mutation */
export type EditAttendanceRemarksInput = {
  /** Record ID of the parent attendance record to edit */
  attendanceId: Scalars['ObjectId'];
  /** Remark ID in the attendance remarks set to edit */
  remarkId: Scalars['ObjectId'];
  /** Updated content of the remark */
  remarks: Scalars['String'];
};

export enum EducationStage {
  Primary = 'Primary',
  Secondary = 'Secondary'
}

export type EligibilityFlow = {
  __typename?: 'EligibilityFlow';
  _id: Scalars['ObjectId'];
  courseId: Scalars['ObjectId'];
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<User>;
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  lastModifiedUser?: Maybe<User>;
  startNode: FlowNode;
  startNodeJsonString: Scalars['String'];
};

export enum EligibilityFlowEnrollmentComparatorType {
  AttendanceBefore = 'AttendanceBefore',
  CurrentlyAttending = 'CurrentlyAttending',
  NeverAttended = 'NeverAttended',
  WillAttend = 'WillAttend'
}

export type EligibleScore = {
  __typename?: 'EligibleScore';
  /** The maximum score. */
  max?: Maybe<Scalars['Float']>;
  /** The minimum score. */
  min?: Maybe<Scalars['Float']>;
};

/** This sets the minimum and maximum score for the student's score to determine eligibility for a reward. */
export type EligibleScoreInput = {
  /** The maximum score. */
  max: Scalars['Int'];
  /** The minimum score. */
  min: Scalars['Int'];
};

export type EmailReminderInput = {
  file?: InputMaybe<Scalars['Upload']>;
  fileName?: InputMaybe<Scalars['String']>;
  fileType?: InputMaybe<Scalars['String']>;
  isIncludeAttachment?: InputMaybe<Scalars['Boolean']>;
  message?: InputMaybe<Scalars['String']>;
  receiverParentIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  scheduledDate?: InputMaybe<Scalars['DateTime']>;
  subject?: InputMaybe<Scalars['String']>;
  templateId: Scalars['ObjectId'];
};

export type EmailValidation = {
  __typename?: 'EmailValidation';
  valid: Scalars['Boolean'];
  validationError?: Maybe<Scalars['String']>;
};

export type Employee = {
  branches?: Maybe<Array<Branch>>;
  department?: Maybe<Department>;
  designation?: Maybe<Scalars['String']>;
};

export type Enrollment = {
  __typename?: 'Enrollment';
  _id: Scalars['ObjectId'];
  arrangements?: Maybe<Array<Arrangement>>;
  attendances?: Maybe<Array<Attendance>>;
  class?: Maybe<Class>;
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  eligibleArrangements?: Maybe<Array<ArrangementType>>;
  endDate?: Maybe<Scalars['DateTime']>;
  group?: Maybe<EnrollmentGroupType>;
  hasIncompleteArrangement?: Maybe<Scalars['Boolean']>;
  invoices?: Maybe<Array<Invoice>>;
  isDeleted: Scalars['Boolean'];
  isFullEnrollment?: Maybe<Scalars['Boolean']>;
  lastModifiedBy?: Maybe<User>;
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  lastModifiedUser?: Maybe<User>;
  preferredLearningArrangement?: Maybe<LearningArrangementType>;
  registration?: Maybe<Registration>;
  reservation?: Maybe<Registration>;
  startDate?: Maybe<Scalars['DateTime']>;
  status: EnrollmentStatus;
  student?: Maybe<Student>;
  /** Enrollment suitability information that tells if student is suitable for registration */
  suitability?: Maybe<EnrollmentSuitability>;
  /**
   * Remarks on the suitability of the student's enrollment
   * @deprecated Field to be removed in future version. Please use `suitability.remarks` instead
   */
  suitabilityRemarks?: Maybe<Scalars['String']>;
  /**
   * Status of suitability of the student's enrollment (either Suitable or pending for contact parent)
   * @deprecated Field to be removed in future version. Please use `suitability.status` instead
   */
  suitabilityStatus?: Maybe<EnrollmentSuitabilityStatus>;
  trialFollowUpStatus?: Maybe<TrialFollowUpStatus>;
  trialFollowUpStatusLastModifiedBy?: Maybe<User>;
  trialFollowUpStatusLastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  trialRecord?: Maybe<Array<Registration>>;
  type: EnrollmentType;
};


export type EnrollmentAttendancesArgs = {
  hasPaid?: InputMaybe<Scalars['Boolean']>;
};

export enum EnrollmentGroupType {
  A = 'A',
  B = 'B',
  Unassigned = 'Unassigned'
}

export type EnrollmentPage = {
  __typename?: 'EnrollmentPage';
  items: Array<Enrollment>;
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export enum EnrollmentStatus {
  Active = 'Active',
  Cancelled = 'Cancelled',
  Complete = 'Complete',
  Draft = 'Draft',
  Holding = 'Holding',
  Pending = 'Pending',
  TransferIn = 'TransferIn',
  TransferOut = 'TransferOut',
  Withdrawing = 'Withdrawing',
  Withdrawn = 'Withdrawn'
}

/** Suitability class that includes suitability information of student on an `Enrollment` record */
export type EnrollmentSuitability = {
  __typename?: 'EnrollmentSuitability';
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  isDeleted: Scalars['Boolean'];
  /** Boolean flag to indicate that remarks has been edited */
  isRemarksEdited?: Maybe<Scalars['Boolean']>;
  lastModifiedBy?: Maybe<User>;
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  lastModifiedUser?: Maybe<User>;
  /** Remarks on the suitability of the student's enrollment */
  remarks?: Maybe<Scalars['String']>;
  /** Date when remarks was last modified or edited */
  remarksLastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  /** Status of suitability of the student's enrollment (either Suitable or pending for contact parent) */
  status?: Maybe<EnrollmentSuitabilityStatus>;
};

export enum EnrollmentSuitabilityStatus {
  ContactParent = 'ContactParent',
  Suitable = 'Suitable'
}

export enum EnrollmentType {
  Additional = 'Additional',
  Regular = 'Regular',
  Reservation = 'Reservation',
  Trial = 'Trial',
  WaitingList = 'WaitingList'
}

export type ExamScore = {
  __typename?: 'ExamScore';
  _id: Scalars['ObjectId'];
  academicLevel?: Maybe<AcademicLevel>;
  academicLevelId?: Maybe<Scalars['ObjectId']>;
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  examType: Scalars['String'];
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<User>;
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  lastModifiedUser?: Maybe<User>;
  marksPercentage: Scalars['Float'];
  mcq?: Maybe<ScoreRecord>;
  practical?: Maybe<ScoreRecord>;
  qeq?: Maybe<ScoreRecord>;
  scoreGrade: Scalars['String'];
  stream: Scalars['String'];
  student?: Maybe<Student>;
  studentId?: Maybe<Scalars['ObjectId']>;
  subject: Scalars['String'];
  totalMarks: ScoreRecord;
  year: Scalars['Float'];
};

export type Exercise = {
  __typename?: 'Exercise';
  _id: Scalars['ObjectId'];
  content: Scalars['String'];
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  duration?: Maybe<Scalars['Int']>;
  gradableType?: Maybe<GradableType>;
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<User>;
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  lastModifiedUser?: Maybe<User>;
  lessonNumber: Scalars['Int'];
  needSubmit?: Maybe<Scalars['Boolean']>;
  order: Scalars['Int'];
  /** Contains the `status` and `mechanics` of an `Activity` which this case is an `Exercise`. */
  reward?: Maybe<RewardInfo>;
  totalBreakdown?: Maybe<MarkBreakDown>;
  totalGrade?: Maybe<Scalars['Int']>;
  workSheet: WorkSheet;
  workSheetId: Scalars['ObjectId'];
};

export type FeatureAccess = {
  __typename?: 'FeatureAccess';
  _id: Scalars['ObjectId'];
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  isDeleted: Scalars['Boolean'];
  keywords: Scalars['String'];
  lastModifiedBy?: Maybe<User>;
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  lastModifiedUser?: Maybe<User>;
  name: Scalars['String'];
};

export type FeatureAccessExtendInput = {
  enrollmentGroup?: InputMaybe<EnrollmentGroupType>;
  enrollmentPreferredLearningArrangement?: InputMaybe<LearningArrangementType>;
};

export type Fee = {
  __typename?: 'Fee';
  amount?: Maybe<Scalars['Decimal']>;
  isApplicable: Scalars['Boolean'];
  name: OtherFeeType;
};

export type Feedback = {
  __typename?: 'Feedback';
  _id: Scalars['ObjectId'];
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  isDeleted: Scalars['Boolean'];
  isResolved?: Maybe<Scalars['Boolean']>;
  lastModifiedBy?: Maybe<User>;
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  lastModifiedUser?: Maybe<User>;
  /** Linked Lesson Flow resolved field */
  lessonFlow: LessonFlow;
  /** Linked Lesson Flow ID */
  lessonFlowId?: Maybe<Scalars['ObjectId']>;
  /**
   * A singular LessonFlowItem referred to by the feedback.
   * @deprecated This will be removed on future versions. Please query `lessonFlow` field.
   */
  lessonFlowItem?: Maybe<LessonFlowItem>;
  /** @deprecated Field to be removed in future versions. Please use `lessonFlowId` instead */
  lessonFlowItemId?: Maybe<Scalars['ObjectId']>;
  quoted: Scalars['String'];
  reply?: Maybe<Array<FeedbackReply>>;
  /** Derived status field of feedback record (e.g. SEEN, UNSEEN) */
  status?: Maybe<FeedbackSeenStatus>;
  text: Scalars['String'];
  type: FeedbackType;
};

export type FeedbackFilterInput = {
  isResolved?: InputMaybe<Scalars['Boolean']>;
  lessonFlowId?: InputMaybe<Scalars['ObjectId']>;
  lessonFlowItemId?: InputMaybe<Scalars['ObjectId']>;
  type?: InputMaybe<FeedbackType>;
  /** Check feedback seen receipt records by User ID  */
  userId?: InputMaybe<Scalars['ObjectId']>;
};

export type FeedbackPage = {
  __typename?: 'FeedbackPage';
  items: Array<Feedback>;
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export type FeedbackReply = {
  __typename?: 'FeedbackReply';
  _id: Scalars['ObjectId'];
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<User>;
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  lastModifiedUser?: Maybe<User>;
  text: Scalars['String'];
};

export type FeedbackSeenReceipt = {
  __typename?: 'FeedbackSeenReceipt';
  _id: Scalars['ObjectId'];
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  feedbackId: Scalars['ObjectId'];
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<User>;
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  lastModifiedUser?: Maybe<User>;
  lessonFlowId: Scalars['ObjectId'];
  type: FeedbackType;
};

export type FeedbackSeenReceiptPage = {
  __typename?: 'FeedbackSeenReceiptPage';
  items: Array<FeedbackSeenReceipt>;
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

/** Filter input in fetching feedback seen receipt records */
export type FeedbackSeenReceiptsFilterInput = {
  /** Filter by Lesson Flow ID */
  lessonFlowId?: InputMaybe<Scalars['ObjectId']>;
  /** Filter by Feedback type (e.g. NOTE or ERROR) */
  type?: InputMaybe<FeedbackType>;
  /** Filter by User ID */
  userId?: InputMaybe<Scalars['ObjectId']>;
};

export enum FeedbackSeenStatus {
  Seen = 'SEEN',
  Unseen = 'UNSEEN'
}

export enum FeedbackType {
  Error = 'Error',
  Note = 'Note'
}

/** Result object for count unseen feedback query */
export type FeedbackUnseenCount = {
  __typename?: 'FeedbackUnseenCount';
  /** Total count of unseen feedback records */
  total: Scalars['Float'];
};

export type Finance = {
  __typename?: 'Finance';
  _id: Scalars['ObjectId'];
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  isArchived: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  isVisible: Scalars['Boolean'];
  lastModifiedBy?: Maybe<User>;
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  lastModifiedUser?: Maybe<User>;
  paymentMethod: Scalars['String'];
};

export type FlowEnrollmentCheckExpression = {
  __typename?: 'FlowEnrollmentCheckExpression';
  type: EligibilityFlowEnrollmentComparatorType;
};

export type FlowEnrollmentCheckExpressionInput = {
  type: EligibilityFlowEnrollmentComparatorType;
};

export type FlowLevelCheckExpression = {
  __typename?: 'FlowLevelCheckExpression';
  academicLevelId: Scalars['ObjectId'];
};

export type FlowLevelCheckExpressionInput = {
  academicLevelId: Scalars['ObjectId'];
};

export type FlowNode = {
  __typename?: 'FlowNode';
  enrollmentCheckExpressions?: Maybe<Array<FlowEnrollmentCheckExpression>>;
  failNode?: Maybe<FlowNode>;
  levelCheckExpressions?: Maybe<Array<FlowLevelCheckExpression>>;
  logicalOperator?: Maybe<LogicalOperator>;
  passNode?: Maybe<FlowNode>;
  result?: Maybe<FlowResult>;
  type: FlowNodeType;
};

export type FlowNodeInput = {
  enrollmentCheckExpressions?: InputMaybe<Array<FlowEnrollmentCheckExpressionInput>>;
  failNode?: InputMaybe<FlowNodeInput>;
  levelCheckExpressions?: InputMaybe<Array<FlowLevelCheckExpressionInput>>;
  logicalOperator?: InputMaybe<LogicalOperator>;
  passNode?: InputMaybe<FlowNodeInput>;
  result?: InputMaybe<FlowResultInput>;
  type: FlowNodeType;
};

export enum FlowNodeType {
  Enrollment = 'Enrollment',
  Level = 'Level',
  Result = 'Result'
}

export type FlowResult = {
  __typename?: 'FlowResult';
  message: Scalars['String'];
  outcome: ResultOutcome;
};

export type FlowResultInput = {
  message: Scalars['String'];
  outcome: ResultOutcome;
};

export type GenerateUserIdInput = {
  parentId?: InputMaybe<Scalars['ObjectId']>;
  phoneNumber?: InputMaybe<PhoneNumberInput>;
};

export type GetTopicsByCourseIdsInput = {
  /** The IDs of the courses to find topics by. */
  courseIds: Array<Scalars['ObjectId']>;
};

/** Object defining submission statistics by Gradable (e.g. Exercise, Homework) */
export type GradableSubmissionStats = {
  __typename?: 'GradableSubmissionStats';
  /** Object ID of Gradable */
  _id: Scalars['ObjectId'];
  /** Gradable Name */
  gradableName: Scalars['String'];
  /** Gradable Type (e.g. GradableType.EXERCISE) */
  gradableType: Scalars['String'];
  /** Score statistics object containing min, max, and avg scores of the Gradable */
  score: ScoreStats;
};

export type GradableSubmissionStatsInput = {
  /** Filter gradable score statistics by Academic Year ID */
  academicYearId?: InputMaybe<Scalars['ObjectId']>;
  /** Filter gradable score statistics by Class ID */
  classId?: InputMaybe<Scalars['ObjectId']>;
  /** Filter gradable score statistics by date range */
  dateTimeRange?: InputMaybe<DateRangeInput>;
  /** Filter gradable score statistics by Worksheet ID */
  worksheetId?: InputMaybe<Scalars['ObjectId']>;
};

export enum GradableType {
  Exercise = 'Exercise',
  Homework = 'Homework'
}

export type GradableUnion = Exercise | Homework;

export type GroupAssignment = {
  __typename?: 'GroupAssignment';
  _id: Scalars['ObjectId'];
  academicYear: AcademicYear;
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  isDeleted: Scalars['Boolean'];
  items?: Maybe<Array<GroupAssignmentItem>>;
  lastModifiedBy?: Maybe<User>;
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  lastModifiedUser?: Maybe<User>;
};

export type GroupAssignmentItem = {
  __typename?: 'GroupAssignmentItem';
  endDate: Scalars['Date'];
  groupA: GroupAssignmentType;
  groupB: GroupAssignmentType;
  startDate: Scalars['Date'];
  weekNumber: Scalars['Int'];
};

export type GroupAssignmentItemInput = {
  endDate: Scalars['Date'];
  groupA: GroupAssignmentType;
  groupB: GroupAssignmentType;
  startDate: Scalars['Date'];
  weekNumber: Scalars['Int'];
};

export enum GroupAssignmentType {
  Digital = 'Digital',
  Onsite = 'Onsite'
}

/** A handout. This is used in Teachers -> Reminders and can be of three different types of `HandoutType`. */
export type Handout = {
  __typename?: 'Handout';
  _id: Scalars['ObjectId'];
  /** The academic week this handout is for. */
  academicWeek: AcademicWeek;
  /** The channel the handout is sent to. */
  channel: HandoutChannel;
  /** The Reference to the Course this handout belongs to. */
  course: Course;
  /** The ID of the course this handout belongs to. */
  courseId: Scalars['ObjectId'];
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** The enrollment type the Handout is intended for. Only Regular and Trial is allowed. */
  enrollmentType: EnrollmentType;
  /** A file to be attached to the course. This is used for handouts that are of type `HandoutType.Answers` */
  file?: Maybe<HandoutFile>;
  /** The instructions for the handout. This is formatted in Markdown. */
  instructions?: Maybe<Scalars['String']>;
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<User>;
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  lastModifiedUser?: Maybe<User>;
  /** The type of handout. This is either instructions for the handout itself, or instructions to parents. */
  type: HandoutType;
  /** The Reference to the Worksheet this handout is linked to. */
  worksheet?: Maybe<WorkSheet>;
  /** The ID of the Worksheet this handout is linked to. */
  worksheetId: Scalars['ObjectId'];
};

export enum HandoutChannel {
  Portal = 'Portal',
  WhatsApp = 'WhatsApp'
}

/** An uploaded file for a handout. This is used for handouts that are of type `HandoutType.Answers` */
export type HandoutFile = {
  __typename?: 'HandoutFile';
  /** The name of the file. */
  fileName: Scalars['String'];
  /** The URL of the file. */
  url: Scalars['String'];
};

/** An uploaded file for a handout. This is used for handouts that are of type `HandoutType.Answers` */
export type HandoutFileInput = {
  /** The name of the file. */
  fileName: Scalars['String'];
  /** The URL of the file. */
  url: Scalars['String'];
};

/** Arguments to be used when filtering handouts. */
export type HandoutFilterInput = {
  /** The Academic Week to filter the handouts by. This only needs the weekNumber as an input. */
  academicWeek?: InputMaybe<AcademicWeekInput>;
  channels?: InputMaybe<Array<HandoutChannel>>;
  /** The IDs of the courses to filter by. If not provided, handouts for all courses will be returned. */
  courseIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  /** The enrollment types to filter by. */
  enrollmentTypes?: InputMaybe<Array<EnrollmentType>>;
  /** The handout types to filter by. This determines who the handout is intended for. */
  types?: InputMaybe<Array<HandoutType>>;
};

export type HandoutPage = {
  __typename?: 'HandoutPage';
  items: Array<Handout>;
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export enum HandoutType {
  Answers = 'Answers',
  Course = 'Course',
  Parent = 'Parent'
}

export type Holiday = {
  __typename?: 'Holiday';
  _id: Scalars['ObjectId'];
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<User>;
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  lastModifiedUser?: Maybe<User>;
  name: Scalars['String'];
  period: DateRange;
  type?: Maybe<Scalars['String']>;
};

export type HolidayFilterInput = {
  year?: InputMaybe<Scalars['Int']>;
};

export type Homework = {
  __typename?: 'Homework';
  _id: Scalars['ObjectId'];
  content: Scalars['String'];
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** The duration of the `homework` in minutes. */
  duration?: Maybe<Scalars['Int']>;
  gradableType?: Maybe<GradableType>;
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<User>;
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  lastModifiedUser?: Maybe<User>;
  lessonNumber: Scalars['Int'];
  needSubmit?: Maybe<Scalars['Boolean']>;
  order: Scalars['Int'];
  /** Contains the `status` and `mechanics` of an `Activity` which this case is a `Homework`. */
  reward?: Maybe<RewardInfo>;
  totalBreakdown?: Maybe<MarkBreakDown>;
  totalGrade?: Maybe<Scalars['Int']>;
  workSheet: WorkSheet;
  workSheetId?: Maybe<Scalars['ObjectId']>;
};

export type IdInput = {
  id: Scalars['ObjectId'];
};

export type ImportWorksheetsInput = {
  /** The file to upload. This is a FileUpload object that is created by the `<Upload/>` component of Antd. */
  file?: InputMaybe<Scalars['Upload']>;
};

export type Integration = {
  __typename?: 'Integration';
  _id: Scalars['ObjectId'];
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<User>;
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  lastModifiedUser?: Maybe<User>;
  name: Scalars['String'];
  otherAccounts: Array<OtherMappingAccount>;
  tenant?: Maybe<XeroTenant>;
  token?: Maybe<TokenSet>;
};

export type Invoice = {
  __typename?: 'Invoice';
  _id: Scalars['ObjectId'];
  amountPaid?: Maybe<Scalars['Decimal']>;
  billedFor?: Maybe<Student>;
  billedTo?: Maybe<Parent>;
  billedToParent?: Maybe<Parent>;
  billingFrequency?: Maybe<BillingFrequency>;
  billingPeriod?: Maybe<Scalars['Int']>;
  billingYear?: Maybe<Scalars['Int']>;
  class?: Maybe<Class>;
  course?: Maybe<Course>;
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  destination?: Maybe<Enrollment>;
  dueDate?: Maybe<Scalars['DateTime']>;
  enrollment: Enrollment;
  hasTransferred: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<User>;
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  lastModifiedUser?: Maybe<User>;
  lessonDates?: Maybe<Array<Scalars['DateTime']>>;
  lessons?: Maybe<Array<Lesson>>;
  lineItems?: Maybe<Array<LineItem>>;
  nextInvoice?: Maybe<Invoice>;
  nextInvoiceDate?: Maybe<Scalars['DateTime']>;
  payments?: Maybe<Array<Payment>>;
  receipts?: Maybe<Array<Receipt>>;
  referenceNumber: Scalars['String'];
  reminders?: Maybe<Array<Reminder>>;
  status: InvoiceStatus;
  student?: Maybe<Student>;
  subtotal?: Maybe<Scalars['Decimal']>;
  total?: Maybe<Scalars['Decimal']>;
  totalTax?: Maybe<Scalars['Decimal']>;
  xeroInvoices?: Maybe<Array<XeroInvoiceInfo>>;
};

export type InvoiceFilterInput = {
  billedForStudentIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  courseIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  dateRange?: InputMaybe<DateTimeRangeInput>;
  dueDateRange?: InputMaybe<DateTimeRangeInput>;
  invoiceIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  lessonDateRange?: InputMaybe<DateTimeRangeInput>;
  lessonDays?: InputMaybe<Array<WeekDay>>;
  name?: InputMaybe<Scalars['String']>;
  parentId?: InputMaybe<Scalars['ObjectId']>;
  status?: InputMaybe<Array<InvoiceStatus>>;
  teacherIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  xeroInvoiceDate?: InputMaybe<XeroInvoiceDateInput>;
};

export type InvoicePage = {
  __typename?: 'InvoicePage';
  items: Array<Invoice>;
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export type InvoicePaidSnapshot = {
  __typename?: 'InvoicePaidSnapshot';
  invoiceId: Scalars['ObjectId'];
  paid?: Maybe<Scalars['Decimal']>;
};

export enum InvoiceStatus {
  Draft = 'Draft',
  Overdue = 'Overdue',
  Paid = 'Paid',
  Pending = 'Pending',
  Void = 'Void'
}

export enum LearningArrangementType {
  Digital = 'Digital',
  Hybrid = 'Hybrid',
  Onsite = 'Onsite'
}

export type Lesson = {
  __typename?: 'Lesson';
  _id: Scalars['ObjectId'];
  absentStudent?: Maybe<Scalars['Int']>;
  academicWeek?: Maybe<AcademicWeek>;
  additional: Scalars['Boolean'];
  attendance?: Maybe<Array<Attendance>>;
  capacity?: Maybe<LessonCapacity>;
  class?: Maybe<Class>;
  classId: Scalars['ObjectId'];
  course?: Maybe<Course>;
  courseId: Scalars['ObjectId'];
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  enrollment?: Maybe<Array<Enrollment>>;
  holidayEffectedEnrollments?: Maybe<Array<Enrollment>>;
  holidays?: Maybe<Array<Holiday>>;
  isArchived: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  isHoliday?: Maybe<Scalars['Boolean']>;
  isOverlappedTeacher?: Maybe<Scalars['Boolean']>;
  isOverlappedVenue?: Maybe<Scalars['Boolean']>;
  lastModifiedBy?: Maybe<User>;
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  lastModifiedUser?: Maybe<User>;
  lessonDay: WeekDay;
  lessonEnd: Scalars['DateTime'];
  lessonNotes?: Maybe<Scalars['String']>;
  lessonNumber: Scalars['Float'];
  lessonStart: Scalars['DateTime'];
  maximumClassCapacity?: Maybe<Scalars['Int']>;
  noStatusStudent?: Maybe<Scalars['Int']>;
  remarks?: Maybe<Scalars['String']>;
  status?: Maybe<LessonStatus>;
  teacher?: Maybe<Teacher>;
  totalStudent?: Maybe<Scalars['Int']>;
  venue?: Maybe<Venue>;
};


export type LessonAttendanceArgs = {
  status?: InputMaybe<Array<AttendanceStatus>>;
  type?: InputMaybe<Array<AttendanceType>>;
};

export type LessonCapacity = {
  __typename?: 'LessonCapacity';
  class?: Maybe<Scalars['Int']>;
  enrolled?: Maybe<Scalars['Int']>;
  makeUp?: Maybe<Scalars['Int']>;
  remainingMakeUpSeat?: Maybe<Scalars['Int']>;
  remainingSeat?: Maybe<Scalars['Int']>;
  replacing?: Maybe<Scalars['Int']>;
  seats?: Maybe<Array<LessonSeat>>;
  total?: Maybe<Scalars['Int']>;
};

export type LessonFilterInput = {
  academicLevelIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  academicWeek?: InputMaybe<AcademicWeekInput>;
  academicYearIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  classIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  classStatus?: InputMaybe<Array<ClassStatus>>;
  classTypes?: InputMaybe<Array<ClassType>>;
  courseIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  dateRange?: InputMaybe<DateTimeRangeInput>;
  endTime?: InputMaybe<Scalars['Time']>;
  holidayIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  isAdHoc?: InputMaybe<Scalars['Boolean']>;
  isFull?: InputMaybe<Scalars['Boolean']>;
  lessonDays?: InputMaybe<Array<WeekDay>>;
  lessonNumber?: InputMaybe<Array<Scalars['Int']>>;
  name?: InputMaybe<Scalars['String']>;
  showVoided?: InputMaybe<Scalars['Boolean']>;
  skipHoliday?: InputMaybe<Scalars['Boolean']>;
  startAndEndTime?: InputMaybe<TimeRangeInput>;
  startTime?: InputMaybe<Scalars['Time']>;
  studentId?: InputMaybe<Scalars['ObjectId']>;
  teacherIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  venueIds?: InputMaybe<Array<Scalars['ObjectId']>>;
};

export type LessonFlow = {
  __typename?: 'LessonFlow';
  _id: Scalars['ObjectId'];
  academicYear?: Maybe<AcademicYear>;
  appliedClasses?: Maybe<Array<Class>>;
  appliedCourses?: Maybe<Array<Course>>;
  course?: Maybe<Course>;
  /** The ID of the course assigned to the lesson flow. This will be used by the course to determine it's assigned `lessonFlow`s. Notice: `courseId` will soon be changed to required in the future. */
  courseId?: Maybe<Scalars['ObjectId']>;
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  isArchived: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<User>;
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  lastModifiedUser?: Maybe<User>;
  /** Reference of Lesson Flow Items assigned to the Lesson Flow. These contain activities for the Lesson. */
  lessonFlowItemIds?: Maybe<Array<Scalars['ObjectId']>>;
  lessonFlowItems?: Maybe<Array<LessonFlowItem>>;
  name: Scalars['String'];
};

export type LessonFlowAcademicWeekInput = {
  endDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
  weekNumber: Scalars['Int'];
};

export type LessonFlowFilterInput = {
  /** Filter by one or more academic levels */
  academicLevels?: InputMaybe<Array<Scalars['ObjectId']>>;
  /** Filter by one or more academic year IDs */
  academicYears?: InputMaybe<Array<Scalars['ObjectId']>>;
  courseId?: InputMaybe<Scalars['ObjectId']>;
  /** Filter by one or more course types */
  courseTypes?: InputMaybe<Array<CourseType>>;
  /** Filter condition if archived items should be returned */
  showArchived?: InputMaybe<Scalars['Boolean']>;
};

export type LessonFlowItem = {
  __typename?: 'LessonFlowItem';
  _id: Scalars['ObjectId'];
  academicWeek: AcademicWeek;
  /** @deprecated Keeping collect to ensure the frontend in admin does not break. Currently unsure wether this would be an issue since the split happened before this has been implemented. */
  collect?: Maybe<Scalars['String']>;
  /** An array of strings that are received from a markdown input from a Rich Text Editor */
  collects?: Maybe<Array<Collect>>;
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  exceptions?: Maybe<Array<Class>>;
  exercises?: Maybe<Array<Exercise>>;
  feedbacks?: Maybe<Array<Feedback>>;
  homeworks?: Maybe<Array<Homework>>;
  isArchived: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<User>;
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  lastModifiedUser?: Maybe<User>;
  lessonFlowId: Scalars['ObjectId'];
  lessonNumber: Scalars['Int'];
  name: Scalars['String'];
  order: Scalars['Int'];
  suitability?: Maybe<Suitability>;
  version: Scalars['Int'];
};

export type LessonFlowPage = {
  __typename?: 'LessonFlowPage';
  items: Array<LessonFlow>;
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

/** This describes the payload of the lesson flow update notification */
export type LessonFlowUpdate = {
  __typename?: 'LessonFlowUpdate';
  /** The date and time that the lesson flow was modified */
  dateModified: Scalars['DateTime'];
  /** The supervisor who modified the lesson flow */
  modifiedBy: Teacher;
  /** Type of the lesson flow update */
  type: LessonFlowUpdateType;
};

export enum LessonFlowUpdateType {
  Collect = 'COLLECT',
  Homework = 'HOMEWORK',
  LessonFlowItem = 'LESSON_FLOW_ITEM',
  Sequence = 'SEQUENCE'
}

export type LessonPage = {
  __typename?: 'LessonPage';
  items: Array<Lesson>;
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export type LessonRecord = {
  __typename?: 'LessonRecord';
  isMakeUp?: Maybe<Scalars['Boolean']>;
  lesson?: Maybe<Lesson>;
  lessonId?: Maybe<Scalars['ObjectId']>;
};

export type LessonSeat = {
  __typename?: 'LessonSeat';
  attendanceId: Scalars['ObjectId'];
  checkFirstLesson?: Maybe<Scalars['Boolean']>;
  date?: Maybe<Scalars['DateTime']>;
  enrollmentStatus?: Maybe<EnrollmentStatus>;
  hasPaid?: Maybe<Scalars['Boolean']>;
  isSimulation?: Maybe<Scalars['Boolean']>;
  seatType?: Maybe<SeatType>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AttendanceStatus>;
  student?: Maybe<Student>;
  type?: Maybe<AttendanceType>;
};

export enum LessonStatus {
  Created = 'Created',
  Incomplete = 'Incomplete',
  Void = 'Void'
}

export type LineItem = {
  __typename?: 'LineItem';
  _id: Scalars['ObjectId'];
  description?: Maybe<Scalars['String']>;
  discountAmount?: Maybe<Scalars['Decimal']>;
  discountRate?: Maybe<Scalars['Decimal']>;
  discountRemarks?: Maybe<Scalars['String']>;
  discountType?: Maybe<DiscountType>;
  itemDetails?: Maybe<LineItemDetail>;
  lineAmount?: Maybe<Scalars['Decimal']>;
  otherFeeType?: Maybe<OtherFeeType>;
  paid?: Maybe<Scalars['Decimal']>;
  quantity?: Maybe<Scalars['Int']>;
  unitAmount?: Maybe<Scalars['Decimal']>;
};

export type LineItemDetail = {
  __typename?: 'LineItemDetail';
  class?: Maybe<Class>;
  classId?: Maybe<Scalars['ObjectId']>;
  lessons?: Maybe<Array<LessonRecord>>;
};

export type LineItemDetailInput = {
  lessonTime?: InputMaybe<Scalars['String']>;
  lessons?: InputMaybe<Array<Scalars['String']>>;
  teacher?: InputMaybe<Scalars['String']>;
  venue?: InputMaybe<Scalars['String']>;
};

export type LineItemInput = {
  description: Scalars['String'];
  itemDetails?: InputMaybe<Array<LineItemDetailInput>>;
  quantity: Scalars['Float'];
  unitAmount: Scalars['Decimal'];
};

export type LinkParentInput = {
  parentId1: Scalars['ObjectId'];
  parentId2: Scalars['ObjectId'];
};

/** Query input on fetching students award points details */
export type ListStudentAwardPointsInput = {
  /** Filter students by enrolled class ID */
  classId?: InputMaybe<Scalars['ObjectId']>;
  /** Filter students by linked gradable Exercise or Homework */
  gradableId: Scalars['ObjectId'];
  /** Filter students by name */
  search?: InputMaybe<Scalars['String']>;
};

export type Log = {
  __typename?: 'Log';
  _id: Scalars['ObjectId'];
  attachment?: Maybe<Scalars['String']>;
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<User>;
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  lastModifiedUser?: Maybe<User>;
  log: Scalars['String'];
  type: LogType;
  userId: Scalars['ObjectId'];
};

export type LogPage = {
  __typename?: 'LogPage';
  items: Array<Log>;
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export enum LogType {
  Other = 'Other',
  PhoneCall = 'PhoneCall',
  WalkIn = 'WalkIn'
}

export enum LogicalOperator {
  And = 'And',
  Or = 'Or'
}

export type MailMerge = {
  __typename?: 'MailMerge';
  _id: Scalars['ObjectId'];
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<User>;
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  lastModifiedUser?: Maybe<User>;
  mergeList?: Maybe<Array<Scalars['Object']>>;
  mergeListHeaders?: Maybe<Array<Scalars['String']>>;
  sender?: Maybe<WhatsappAccount>;
  status: MailMergeStatus;
  template: Template;
};

export enum MailMergeStatus {
  Complete = 'Complete',
  Draft = 'Draft'
}

export type MakeUpCredit = {
  __typename?: 'MakeUpCredit';
  course?: Maybe<Course>;
  courseId: Scalars['ObjectId'];
  exceptions: Scalars['Float'];
  term: Scalars['Float'];
  used: Scalars['Float'];
  year: Scalars['Float'];
};

export type MakeUpCreditInfo = {
  __typename?: 'MakeUpCreditInfo';
  currentTerm?: Maybe<MakeUpCreditInfoItem>;
  term1?: Maybe<MakeUpCreditInfoItem>;
  term2?: Maybe<MakeUpCreditInfoItem>;
  term3?: Maybe<MakeUpCreditInfoItem>;
  term4?: Maybe<MakeUpCreditInfoItem>;
  workshop?: Maybe<MakeUpCreditInfoItem>;
};

export type MakeUpCreditInfoItem = {
  __typename?: 'MakeUpCreditInfoItem';
  exceptions?: Maybe<Scalars['Int']>;
  termNumber?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  used?: Maybe<Scalars['Int']>;
};

export type MakeUpCreditInput = {
  term1?: InputMaybe<Scalars['Int']>;
  term2?: InputMaybe<Scalars['Int']>;
  term3?: InputMaybe<Scalars['Int']>;
  term4?: InputMaybe<Scalars['Int']>;
  workshop?: InputMaybe<Scalars['Int']>;
};

export type MakeUpCreditOutput = {
  __typename?: 'MakeUpCreditOutput';
  term1?: Maybe<Scalars['Int']>;
  term2?: Maybe<Scalars['Int']>;
  term3?: Maybe<Scalars['Int']>;
  term4?: Maybe<Scalars['Int']>;
  workshop?: Maybe<Scalars['Int']>;
};

export type MakeUpCredits = {
  __typename?: 'MakeUpCredits';
  term1?: Maybe<Scalars['Int']>;
  term2?: Maybe<Scalars['Int']>;
  term3?: Maybe<Scalars['Int']>;
  term4?: Maybe<Scalars['Int']>;
  workshop?: Maybe<Scalars['Int']>;
};

export type MarkBreakDown = {
  __typename?: 'MarkBreakDown';
  ecq?: Maybe<Scalars['Float']>;
  mcq?: Maybe<Scalars['Float']>;
};

export type MarkBreakDownInput = {
  ecq?: InputMaybe<Scalars['Int']>;
  mcq?: InputMaybe<Scalars['Int']>;
};

export type Merchandise = {
  __typename?: 'Merchandise';
  _id: Scalars['ObjectId'];
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  dateIssued?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  distributionChannel?: Maybe<Scalars['String']>;
  isArchived: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  item: Scalars['String'];
  lastModifiedBy?: Maybe<User>;
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  lastModifiedUser?: Maybe<User>;
  remarks?: Maybe<Scalars['String']>;
  status: MerchandiseStatus;
  student: Student;
};

export type MerchandisePage = {
  __typename?: 'MerchandisePage';
  items: Array<Merchandise>;
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export enum MerchandiseStatus {
  Issued = 'Issued',
  Pending = 'Pending'
}

export type Mutation = {
  __typename?: 'Mutation';
  addAttendanceRemarks?: Maybe<Attendance>;
  addInvoiceLineItem?: Maybe<Invoice>;
  addReward?: Maybe<Reward>;
  /** Mutation to add reward points to student */
  addRewardPoints?: Maybe<Student>;
  addXeroAccount?: Maybe<OtherMappingAccount>;
  applyDiscount?: Maybe<Invoice>;
  approveRegistration?: Maybe<Registration>;
  archiveDiscount?: Maybe<Discount>;
  archiveFinance?: Maybe<Finance>;
  archiveHistoryReward?: Maybe<RewardHistory>;
  archiveReward?: Maybe<Reward>;
  archiveWhatsappAccount?: Maybe<WhatsappAccount>;
  /** Mutation to add reward points to students by bulk */
  bulkAddRewardPoints?: Maybe<StudentBulkAwardsResult>;
  bulkCompileTemplate?: Maybe<ReminderPreview>;
  /** Mutation to deduct reward points to students by bulk */
  bulkDeductRewardPoints?: Maybe<StudentBulkAwardsResult>;
  bulkSendReminder?: Maybe<Scalars['Boolean']>;
  bulkUpdateDigitalClassVenue?: Maybe<Array<Class>>;
  cancelArrangement?: Maybe<Arrangement>;
  cancelChangeAttendingClassForRegistration?: Maybe<Registration>;
  cancelChangeAttendingClassForTransfer?: Maybe<Arrangement>;
  cancelClassCreationFlow?: Maybe<Class>;
  cancelRegistration?: Maybe<Registration>;
  capacitySimulation?: Maybe<Scalars['Boolean']>;
  changeAcademicLevelArchive?: Maybe<AcademicLevel>;
  changeAcademicLevelVisibility?: Maybe<AcademicLevel>;
  changeAdminArchive?: Maybe<Admin>;
  changeAttendingClassForRegistration?: Maybe<Registration>;
  changeAttendingClassForTransfer?: Maybe<Arrangement>;
  changeBranchArchive?: Maybe<Branch>;
  changeClassVisibility?: Maybe<Class>;
  changeClassWaitable?: Maybe<Class>;
  changeDepartmentArchive?: Maybe<Department>;
  changeSchoolArchive?: Maybe<School>;
  changeSchoolVisibility?: Maybe<School>;
  changeUserArchive?: Maybe<User>;
  changeVenueArchive?: Maybe<Venue>;
  clearSimulation?: Maybe<Scalars['Boolean']>;
  compileTemplate?: Maybe<ReminderPreview>;
  completeClassCreationFlow?: Maybe<Class>;
  completeMailMerge?: Maybe<MailMerge>;
  connectXeroAccountForCourse?: Maybe<Course>;
  connectXeroAccountForOtherMapping?: Maybe<Integration>;
  createAcademicLevel?: Maybe<AcademicLevel>;
  createAcademicYear: AcademicYear;
  createAdmin?: Maybe<Admin>;
  createArrangement?: Maybe<Arrangement>;
  createBillingForRegistration?: Maybe<Registration>;
  createBillingForTransfer?: Maybe<Arrangement>;
  createBranch?: Maybe<Branch>;
  createClass?: Maybe<Class>;
  /** Create a collect for the current LessonFlowItem. */
  createCollect?: Maybe<LessonFlowItem>;
  createCourse?: Maybe<Course>;
  createDepartment?: Maybe<Department>;
  createDiscount?: Maybe<Discount>;
  createEligibilityFlow?: Maybe<EligibilityFlow>;
  createExercise?: Maybe<Exercise>;
  createFeedback?: Maybe<Feedback>;
  /** Mutation to create multiple feedback seen receipt records */
  createFeedbackSeenReceipts?: Maybe<Array<FeedbackSeenReceipt>>;
  createFinance?: Maybe<Finance>;
  createGroupAssignment?: Maybe<GroupAssignment>;
  /** Create a new handout. */
  createHandout?: Maybe<Handout>;
  createHoliday?: Maybe<Holiday>;
  createHomework?: Maybe<Homework>;
  createLesson?: Maybe<Lesson>;
  createLessonFlow?: Maybe<LessonFlow>;
  createLessonFlowItem?: Maybe<LessonFlowItem>;
  createLog?: Maybe<Log>;
  createMailMerge?: Maybe<MailMerge>;
  createMerchandise?: Maybe<Merchandise>;
  createNewTermForAcademicYear: AcademicYear;
  createNote?: Maybe<Note>;
  createParent?: Maybe<Parent>;
  createPaymentRecord?: Maybe<Payment>;
  createPromotion?: Maybe<Promotion>;
  createReferenceData?: Maybe<ReferenceData>;
  createRefund?: Maybe<Refund>;
  createRegistration?: Maybe<Registration>;
  createSchool?: Maybe<School>;
  createStudentReportFilter?: Maybe<StudentReportFilter>;
  createTask?: Maybe<Task>;
  createTeacher?: Maybe<Teacher>;
  createTemplate?: Maybe<Template>;
  createTopic?: Maybe<Topic>;
  createUserCustomiseColumn?: Maybe<UserCustomiseColumn>;
  createUserCustomiseColumns?: Maybe<Array<UserCustomiseColumn>>;
  createVenue?: Maybe<Venue>;
  createWaitingListsForMultipleClasses?: Maybe<Array<Registration>>;
  createWhatsappAccount?: Maybe<WhatsappAccount>;
  createWorkSheet?: Maybe<WorkSheet>;
  declineRegistration?: Maybe<Registration>;
  /** Mutation to deduct reward points to student */
  deductRewardPoints?: Maybe<Student>;
  deleteAcademicLevel?: Maybe<AcademicLevel>;
  deleteAcademicYear: AcademicYear;
  deleteAdmin?: Maybe<Admin>;
  /** Mutation that deletes an attendance's remarks record */
  deleteAttendanceRemarks?: Maybe<Attendance>;
  deleteBranch?: Maybe<Branch>;
  /** Delete a collect for the current LessonFlowItem. */
  deleteCollect?: Maybe<LessonFlowItem>;
  deleteDepartment?: Maybe<Centre>;
  deleteFinance?: Maybe<Finance>;
  deleteGroupAssignment?: Maybe<GroupAssignment>;
  /** Delete a handout by id. */
  deleteHandout?: Maybe<Handout>;
  deleteHoliday?: Maybe<Holiday>;
  deleteLesson?: Maybe<Lesson>;
  /** A Mutation to delete a lessonFlowItem and it's exercises, and homeworks. */
  deleteLessonFlowItem?: Maybe<LessonFlowItem>;
  deleteMerchandise?: Maybe<Merchandise>;
  deleteParent?: Maybe<Parent>;
  deletePromotion?: Maybe<Promotion>;
  deleteReferenceData?: Maybe<ReferenceData>;
  deleteSchool?: Maybe<School>;
  deleteStudent?: Maybe<Student>;
  deleteStudentReportFilter?: Maybe<StudentReportFilter>;
  deleteSubmission?: Maybe<Submission>;
  deleteTask?: Maybe<Task>;
  deleteTeacher?: Maybe<Teacher>;
  deleteTermForAcademicYear: AcademicYear;
  deleteTopic?: Maybe<Topic>;
  deleteUser?: Maybe<User>;
  deleteUserCustomiseColumn?: Maybe<UserCustomiseColumn>;
  deleteUserCustomiseColumns?: Maybe<Array<UserCustomiseColumn>>;
  deleteVenue?: Maybe<Venue>;
  deleteWorkSheet?: Maybe<WorkSheet>;
  disconnectXeroAccount?: Maybe<Integration>;
  downloadMailMerge?: Maybe<Csv>;
  /** Mutation that updates an attendance's remarks record */
  editAttendanceRemarks?: Maybe<Attendance>;
  extendsArrangementTime?: Maybe<Arrangement>;
  extendsRegistrationTime?: Maybe<Registration>;
  generateNextInvoice?: Maybe<Invoice>;
  /** A mutation to import worksheets from a csv file. This mutation accepts only a url string of an uploaded csv. */
  importWorkSheets?: Maybe<Array<WorkSheet>>;
  incrementStep?: Maybe<Arrangement>;
  linkParent?: Maybe<Relationship>;
  pauseWhatsAppMessage?: Maybe<WhatsappMessage>;
  pauseWhatsappAccount?: Maybe<WhatsappAccount>;
  processArrangement?: Maybe<Arrangement>;
  processRegistration?: Maybe<Registration>;
  queueNext?: Maybe<WhatsappMessage>;
  readSystemNotification?: Maybe<SystemNotification>;
  reconnectWhatsappAccount?: Maybe<WhatsappAccount>;
  recordExamScore?: Maybe<ExamScore>;
  recoverWhatsappAccount?: Maybe<WhatsappAccount>;
  refreshXeroToken?: Maybe<Array<Integration>>;
  regenerateInvoice?: Maybe<Invoice>;
  regenerateRegistration?: Maybe<Registration>;
  registerStudent?: Maybe<Student>;
  registerStudentFromParent?: Maybe<Student>;
  registrationIncrementStep?: Maybe<Registration>;
  removeLineItem?: Maybe<Invoice>;
  removeLineItemDiscount?: Maybe<Invoice>;
  reorderLessonFlowItems?: Maybe<Array<LessonFlowItem>>;
  replyFeedback?: Maybe<Feedback>;
  requeueWhatsAppMessage?: Maybe<WhatsappMessage>;
  resendReminder?: Maybe<Reminder>;
  resetDefault?: Maybe<Array<UserCustomiseColumn>>;
  restoreDiscount?: Maybe<Discount>;
  restoreLessonFlowItemVersion?: Maybe<LessonFlowItem>;
  restoreReward?: Maybe<Reward>;
  restoreWorkSheet?: Maybe<WorkSheet>;
  resumeWhatsAppMessage?: Maybe<WhatsappMessage>;
  resumeWhatsappAccount?: Maybe<WhatsappAccount>;
  retryUploadInvoice?: Maybe<Invoice>;
  retryWhatsAppMessage?: Maybe<WhatsappMessage>;
  retryXeroPayment?: Maybe<Payment>;
  saveAsNewLessonFlow?: Maybe<LessonFlow>;
  saveSubmissions?: Maybe<Array<Submission>>;
  saveTopics?: Maybe<Array<Topic>>;
  sendReminder?: Maybe<Array<Reminder>>;
  sendTestReminder?: Maybe<Reminder>;
  setDefaultLessonFlow?: Maybe<Course>;
  setLessonFlowItemExceptions?: Maybe<LessonFlowItem>;
  toggleFeature?: Maybe<FeatureAccess>;
  toggleFeatures?: Maybe<Array<FeatureAccess>>;
  unlinkParent?: Maybe<Relationship>;
  updateAcademicLevel?: Maybe<AcademicLevel>;
  updateAcademicYear: AcademicYear;
  updateAdmin?: Maybe<Admin>;
  updateArchivable?: Maybe<Template>;
  updateAttendanceCollection?: Maybe<Attendance>;
  updateAttendanceLessonMode?: Maybe<Attendance>;
  updateBranch?: Maybe<Branch>;
  updateClass?: Maybe<Class>;
  updateClassLessonFlow?: Maybe<Class>;
  /** Update a collect for the current LessonFlowItem. */
  updateCollect?: Maybe<LessonFlowItem>;
  updateCourse?: Maybe<Course>;
  updateDepartment?: Maybe<Department>;
  updateDiscount?: Maybe<Discount>;
  updateEligibilityFlow?: Maybe<EligibilityFlow>;
  updateEnrollmentGroup?: Maybe<Enrollment>;
  updateEnrollmentPreferredLearningArrangement?: Maybe<Enrollment>;
  /** Mutation that upddates an enrollment's suitability information */
  updateEnrollmentSuitability?: Maybe<Enrollment>;
  updateEnrollmentTrialFollowUpStatus?: Maybe<Enrollment>;
  updateExamScore?: Maybe<ExamScore>;
  updateExercise?: Maybe<Exercise>;
  updateFeedback?: Maybe<Feedback>;
  updateFinance?: Maybe<Finance>;
  updateGroupAssignment?: Maybe<GroupAssignment>;
  /** Update a handout by id. */
  updateHandout?: Maybe<Handout>;
  updateHoliday?: Maybe<Holiday>;
  updateHomework?: Maybe<Homework>;
  updateInvoice?: Maybe<Invoice>;
  updateInvoiceItem?: Maybe<Invoice>;
  updateLesson?: Maybe<Lesson>;
  updateLessonAttendance?: Maybe<Lesson>;
  /** Updates lesson flow record with given classIds and lessonFlowItemIds. */
  updateLessonFlow?: Maybe<LessonFlow>;
  updateLessonFlowArchive?: Maybe<LessonFlow>;
  updateLessonFlowItem?: Maybe<LessonFlowItem>;
  updateLog?: Maybe<Log>;
  updateMailMerge?: Maybe<MailMerge>;
  updateMakeUpRemarksAndUseCredit?: Maybe<Arrangement>;
  updateMerchandise?: Maybe<Merchandise>;
  updateNotificationItem?: Maybe<NotificationSetting>;
  updateParent?: Maybe<Parent>;
  updateParentProfile?: Maybe<Parent>;
  updateParentRemarks?: Maybe<Parent>;
  /** Resolver to update existing payment record in an invoice */
  updatePaymentRecord?: Maybe<Payment>;
  updatePromotion?: Maybe<Promotion>;
  updateReferenceData?: Maybe<ReferenceData>;
  updateRegistration?: Maybe<Registration>;
  updateRelationName?: Maybe<Scalars['String']>;
  updateReward?: Maybe<Reward>;
  updateRewardsHistory?: Maybe<RewardHistory>;
  updateSchool?: Maybe<School>;
  updateStudent?: Maybe<Student>;
  updateStudentRemarks?: Maybe<Student>;
  updateStudentReportFilter?: Maybe<StudentReportFilter>;
  updateTask?: Maybe<Task>;
  updateTeacher?: Maybe<Teacher>;
  updateTemplate?: Maybe<Template>;
  updateTermForAcademicYear: AcademicYear;
  updateTopic?: Maybe<Topic>;
  updateUserCustomiseColumn?: Maybe<UserCustomiseColumn>;
  updateUserCustomiseColumns?: Maybe<Array<UserCustomiseColumn>>;
  updateUserPermission?: Maybe<User>;
  updateVenue?: Maybe<Venue>;
  updateWhatsappAccount?: Maybe<WhatsappAccount>;
  updateWorkSheet?: Maybe<WorkSheet>;
  uploadBase64File?: Maybe<Scalars['String']>;
  uploadFile?: Maybe<Scalars['String']>;
  uploadMailMergeMergeList?: Maybe<MailMerge>;
  uploadWorkSheet?: Maybe<WorkSheet>;
  upsertUserCustomiseColumn?: Maybe<UserCustomiseColumn>;
  upsertUserCustomiseColumns?: Maybe<Array<UserCustomiseColumn>>;
  useRewardPoints?: Maybe<Student>;
  validateWhatsappNumber?: Maybe<Scalars['Boolean']>;
  visibilityFinance?: Maybe<Finance>;
  voidClass?: Maybe<Class>;
  voidCourse?: Maybe<Course>;
  voidPaymentRecord?: Maybe<Payment>;
  voidRefund?: Maybe<Refund>;
  whatsappLogin?: Maybe<QrCode>;
  whatsappLogout?: Maybe<WhatsappAccount>;
  xeroApiCallback?: Maybe<Integration>;
};


export type MutationAddAttendanceRemarksArgs = {
  input: AddAttendanceRemarksInput;
};


export type MutationAddInvoiceLineItemArgs = {
  input: AddInvoiceLineItemInput;
};


export type MutationAddRewardArgs = {
  input: AddRewardCatalogueInput;
};


export type MutationAddRewardPointsArgs = {
  input: AddRewardPointsInput;
};


export type MutationAddXeroAccountArgs = {
  input: AddXeroAccountInput;
};


export type MutationApplyDiscountArgs = {
  input: ApplyDiscountInput;
};


export type MutationApproveRegistrationArgs = {
  input: ApproveRegistrationInput;
};


export type MutationArchiveDiscountArgs = {
  id: Scalars['ObjectId'];
};


export type MutationArchiveFinanceArgs = {
  input: ArchiveFinanceInput;
};


export type MutationArchiveHistoryRewardArgs = {
  id: Scalars['ObjectId'];
};


export type MutationArchiveRewardArgs = {
  id: Scalars['ObjectId'];
};


export type MutationArchiveWhatsappAccountArgs = {
  id: Scalars['ObjectId'];
};


export type MutationBulkAddRewardPointsArgs = {
  input: BulkAddRewardPointsInput;
};


export type MutationBulkCompileTemplateArgs = {
  input: BulkSendReminderInput;
};


export type MutationBulkDeductRewardPointsArgs = {
  input: BulkDeductRewardPointsInput;
};


export type MutationBulkSendReminderArgs = {
  input: BulkSendReminderInput;
};


export type MutationBulkUpdateDigitalClassVenueArgs = {
  input: Array<UpdateClassInput>;
};


export type MutationCancelArrangementArgs = {
  id: Scalars['ObjectId'];
};


export type MutationCancelChangeAttendingClassForRegistrationArgs = {
  input: ChangeAttendingClassForRegistrationInput;
};


export type MutationCancelChangeAttendingClassForTransferArgs = {
  input: ChangeAttendingClassForTransferInput;
};


export type MutationCancelClassCreationFlowArgs = {
  input: IdInput;
};


export type MutationCancelRegistrationArgs = {
  id: Scalars['ObjectId'];
  isSingle?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCapacitySimulationArgs = {
  input: SimulationCapacityInput;
};


export type MutationChangeAcademicLevelArchiveArgs = {
  input: ChangeAcademicLevelArchive;
};


export type MutationChangeAcademicLevelVisibilityArgs = {
  input: ChangeAcademicLevelVisibility;
};


export type MutationChangeAdminArchiveArgs = {
  input: ChangeAdminArchiveInput;
};


export type MutationChangeAttendingClassForRegistrationArgs = {
  input: ChangeAttendingClassForRegistrationInput;
};


export type MutationChangeAttendingClassForTransferArgs = {
  input: ChangeAttendingClassForTransferInput;
};


export type MutationChangeBranchArchiveArgs = {
  input: ChangeBranchArchiveInput;
};


export type MutationChangeClassVisibilityArgs = {
  input: ChangeClassVisibilityInput;
};


export type MutationChangeClassWaitableArgs = {
  input: ChangeClassWaitableInput;
};


export type MutationChangeDepartmentArchiveArgs = {
  input: ChangeDepartmentArchiveInput;
};


export type MutationChangeSchoolArchiveArgs = {
  input: ChangeSchoolArchiveInput;
};


export type MutationChangeSchoolVisibilityArgs = {
  input: ChangeSchoolVisibilityInput;
};


export type MutationChangeUserArchiveArgs = {
  input: ChangeUserArchiveInput;
};


export type MutationChangeVenueArchiveArgs = {
  input: ChangeVenueArchiveInput;
};


export type MutationClearSimulationArgs = {
  input: IdInput;
};


export type MutationCompileTemplateArgs = {
  input: SendReminderInput;
};


export type MutationCompleteClassCreationFlowArgs = {
  input: IdInput;
};


export type MutationCompleteMailMergeArgs = {
  input: CompleteMailMergeInput;
};


export type MutationConnectXeroAccountForCourseArgs = {
  input: ConnectXeroAccountForCourseInput;
};


export type MutationConnectXeroAccountForOtherMappingArgs = {
  input: ConnectXeroAccountInput;
};


export type MutationCreateAcademicLevelArgs = {
  input: CreateAcademicLevelInput;
};


export type MutationCreateAcademicYearArgs = {
  input: CreateAcademicYearInput;
};


export type MutationCreateAdminArgs = {
  input: CreateAdminInput;
};


export type MutationCreateArrangementArgs = {
  input: CreateArrangementInput;
};


export type MutationCreateBillingForRegistrationArgs = {
  currentStep: Scalars['Int'];
  id: Scalars['ObjectId'];
};


export type MutationCreateBillingForTransferArgs = {
  id: Scalars['ObjectId'];
};


export type MutationCreateBranchArgs = {
  input: CreateBranchInput;
};


export type MutationCreateClassArgs = {
  input: CreateClassInput;
};


export type MutationCreateCollectArgs = {
  input: CreateCollectInput;
};


export type MutationCreateCourseArgs = {
  input: CreateCourseInput;
};


export type MutationCreateDepartmentArgs = {
  input: CreateDepartmentInput;
};


export type MutationCreateDiscountArgs = {
  input: CreateDiscountInput;
};


export type MutationCreateEligibilityFlowArgs = {
  input: CreateEligibilityFlowInput;
};


export type MutationCreateExerciseArgs = {
  input: CreateExerciseInput;
};


export type MutationCreateFeedbackArgs = {
  input: CreateFeedbackInput;
};


export type MutationCreateFeedbackSeenReceiptsArgs = {
  inputs: CreateFeedbackSeenReceiptInputs;
};


export type MutationCreateFinanceArgs = {
  input: CreateFinanceInput;
};


export type MutationCreateGroupAssignmentArgs = {
  input: CreateGroupAssignmentInput;
};


export type MutationCreateHandoutArgs = {
  input: CreateHandoutInput;
};


export type MutationCreateHolidayArgs = {
  input: CreateHolidayInput;
};


export type MutationCreateHomeworkArgs = {
  input: CreateHomeworkInput;
};


export type MutationCreateLessonArgs = {
  input: CreateLessonInput;
};


export type MutationCreateLessonFlowArgs = {
  input: CreateLessonFlowInput;
};


export type MutationCreateLessonFlowItemArgs = {
  input: CreateLessonFlowItemInput;
};


export type MutationCreateLogArgs = {
  input: CreateLogInput;
};


export type MutationCreateMailMergeArgs = {
  input: CreateMailMergeInput;
};


export type MutationCreateMerchandiseArgs = {
  input: CreateMerchandiseInput;
};


export type MutationCreateNewTermForAcademicYearArgs = {
  input: CreateTermInput;
};


export type MutationCreateNoteArgs = {
  input: CreateNoteInput;
};


export type MutationCreateParentArgs = {
  input: CreateParentInput;
};


export type MutationCreatePaymentRecordArgs = {
  input: CreatePaymentRecordInput;
};


export type MutationCreatePromotionArgs = {
  input: CreatePromotionInput;
};


export type MutationCreateReferenceDataArgs = {
  input: CreateReferenceDataInput;
};


export type MutationCreateRefundArgs = {
  input: CreateRefundInput;
};


export type MutationCreateRegistrationArgs = {
  input: CreateRegistrationInput;
};


export type MutationCreateSchoolArgs = {
  input: CreateSchoolInput;
};


export type MutationCreateStudentReportFilterArgs = {
  input: StudentReportFilterInput;
};


export type MutationCreateTaskArgs = {
  input: CreateTaskInput;
};


export type MutationCreateTeacherArgs = {
  input: CreateTeacherInput;
};


export type MutationCreateTemplateArgs = {
  input: CreateTemplateInput;
};


export type MutationCreateTopicArgs = {
  input: CreateTopicInput;
};


export type MutationCreateUserCustomiseColumnArgs = {
  input: CreateUserCustomiseColumnInput;
};


export type MutationCreateUserCustomiseColumnsArgs = {
  inputs: Array<CreateUserCustomiseColumnInput>;
};


export type MutationCreateVenueArgs = {
  input: CreateVenueInput;
};


export type MutationCreateWaitingListsForMultipleClassesArgs = {
  input: CreateWaitingListsForStudentInput;
};


export type MutationCreateWhatsappAccountArgs = {
  input: CreateWhatsappAccountInput;
};


export type MutationCreateWorkSheetArgs = {
  input: CreateWorkSheetInput;
};


export type MutationDeclineRegistrationArgs = {
  id: Scalars['ObjectId'];
};


export type MutationDeductRewardPointsArgs = {
  input: DeductRewardPointsInput;
};


export type MutationDeleteAcademicLevelArgs = {
  input: IdInput;
};


export type MutationDeleteAcademicYearArgs = {
  input: IdInput;
};


export type MutationDeleteAdminArgs = {
  input: IdInput;
};


export type MutationDeleteAttendanceRemarksArgs = {
  input: DeleteAttendanceRemarksInput;
};


export type MutationDeleteBranchArgs = {
  input: IdInput;
};


export type MutationDeleteCollectArgs = {
  input: DeleteCollectInput;
};


export type MutationDeleteDepartmentArgs = {
  input: IdInput;
};


export type MutationDeleteFinanceArgs = {
  input: IdInput;
};


export type MutationDeleteGroupAssignmentArgs = {
  id: Scalars['ObjectId'];
};


export type MutationDeleteHandoutArgs = {
  id: Scalars['ObjectId'];
};


export type MutationDeleteHolidayArgs = {
  input: IdInput;
};


export type MutationDeleteLessonArgs = {
  id: Scalars['ObjectId'];
};


export type MutationDeleteLessonFlowItemArgs = {
  id: Scalars['ObjectId'];
};


export type MutationDeleteMerchandiseArgs = {
  input: IdInput;
};


export type MutationDeleteParentArgs = {
  input: IdInput;
};


export type MutationDeletePromotionArgs = {
  id: Scalars['ObjectId'];
};


export type MutationDeleteReferenceDataArgs = {
  input: IdInput;
};


export type MutationDeleteSchoolArgs = {
  input: IdInput;
};


export type MutationDeleteStudentArgs = {
  input: IdInput;
};


export type MutationDeleteStudentReportFilterArgs = {
  id: Scalars['ObjectId'];
};


export type MutationDeleteSubmissionArgs = {
  input: DeleteSubmissionInput;
};


export type MutationDeleteTaskArgs = {
  id: Scalars['ObjectId'];
};


export type MutationDeleteTeacherArgs = {
  input: IdInput;
};


export type MutationDeleteTermForAcademicYearArgs = {
  id: Scalars['ObjectId'];
};


export type MutationDeleteTopicArgs = {
  input: DeleteTopicInput;
};


export type MutationDeleteUserArgs = {
  input: IdInput;
};


export type MutationDeleteUserCustomiseColumnArgs = {
  input: DeleteUserCustomiseColumnInput;
};


export type MutationDeleteUserCustomiseColumnsArgs = {
  inputs: Array<DeleteUserCustomiseColumnInput>;
};


export type MutationDeleteVenueArgs = {
  input: IdInput;
};


export type MutationDeleteWorkSheetArgs = {
  input: IdInput;
};


export type MutationDownloadMailMergeArgs = {
  id: Scalars['ObjectId'];
};


export type MutationEditAttendanceRemarksArgs = {
  input: EditAttendanceRemarksInput;
};


export type MutationExtendsArrangementTimeArgs = {
  id: Scalars['ObjectId'];
};


export type MutationExtendsRegistrationTimeArgs = {
  id: Scalars['ObjectId'];
};


export type MutationGenerateNextInvoiceArgs = {
  id: Scalars['ObjectId'];
};


export type MutationImportWorkSheetsArgs = {
  input: ImportWorksheetsInput;
};


export type MutationIncrementStepArgs = {
  arrangementId: Scalars['ObjectId'];
  step?: InputMaybe<Scalars['Int']>;
};


export type MutationLinkParentArgs = {
  input: LinkParentInput;
};


export type MutationPauseWhatsAppMessageArgs = {
  id: Scalars['ObjectId'];
};


export type MutationPauseWhatsappAccountArgs = {
  id: Scalars['ObjectId'];
};


export type MutationProcessArrangementArgs = {
  id: Scalars['ObjectId'];
};


export type MutationProcessRegistrationArgs = {
  id: Scalars['ObjectId'];
};


export type MutationQueueNextArgs = {
  id: Scalars['ObjectId'];
};


export type MutationReadSystemNotificationArgs = {
  id: Scalars['ObjectId'];
};


export type MutationReconnectWhatsappAccountArgs = {
  id: Scalars['ObjectId'];
};


export type MutationRecordExamScoreArgs = {
  input: RecordExamScoreInput;
};


export type MutationRecoverWhatsappAccountArgs = {
  id: Scalars['ObjectId'];
};


export type MutationRegenerateInvoiceArgs = {
  id: Scalars['ObjectId'];
};


export type MutationRegenerateRegistrationArgs = {
  input: RegenerateRegistrationInput;
};


export type MutationRegisterStudentArgs = {
  input: RegisterStudentInput;
};


export type MutationRegisterStudentFromParentArgs = {
  input: RegisterStudentFromParentInput;
};


export type MutationRegistrationIncrementStepArgs = {
  currentStep: Scalars['Int'];
  registrationId: Scalars['ObjectId'];
};


export type MutationRemoveLineItemArgs = {
  lineItemId: Scalars['ObjectId'];
};


export type MutationRemoveLineItemDiscountArgs = {
  lineItemId: Scalars['ObjectId'];
};


export type MutationReorderLessonFlowItemsArgs = {
  input: ReorderLessonFlowItemsInput;
};


export type MutationReplyFeedbackArgs = {
  input: ReplyFeedbackInput;
};


export type MutationRequeueWhatsAppMessageArgs = {
  id: Scalars['ObjectId'];
};


export type MutationResendReminderArgs = {
  id: Scalars['ObjectId'];
};


export type MutationResetDefaultArgs = {
  input: ResetDefaultInput;
};


export type MutationRestoreDiscountArgs = {
  id: Scalars['ObjectId'];
};


export type MutationRestoreLessonFlowItemVersionArgs = {
  input: RestoreLessonFlowItemVersion;
};


export type MutationRestoreRewardArgs = {
  id: Scalars['ObjectId'];
};


export type MutationRestoreWorkSheetArgs = {
  worksheetId: Scalars['ObjectId'];
};


export type MutationResumeWhatsAppMessageArgs = {
  id: Scalars['ObjectId'];
};


export type MutationResumeWhatsappAccountArgs = {
  id: Scalars['ObjectId'];
};


export type MutationRetryUploadInvoiceArgs = {
  date: Scalars['DateTime'];
  invoiceId: Scalars['ObjectId'];
};


export type MutationRetryWhatsAppMessageArgs = {
  id: Scalars['ObjectId'];
};


export type MutationRetryXeroPaymentArgs = {
  paymentId: Scalars['ObjectId'];
};


export type MutationSaveAsNewLessonFlowArgs = {
  input: SaveAsNewLessonFlowInput;
};


export type MutationSaveSubmissionsArgs = {
  input: SaveSubmissionsInput;
};


export type MutationSaveTopicsArgs = {
  input: SaveTopicsInput;
};


export type MutationSendReminderArgs = {
  input: SendReminderInput;
};


export type MutationSendTestReminderArgs = {
  input: SendTestReminderInput;
};


export type MutationSetDefaultLessonFlowArgs = {
  id: Scalars['ObjectId'];
};


export type MutationSetLessonFlowItemExceptionsArgs = {
  input: SetLessonFlowItemExceptionsInput;
};


export type MutationToggleFeatureArgs = {
  input: UpdateFeatureAccessInput;
};


export type MutationToggleFeaturesArgs = {
  input: UpdateFeatureAccessesInput;
};


export type MutationUnlinkParentArgs = {
  input: LinkParentInput;
};


export type MutationUpdateAcademicLevelArgs = {
  input: UpdateAcademicLevelInput;
};


export type MutationUpdateAcademicYearArgs = {
  input: UpdateAcademicYearInput;
};


export type MutationUpdateAdminArgs = {
  input: UpdateAdminInput;
};


export type MutationUpdateArchivableArgs = {
  id: Scalars['ObjectId'];
  isArchived: Scalars['Boolean'];
};


export type MutationUpdateAttendanceCollectionArgs = {
  input: UpdateAttendanceCollectionInput;
};


export type MutationUpdateAttendanceLessonModeArgs = {
  input: UpdateAttendanceLessonModeInput;
};


export type MutationUpdateBranchArgs = {
  input: UpdateBranchInput;
};


export type MutationUpdateClassArgs = {
  input: UpdateClassInput;
};


export type MutationUpdateClassLessonFlowArgs = {
  input: ChangeClassLessonFlowInput;
};


export type MutationUpdateCollectArgs = {
  input: UpdateCollectInput;
};


export type MutationUpdateCourseArgs = {
  input: UpdateCourseInput;
};


export type MutationUpdateDepartmentArgs = {
  input: UpdateDepartmentInput;
};


export type MutationUpdateDiscountArgs = {
  input: UpdateDiscountInput;
};


export type MutationUpdateEligibilityFlowArgs = {
  input: UpdateEligibilityFlowInput;
};


export type MutationUpdateEnrollmentGroupArgs = {
  input: UpdateEnrollmentGroupInput;
};


export type MutationUpdateEnrollmentPreferredLearningArrangementArgs = {
  input: UpdateEnrollmentPreferredLearningArrangementInput;
};


export type MutationUpdateEnrollmentSuitabilityArgs = {
  input: UpdateEnrollmentSuitabilityInput;
};


export type MutationUpdateEnrollmentTrialFollowUpStatusArgs = {
  input: UpdateEnrollmentTrialFollowUpStatusInput;
};


export type MutationUpdateExamScoreArgs = {
  input: UpdateExamScoreInput;
};


export type MutationUpdateExerciseArgs = {
  input: UpdateExerciseInput;
};


export type MutationUpdateFeedbackArgs = {
  input: UpdateFeedbackInput;
};


export type MutationUpdateFinanceArgs = {
  input: UpdateFinanceInput;
};


export type MutationUpdateGroupAssignmentArgs = {
  input: UpdateGroupAssignmentInput;
};


export type MutationUpdateHandoutArgs = {
  input: UpdateHandoutInput;
};


export type MutationUpdateHolidayArgs = {
  input: UpdateHolidayInput;
};


export type MutationUpdateHomeworkArgs = {
  input: UpdateHomeworkInput;
};


export type MutationUpdateInvoiceArgs = {
  input: UpdateInvoiceInput;
};


export type MutationUpdateInvoiceItemArgs = {
  input: UpdateInvoiceItemInput;
};


export type MutationUpdateLessonArgs = {
  input: UpdateLessonInput;
};


export type MutationUpdateLessonAttendanceArgs = {
  input: UpdateLessonAttendanceInput;
};


export type MutationUpdateLessonFlowArgs = {
  input: UpdateLessonFlowInput;
};


export type MutationUpdateLessonFlowArchiveArgs = {
  archive: Scalars['Boolean'];
  id: Scalars['ObjectId'];
};


export type MutationUpdateLessonFlowItemArgs = {
  input: UpdateLessonFlowItemInput;
};


export type MutationUpdateLogArgs = {
  input: UpdateLogInput;
};


export type MutationUpdateMailMergeArgs = {
  input: UpdateMailMergeInput;
};


export type MutationUpdateMakeUpRemarksAndUseCreditArgs = {
  input: UpdateMakeUpRemarksAndUseCreditInput;
};


export type MutationUpdateMerchandiseArgs = {
  input: UpdateMerchandiseInput;
};


export type MutationUpdateNotificationItemArgs = {
  input: NotificationInput;
};


export type MutationUpdateParentArgs = {
  input: UpdateParentInput;
};


export type MutationUpdateParentProfileArgs = {
  input: UpdateParentProfileInput;
};


export type MutationUpdateParentRemarksArgs = {
  input: UpdateParentRemarksInput;
};


export type MutationUpdatePaymentRecordArgs = {
  input: UpdatePaymentRecordInput;
};


export type MutationUpdatePromotionArgs = {
  input: UpdatePromotionInput;
};


export type MutationUpdateReferenceDataArgs = {
  input: UpdateReferenceDataInput;
};


export type MutationUpdateRegistrationArgs = {
  input: UpdateRegistrationInput;
};


export type MutationUpdateRelationNameArgs = {
  input: UpdateRelationshipInput;
};


export type MutationUpdateRewardArgs = {
  input: UpdateRewardCatalogInput;
};


export type MutationUpdateRewardsHistoryArgs = {
  input: UpdateRewardsHistoryInput;
};


export type MutationUpdateSchoolArgs = {
  input: UpdateSchoolInput;
};


export type MutationUpdateStudentArgs = {
  input: UpdateStudentInput;
};


export type MutationUpdateStudentRemarksArgs = {
  input: UpdateStudentRemarksInput;
};


export type MutationUpdateStudentReportFilterArgs = {
  input: UpdateStudentReportFilterInput;
};


export type MutationUpdateTaskArgs = {
  input: UpdateTaskInput;
};


export type MutationUpdateTeacherArgs = {
  input: UpdateTeacherInput;
};


export type MutationUpdateTemplateArgs = {
  input: UpdateTemplateInput;
};


export type MutationUpdateTermForAcademicYearArgs = {
  input: UpdateTermInput;
};


export type MutationUpdateTopicArgs = {
  input: UpdateTopicInput;
};


export type MutationUpdateUserCustomiseColumnArgs = {
  input: UpdateUserCustomiseColumnInput;
};


export type MutationUpdateUserCustomiseColumnsArgs = {
  inputs: Array<UpdateUserCustomiseColumnInput>;
};


export type MutationUpdateUserPermissionArgs = {
  input: UpdateUserPermissionInput;
};


export type MutationUpdateVenueArgs = {
  input: UpdateVenueInput;
};


export type MutationUpdateWhatsappAccountArgs = {
  input: UpdateWhatsappAccountInput;
};


export type MutationUpdateWorkSheetArgs = {
  input: UpdateWorksheetInput;
};


export type MutationUploadBase64FileArgs = {
  body: Scalars['String'];
  path: UploadFilePath;
};


export type MutationUploadFileArgs = {
  file: Scalars['Upload'];
  path: UploadFilePath;
};


export type MutationUploadMailMergeMergeListArgs = {
  input: UploadMailMergeMergeListInput;
};


export type MutationUpsertUserCustomiseColumnArgs = {
  input: UpsertUserCustomiseColumnInput;
};


export type MutationUpsertUserCustomiseColumnsArgs = {
  inputs: Array<UpsertUserCustomiseColumnInput>;
};


export type MutationUseRewardPointsArgs = {
  input: UseRewardPointsInput;
};


export type MutationValidateWhatsappNumberArgs = {
  input: ValidateWhatsappNumberInput;
};


export type MutationVisibilityFinanceArgs = {
  input: VisibilityFinanceInput;
};


export type MutationVoidClassArgs = {
  input: VoidInput;
};


export type MutationVoidCourseArgs = {
  input: VoidInput;
};


export type MutationVoidPaymentRecordArgs = {
  input: VoidPaymentRecordInput;
};


export type MutationVoidRefundArgs = {
  id: Scalars['ObjectId'];
};


export type MutationWhatsappLoginArgs = {
  id: Scalars['ObjectId'];
};


export type MutationWhatsappLogoutArgs = {
  id: Scalars['ObjectId'];
};


export type MutationXeroApiCallbackArgs = {
  callbackUrl: Scalars['String'];
};

export type Note = {
  __typename?: 'Note';
  _id: Scalars['ObjectId'];
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<User>;
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  lastModifiedUser?: Maybe<User>;
  note: Scalars['String'];
  userId: Scalars['ObjectId'];
};

export type NotePage = {
  __typename?: 'NotePage';
  items: Array<Note>;
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export enum NotificationChannel {
  Email = 'Email',
  Whatsapp = 'Whatsapp'
}

export type NotificationCondition = {
  __typename?: 'NotificationCondition';
  attendanceStatuses: Array<AttendanceStatus>;
  attendanceTypes: Array<AttendanceType>;
  classStatuses: Array<ClassStatus>;
  courseStatuses: Array<CourseStatus>;
  courseTypes: Array<CourseType>;
  enrollmentStatuses: Array<EnrollmentStatus>;
  enrollmentTypes: Array<EnrollmentType>;
  invoiceStatuses: Array<InvoiceStatus>;
};

export type NotificationInput = {
  id: Scalars['ObjectId'];
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  templateId?: InputMaybe<Scalars['ObjectId']>;
};

export type NotificationSetting = {
  __typename?: 'NotificationSetting';
  _id: Scalars['ObjectId'];
  conditions: NotificationCondition;
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  daysBefore: Scalars['Int'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  groupName: Scalars['String'];
  isDeleted: Scalars['Boolean'];
  isEnabled: Scalars['Boolean'];
  lastModifiedBy?: Maybe<User>;
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  lastModifiedUser?: Maybe<User>;
  name: Scalars['String'];
  template?: Maybe<Template>;
  type: NotificationType;
};

export enum NotificationType {
  LessonReminder = 'LessonReminder'
}

export type NumberRangeInput = {
  end: Scalars['Int'];
  start: Scalars['Int'];
};

export type OrderHistory = {
  __typename?: 'OrderHistory';
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<User>;
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  lastModifiedUser?: Maybe<User>;
  status: RewardHistoryStatus;
};

export type OtherFeeInput = {
  amount?: InputMaybe<Scalars['Decimal']>;
  isApplicable: Scalars['Boolean'];
  name: OtherFeeType;
};

export type OtherFeeOutput = {
  __typename?: 'OtherFeeOutput';
  amount?: Maybe<Scalars['Decimal']>;
  isApplicable: Scalars['Boolean'];
  name: OtherFeeType;
};

export enum OtherFeeType {
  Additional = 'Additional',
  Deposit = 'Deposit',
  Materials = 'Materials',
  Registration = 'Registration',
  Trial = 'Trial'
}

export type OtherMappingAccount = {
  __typename?: 'OtherMappingAccount';
  _id: Scalars['ObjectId'];
  code: Scalars['String'];
  description: Scalars['String'];
  name: Scalars['String'];
  otherFeeType: OtherFeeType;
  taxType?: Maybe<Scalars['String']>;
  type: AccountType;
  xeroAccountsSyncStatus?: Maybe<Scalars['Boolean']>;
};

export type PageInfo = {
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
};

export type Pageable = {
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export type Parent = {
  __typename?: 'Parent';
  _id: Scalars['ObjectId'];
  address?: Maybe<UserAddress>;
  alias?: Maybe<Scalars['String']>;
  alternateUserId?: Maybe<Scalars['String']>;
  alternativeEmails?: Maybe<Array<Scalars['String']>>;
  avatarUrl?: Maybe<Scalars['String']>;
  canRemove?: Maybe<Scalars['Boolean']>;
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  financeStatement?: Maybe<StatementOfAccount>;
  fullName: Scalars['String'];
  gender?: Maybe<Scalars['String']>;
  homePhone?: Maybe<Telephone>;
  isArchived: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<User>;
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  lastModifiedUser?: Maybe<User>;
  latestStudentNumber: Scalars['Int'];
  linkUser?: Maybe<User>;
  linkedWhatsappAccount?: Maybe<WhatsappAccount>;
  linkedWhatsappAccountId?: Maybe<Scalars['ObjectId']>;
  mobile: Telephone;
  mobileLine?: Maybe<Scalars['String']>;
  officePhone?: Maybe<Telephone>;
  relatedParents?: Maybe<Array<Parent>>;
  relatedStudents?: Maybe<Array<Student>>;
  relationName?: Maybe<Scalars['String']>;
  relations?: Maybe<Array<Relationship>>;
  remarks?: Maybe<Scalars['String']>;
  salutation: Scalars['String'];
  userId?: Maybe<Scalars['String']>;
  whatsAppName?: Maybe<Scalars['String']>;
};


export type ParentCanRemoveArgs = {
  id?: InputMaybe<Scalars['ObjectId']>;
};


export type ParentRelationNameArgs = {
  id?: InputMaybe<Scalars['ObjectId']>;
};

export type ParentFilter = {
  alias?: InputMaybe<Scalars['String']>;
  fullName?: InputMaybe<Scalars['String']>;
  mobile?: InputMaybe<TelephoneInput>;
  mobileLine?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type ParentPage = {
  __typename?: 'ParentPage';
  items: Array<Parent>;
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export type Payment = {
  __typename?: 'Payment';
  _id: Scalars['ObjectId'];
  amount?: Maybe<Scalars['Decimal']>;
  balanceAmount?: Maybe<Scalars['Decimal']>;
  bankName?: Maybe<Scalars['String']>;
  cashAmount?: Maybe<Scalars['Decimal']>;
  chequeNumber?: Maybe<Scalars['String']>;
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  credit?: Maybe<Credit>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  depositDate?: Maybe<Scalars['DateTime']>;
  details?: Maybe<Array<PaymentDetail>>;
  isDeleted: Scalars['Boolean'];
  isUseRemainingCredit?: Maybe<Scalars['Boolean']>;
  isVoid?: Maybe<Scalars['Boolean']>;
  lastDigits?: Maybe<Scalars['String']>;
  lastModifiedBy?: Maybe<User>;
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  lastModifiedUser?: Maybe<User>;
  method: Scalars['String'];
  payForInvoiceAmount?: Maybe<Scalars['Decimal']>;
  /** The date when the payment was made */
  paymentDate?: Maybe<Scalars['DateTime']>;
  paymentProcessor?: Maybe<Scalars['String']>;
  processedAt?: Maybe<Scalars['DateTime']>;
  processedBy?: Maybe<User>;
  receipt?: Maybe<Receipt>;
  reference?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  xeroPaymentSyncStatus?: Maybe<XeroSyncStatus>;
  xeroPrepayments?: Maybe<Array<XeroPrepayment>>;
};

export type PaymentDetail = {
  __typename?: 'PaymentDetail';
  amount?: Maybe<Scalars['Decimal']>;
  invoice?: Maybe<Invoice>;
  invoiceId: Scalars['ObjectId'];
  xeroPrepayments?: Maybe<Array<XeroPrepayment>>;
};

export type Pdf = {
  __typename?: 'Pdf';
  url: Scalars['String'];
};

export type Person = {
  alias?: Maybe<Scalars['String']>;
  avatarUrl?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  fullName: Scalars['String'];
  gender?: Maybe<Scalars['String']>;
  mobile: Telephone;
};

export type PhoneNumberInput = {
  countryCode: Scalars['String'];
  number: Scalars['String'];
};

export type PointCounter = {
  __typename?: 'PointCounter';
  expired?: Maybe<Scalars['Int']>;
  expiring?: Maybe<Scalars['Int']>;
  redemptionsMade?: Maybe<Scalars['Int']>;
  remaining?: Maybe<Scalars['Int']>;
};

export type Promotion = {
  __typename?: 'Promotion';
  _id: Scalars['ObjectId'];
  academicLevels: Array<AcademicLevel>;
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<User>;
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  lastModifiedUser?: Maybe<User>;
  promoteToLevel: AcademicLevel;
  scheduledDate: Scalars['DateTime'];
  selectedStudents?: Maybe<Array<Student>>;
  status?: Maybe<PromotionStatus>;
  students?: Maybe<Array<Student>>;
};

export enum PromotionStatus {
  Cancelled = 'Cancelled',
  Completed = 'Completed',
  Failed = 'Failed',
  Pending = 'Pending'
}

export type QrCode = {
  __typename?: 'QrCode';
  image: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  academicLevel?: Maybe<AcademicLevel>;
  academicLevels?: Maybe<Array<AcademicLevel>>;
  academicYear?: Maybe<AcademicYear>;
  academicYears?: Maybe<Array<AcademicYear>>;
  admin?: Maybe<Admin>;
  admins?: Maybe<AdminPage>;
  alternativeLessons?: Maybe<Array<Lesson>>;
  arrangement?: Maybe<Arrangement>;
  arrangements?: Maybe<ArrangementPage>;
  attachedLessonFlows?: Maybe<Array<LessonFlow>>;
  auditLog?: Maybe<AuditLog>;
  auditLogs?: Maybe<AuditLogPage>;
  autocompleteAdmin: CreateAdminOutput;
  autocompleteClass: CreateClassOutput;
  autocompleteCourse: CreateCourseOutput;
  autocompleteParent: CreateParentOutput;
  autocompleteStudent: RegisterStudentOutput;
  autocompleteTeacher: CreateTeacherOutput;
  balanceHistories?: Maybe<BalanceHistoryPage>;
  balanceHistory?: Maybe<Invoice>;
  branch?: Maybe<Branch>;
  branches?: Maybe<Array<Branch>>;
  cancellationEffects?: Maybe<CancellationEffects>;
  centre?: Maybe<Centre>;
  centreAssignments?: Maybe<Array<CentreAssignments>>;
  checkCurrentOptEligibility?: Maybe<Array<CheckReport>>;
  class?: Maybe<Class>;
  classFromStudent?: Maybe<Class>;
  /** Query to get score submission statistics by Class */
  classSubmissionStats?: Maybe<Array<ClassSubmissionStats>>;
  classes?: Maybe<ClassPage>;
  classesFromStudent?: Maybe<ClassPage>;
  /** Count unseen feedback records with given set of filters */
  countUnseenFeedback?: Maybe<FeedbackUnseenCount>;
  countryCodes?: Maybe<Array<CountryCode>>;
  course?: Maybe<Course>;
  courses?: Maybe<CoursePage>;
  credit?: Maybe<Credit>;
  creditExport?: Maybe<Pdf>;
  credits?: Maybe<CreditPage>;
  department?: Maybe<Department>;
  departments?: Maybe<Array<Department>>;
  discount?: Maybe<Discount>;
  discounts?: Maybe<Array<Discount>>;
  eligibilityFlow?: Maybe<EligibilityFlow>;
  enabledFeatures?: Maybe<Array<FeatureAccess>>;
  enrollment?: Maybe<Enrollment>;
  examScore?: Maybe<ExamScore>;
  examScores?: Maybe<Array<ExamScore>>;
  executeEligibilityFlow?: Maybe<FlowResult>;
  exportAdminCsv?: Maybe<Csv>;
  exportArrangementCsv?: Maybe<Csv>;
  exportClassCsv?: Maybe<Csv>;
  exportCourseCsv?: Maybe<Csv>;
  exportCreditCsv?: Maybe<Csv>;
  exportInvoiceCsv?: Maybe<Csv>;
  exportLessonCsv?: Maybe<Csv>;
  exportParentCsv?: Maybe<Csv>;
  exportPromotionCsv?: Maybe<Csv>;
  exportReceiptsCsv?: Maybe<Csv>;
  exportRefundCsv?: Maybe<Csv>;
  exportRegistrationCsv?: Maybe<Csv>;
  exportStatementOfAccountCsv?: Maybe<Csv>;
  exportStudentCsv?: Maybe<Csv>;
  exportStudentReportCsv?: Maybe<Csv>;
  /** Query to process submission audit logs to CSV */
  exportSubmissionAuditLog?: Maybe<Csv>;
  /** Query for exporting submission tracking report. */
  exportSubmissionTracking?: Maybe<Csv>;
  exportTeacherCsv?: Maybe<Csv>;
  /** Mutation to export list of worksheets to CSV. This will return the url of the generated CSV file that can be downloaded. */
  exportWorkSheets?: Maybe<Csv>;
  features?: Maybe<Array<FeatureAccess>>;
  feedback?: Maybe<Feedback>;
  /** Query to get a single Feedback Seen Receipt record */
  feedbackSeenReceipt?: Maybe<FeedbackSeenReceipt>;
  /** Query to fetch list of Feedback Seen Receipt records */
  feedbackSeenReceipts?: Maybe<FeedbackSeenReceiptPage>;
  feedbacks?: Maybe<FeedbackPage>;
  finance?: Maybe<Finance>;
  finances: Array<Finance>;
  generateUserId?: Maybe<Scalars['String']>;
  getAcademicWeeksByCourseId?: Maybe<AcademicWeekData>;
  getEligibilityFlowWithCourseId?: Maybe<EligibilityFlow>;
  getMailMerge?: Maybe<MailMerge>;
  getNotificationConfigurations?: Maybe<Array<NotificationSetting>>;
  getStudentsBirthDate?: Maybe<Array<Scalars['Date']>>;
  getStudentsByEnrollment?: Maybe<EnrollmentPage>;
  /** Get topics by one or more courseIds. */
  getTopicsByCourseIds?: Maybe<Array<Topic>>;
  /** Query to get score submission statistics by Gradable (e.g. Exercise, Homework) */
  gradableSubmissionStats?: Maybe<Array<GradableSubmissionStats>>;
  /** Query the handouts depending on the search and filter. */
  handouts?: Maybe<HandoutPage>;
  historyPointCounterBaseDateRange?: Maybe<RewardsHistoryCounter>;
  holiday?: Maybe<Holiday>;
  holidayLessonsIncludeEnrollments?: Maybe<LessonPage>;
  holidays?: Maybe<Array<Holiday>>;
  invoice?: Maybe<Invoice>;
  invoiceExport?: Maybe<Pdf>;
  invoices?: Maybe<InvoicePage>;
  lesson?: Maybe<Lesson>;
  lessonFlow?: Maybe<LessonFlow>;
  lessonFlowItems?: Maybe<Array<LessonFlowItem>>;
  lessonFlows?: Maybe<LessonFlowPage>;
  lessons?: Maybe<LessonPage>;
  /** Query to list students with award point details */
  listStudentAwardPointsDetails?: Maybe<StudentAwardDetailsPage>;
  parent?: Maybe<Parent>;
  parents?: Maybe<ParentPage>;
  promotion?: Maybe<Promotion>;
  promotions?: Maybe<Array<Promotion>>;
  queryTaskCounter?: Maybe<Counter>;
  receipt?: Maybe<Receipt>;
  receiptExport?: Maybe<Pdf>;
  receipts?: Maybe<ReceiptPage>;
  referenceData?: Maybe<ReferenceData>;
  referenceDatas?: Maybe<Array<ReferenceData>>;
  refund?: Maybe<Refund>;
  refundExport?: Maybe<Pdf>;
  refunds?: Maybe<RefundPage>;
  registration?: Maybe<Registration>;
  registrations?: Maybe<RegistrationPage>;
  reminder?: Maybe<Reminder>;
  reminders?: Maybe<ReminderPage>;
  reward?: Maybe<Reward>;
  rewards?: Maybe<RewardPage>;
  /** This is a query that returns a list of `Rewards` that have been awarded to students. This contains information about what the current status of the reward is long with ordering and shipping information. */
  rewardsHistory?: Maybe<RewardHistoryPage>;
  school?: Maybe<School>;
  schools?: Maybe<Array<School>>;
  seatsAnalyticsByAcademicLevel?: Maybe<SeatAnalytics>;
  statementOfAccount?: Maybe<StatementOfAccount>;
  statementOfAccountNumber?: Maybe<StatementOfAccountNumber>;
  statementOfAccounts?: Maybe<StatementOfAccountPage>;
  student?: Maybe<Student>;
  studentMerchandise?: Maybe<MerchandisePage>;
  studentReportFilter?: Maybe<StudentReportFilter>;
  studentReportFilters?: Maybe<Array<StudentReportFilter>>;
  students?: Maybe<StudentPage>;
  submissionAuditLogs?: Maybe<AuditLogPage>;
  submissionTracking?: Maybe<Array<SubmissionTracking>>;
  systemNotification?: Maybe<SystemNotification>;
  systemNotifications?: Maybe<SystemNotificationPage>;
  task?: Maybe<Task>;
  tasks?: Maybe<TaskPage>;
  teacher?: Maybe<Teacher>;
  teachers?: Maybe<TeacherPage>;
  template?: Maybe<Template>;
  templates?: Maybe<TemplatePage>;
  topic?: Maybe<Topic>;
  topics?: Maybe<Array<Topic>>;
  upcomingLesson?: Maybe<Lesson>;
  user?: Maybe<User>;
  userCustomiseColumn?: Maybe<UserCustomiseColumn>;
  userCustomiseColumns?: Maybe<UserCustomiseColumnPage>;
  userLogs?: Maybe<LogPage>;
  userNotes?: Maybe<NotePage>;
  users?: Maybe<UserPage>;
  validateEmail?: Maybe<EmailValidation>;
  validateTeacherAndVenue?: Maybe<TeacherAndVenueValidateResult>;
  validateTeacherAndVenueWithClass?: Maybe<TeacherAndVenueValidateResult>;
  venue?: Maybe<Venue>;
  venues?: Maybe<Array<Venue>>;
  whatsappAccount?: Maybe<WhatsappAccount>;
  whatsappAccounts?: Maybe<WhatsappAccountPage>;
  whatsappMessage?: Maybe<WhatsappMessage>;
  whatsappMessageCounts?: Maybe<WhatsappMessageCounts>;
  whatsappMessages?: Maybe<WhatsappMessagePage>;
  workSheet?: Maybe<WorkSheet>;
  workSheets?: Maybe<WorkSheetPage>;
  xeroAccounts?: Maybe<Array<XeroAccount>>;
  xeroConsentUrl?: Maybe<Scalars['String']>;
  xeroTokenInfo?: Maybe<Integration>;
};


export type QueryAcademicLevelArgs = {
  id: Scalars['ObjectId'];
};


export type QueryAcademicLevelsArgs = {
  ids?: InputMaybe<Array<Scalars['ObjectId']>>;
  showArchived?: InputMaybe<Scalars['Boolean']>;
  showHided?: InputMaybe<Scalars['Boolean']>;
};


export type QueryAcademicYearArgs = {
  id?: InputMaybe<Scalars['ObjectId']>;
  year?: InputMaybe<Scalars['Int']>;
};


export type QueryAdminArgs = {
  id: Scalars['ObjectId'];
};


export type QueryAdminsArgs = {
  filterInput?: InputMaybe<AdminFilterInput>;
  pageInfo?: InputMaybe<PageInfo>;
  search?: InputMaybe<Scalars['String']>;
  sortInfo?: InputMaybe<SortInfo>;
};


export type QueryAlternativeLessonsArgs = {
  input: AlternativeLessonsForChangeAttendingClassInput;
};


export type QueryArrangementArgs = {
  id: Scalars['ObjectId'];
};


export type QueryArrangementsArgs = {
  filterInput?: InputMaybe<ArrangementFilterInput>;
  pageInfo?: InputMaybe<PageInfo>;
  search?: InputMaybe<Scalars['String']>;
  sortInfo?: InputMaybe<SortInfo>;
};


export type QueryAttachedLessonFlowsArgs = {
  workSheetId: Scalars['ObjectId'];
};


export type QueryAuditLogArgs = {
  id: Scalars['ObjectId'];
};


export type QueryAuditLogsArgs = {
  filterInput?: InputMaybe<AuditLogFilterInput>;
  pageInfo?: InputMaybe<PageInfo>;
  search?: InputMaybe<Scalars['String']>;
  sortInfo?: InputMaybe<SortInfo>;
};


export type QueryAutocompleteClassArgs = {
  courseId: Scalars['ObjectId'];
  isAdHoc: Scalars['Boolean'];
};


export type QueryAutocompleteCourseArgs = {
  type: CourseType;
};


export type QueryAutocompleteStudentArgs = {
  primaryParentId: Scalars['ObjectId'];
};


export type QueryBalanceHistoriesArgs = {
  filterInput?: InputMaybe<BalanceHistoryFilterInput>;
  pageInfo?: InputMaybe<PageInfo>;
  sortInfo?: InputMaybe<SortInfo>;
};


export type QueryBalanceHistoryArgs = {
  id: Scalars['ObjectId'];
};


export type QueryBranchArgs = {
  id: Scalars['ObjectId'];
};


export type QueryBranchesArgs = {
  showArchived?: InputMaybe<Scalars['Boolean']>;
};


export type QueryCancellationEffectsArgs = {
  id: Scalars['ObjectId'];
};


export type QueryCentreAssignmentsArgs = {
  studentId: Scalars['ObjectId'];
};


export type QueryCheckCurrentOptEligibilityArgs = {
  courseId: Scalars['ObjectId'];
  studentId: Scalars['ObjectId'];
  type: Scalars['String'];
};


export type QueryClassArgs = {
  id: Scalars['ObjectId'];
};


export type QueryClassFromStudentArgs = {
  classId: Scalars['ObjectId'];
  studentId: Scalars['ObjectId'];
};


export type QueryClassSubmissionStatsArgs = {
  input: ClassSubmissionStatsInput;
};


export type QueryClassesArgs = {
  filterInput?: InputMaybe<ClassFilterInput>;
  pageInfo?: InputMaybe<PageInfo>;
  search?: InputMaybe<Scalars['String']>;
  sortInfo?: InputMaybe<SortInfo>;
};


export type QueryClassesFromStudentArgs = {
  courseId?: InputMaybe<Scalars['ObjectId']>;
  pageInfo?: InputMaybe<PageInfo>;
  search?: InputMaybe<Scalars['String']>;
  sortInfo?: InputMaybe<SortInfo>;
  studentId: Scalars['ObjectId'];
};


export type QueryCountUnseenFeedbackArgs = {
  filterInput?: InputMaybe<FeedbackFilterInput>;
};


export type QueryCourseArgs = {
  id: Scalars['ObjectId'];
};


export type QueryCoursesArgs = {
  filterInput?: InputMaybe<CourseFilterInput>;
  pageInfo?: InputMaybe<PageInfo>;
  search?: InputMaybe<Scalars['String']>;
  sortInfo?: InputMaybe<SortInfo>;
};


export type QueryCreditArgs = {
  id: Scalars['ObjectId'];
};


export type QueryCreditExportArgs = {
  id: Scalars['ObjectId'];
  printBase64?: InputMaybe<Scalars['Boolean']>;
};


export type QueryCreditsArgs = {
  filterInput?: InputMaybe<CreditFilterInput>;
  pageInfo?: InputMaybe<PageInfo>;
  search?: InputMaybe<Scalars['String']>;
  sortInfo?: InputMaybe<SortInfo>;
};


export type QueryDepartmentArgs = {
  id: Scalars['ObjectId'];
};


export type QueryDepartmentsArgs = {
  showArchived?: InputMaybe<Scalars['Boolean']>;
};


export type QueryDiscountArgs = {
  id: Scalars['ObjectId'];
};


export type QueryDiscountsArgs = {
  showArchived?: InputMaybe<Scalars['Boolean']>;
  showVisible?: InputMaybe<Scalars['Boolean']>;
};


export type QueryEligibilityFlowArgs = {
  id: Scalars['ObjectId'];
};


export type QueryEnrollmentArgs = {
  id: Scalars['ObjectId'];
};


export type QueryExamScoreArgs = {
  id: Scalars['ObjectId'];
};


export type QueryExamScoresArgs = {
  studentId: Scalars['ObjectId'];
};


export type QueryExecuteEligibilityFlowArgs = {
  id: Scalars['ObjectId'];
  studentId: Scalars['ObjectId'];
};


export type QueryExportAdminCsvArgs = {
  filterInput?: InputMaybe<AdminFilterInput>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryExportArrangementCsvArgs = {
  filterInput?: InputMaybe<ArrangementFilterInput>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryExportClassCsvArgs = {
  filterInput?: InputMaybe<ClassFilterInput>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryExportCourseCsvArgs = {
  filterInput?: InputMaybe<CourseFilterInput>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryExportCreditCsvArgs = {
  filterInput?: InputMaybe<CreditFilterInput>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryExportInvoiceCsvArgs = {
  filterInput?: InputMaybe<InvoiceFilterInput>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryExportLessonCsvArgs = {
  filterInput?: InputMaybe<LessonFilterInput>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryExportParentCsvArgs = {
  filterInput?: InputMaybe<ParentFilter>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryExportReceiptsCsvArgs = {
  filterInput?: InputMaybe<ReceiptFilterInput>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryExportRefundCsvArgs = {
  filterInput?: InputMaybe<RefundFilterInput>;
};


export type QueryExportRegistrationCsvArgs = {
  filterInput?: InputMaybe<RegistrationFilterInput>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryExportStatementOfAccountCsvArgs = {
  filter?: InputMaybe<StatementOfAccountFilter>;
};


export type QueryExportStudentCsvArgs = {
  columns?: InputMaybe<Array<Scalars['String']>>;
  filterInput?: InputMaybe<StudentFilterInput>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryExportStudentReportCsvArgs = {
  reportFilter: StudentReportFilterInput;
  studentFilter: StudentFilterInput;
};


export type QueryExportSubmissionAuditLogArgs = {
  filterInput?: InputMaybe<SubmissionAuditLogFilterInput>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryExportSubmissionTrackingArgs = {
  classId: Scalars['ObjectId'];
  filter?: InputMaybe<SubmissionTrackingFilter>;
  view: Scalars['String'];
};


export type QueryExportTeacherCsvArgs = {
  filterInput?: InputMaybe<TeacherFilterInput>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryExportWorkSheetsArgs = {
  filterInput?: InputMaybe<WorkSheetItemInput>;
  pageInfo?: InputMaybe<PageInfo>;
  search?: InputMaybe<Scalars['String']>;
  sortInfo?: InputMaybe<SortInfo>;
};


export type QueryFeedbackArgs = {
  id: Scalars['ObjectId'];
};


export type QueryFeedbackSeenReceiptArgs = {
  id: Scalars['ObjectId'];
};


export type QueryFeedbackSeenReceiptsArgs = {
  filterInput?: InputMaybe<FeedbackSeenReceiptsFilterInput>;
  pageInfo?: InputMaybe<PageInfo>;
  search?: InputMaybe<Scalars['String']>;
  sortInfo?: InputMaybe<SortInfo>;
};


export type QueryFeedbacksArgs = {
  filterInput?: InputMaybe<FeedbackFilterInput>;
  pageInfo?: InputMaybe<PageInfo>;
  search?: InputMaybe<Scalars['String']>;
  sortInfo?: InputMaybe<SortInfo>;
};


export type QueryFinanceArgs = {
  id: Scalars['ObjectId'];
};


export type QueryFinancesArgs = {
  showArchived?: InputMaybe<Scalars['Boolean']>;
};


export type QueryGenerateUserIdArgs = {
  input: GenerateUserIdInput;
};


export type QueryGetAcademicWeeksByCourseIdArgs = {
  courseId: Scalars['ObjectId'];
};


export type QueryGetEligibilityFlowWithCourseIdArgs = {
  courseId: Scalars['ObjectId'];
};


export type QueryGetMailMergeArgs = {
  id: Scalars['ObjectId'];
};


export type QueryGetNotificationConfigurationsArgs = {
  type: NotificationType;
};


export type QueryGetStudentsByEnrollmentArgs = {
  enrollmentStatus?: InputMaybe<Array<EnrollmentStatus>>;
  enrollmentType?: InputMaybe<EnrollmentType>;
  filterInput?: InputMaybe<StudentFilterInput>;
  pageInfo?: InputMaybe<PageInfo>;
  search?: InputMaybe<Scalars['String']>;
  sortInfo?: InputMaybe<SortInfo>;
};


export type QueryGetTopicsByCourseIdsArgs = {
  input: GetTopicsByCourseIdsInput;
};


export type QueryGradableSubmissionStatsArgs = {
  input: GradableSubmissionStatsInput;
};


export type QueryHandoutsArgs = {
  filterInput?: InputMaybe<HandoutFilterInput>;
  pageInfo?: InputMaybe<PageInfo>;
  search?: InputMaybe<Scalars['String']>;
  sortInfo?: InputMaybe<SortInfo>;
};


export type QueryHistoryPointCounterBaseDateRangeArgs = {
  dateRange: DateRangeInput;
  studentId: Scalars['ObjectId'];
};


export type QueryHolidayArgs = {
  id: Scalars['ObjectId'];
};


export type QueryHolidayLessonsIncludeEnrollmentsArgs = {
  filterInput?: InputMaybe<LessonFilterInput>;
  pageInfo?: InputMaybe<PageInfo>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryHolidaysArgs = {
  filterInput?: InputMaybe<HolidayFilterInput>;
};


export type QueryInvoiceArgs = {
  id: Scalars['ObjectId'];
};


export type QueryInvoiceExportArgs = {
  id: Scalars['ObjectId'];
  printBase64?: InputMaybe<Scalars['Boolean']>;
};


export type QueryInvoicesArgs = {
  filterInput?: InputMaybe<InvoiceFilterInput>;
  pageInfo?: InputMaybe<PageInfo>;
  search?: InputMaybe<Scalars['String']>;
  sortInfo?: InputMaybe<SortInfo>;
};


export type QueryLessonArgs = {
  id: Scalars['ObjectId'];
};


export type QueryLessonFlowArgs = {
  id: Scalars['ObjectId'];
};


export type QueryLessonFlowItemsArgs = {
  lessonFlowId: Scalars['ObjectId'];
};


export type QueryLessonFlowsArgs = {
  filterInput?: InputMaybe<LessonFlowFilterInput>;
  pageInfo?: InputMaybe<PageInfo>;
  search?: InputMaybe<Scalars['String']>;
  sortInfo?: InputMaybe<SortInfo>;
};


export type QueryLessonsArgs = {
  filterInput?: InputMaybe<LessonFilterInput>;
  pageInfo?: InputMaybe<PageInfo>;
  search?: InputMaybe<Scalars['String']>;
  sortInfo?: InputMaybe<SortInfo>;
};


export type QueryListStudentAwardPointsDetailsArgs = {
  filterInput?: InputMaybe<ListStudentAwardPointsInput>;
  pageInfo?: InputMaybe<PageInfo>;
  sortInfo?: InputMaybe<SortInfo>;
};


export type QueryParentArgs = {
  id: Scalars['ObjectId'];
};


export type QueryParentsArgs = {
  filterInput?: InputMaybe<ParentFilter>;
  pageInfo?: InputMaybe<PageInfo>;
  search?: InputMaybe<Scalars['String']>;
  sortInfo?: InputMaybe<SortInfo>;
};


export type QueryPromotionArgs = {
  id: Scalars['ObjectId'];
};


export type QueryQueryTaskCounterArgs = {
  deadline?: InputMaybe<Scalars['DateTime']>;
};


export type QueryReceiptArgs = {
  id: Scalars['ObjectId'];
};


export type QueryReceiptExportArgs = {
  id: Scalars['ObjectId'];
  printBase64?: InputMaybe<Scalars['Boolean']>;
};


export type QueryReceiptsArgs = {
  filterInput?: InputMaybe<ReceiptFilterInput>;
  pageInfo?: InputMaybe<PageInfo>;
  search?: InputMaybe<Scalars['String']>;
  sortInfo?: InputMaybe<SortInfo>;
};


export type QueryReferenceDataArgs = {
  id: Scalars['ObjectId'];
};


export type QueryReferenceDatasArgs = {
  category?: InputMaybe<ReferenceDataCategory>;
  showArchived?: InputMaybe<Scalars['Boolean']>;
};


export type QueryRefundArgs = {
  id: Scalars['ObjectId'];
};


export type QueryRefundExportArgs = {
  id: Scalars['ObjectId'];
  printBase64?: InputMaybe<Scalars['Boolean']>;
};


export type QueryRefundsArgs = {
  filterInput?: InputMaybe<RefundFilterInput>;
  pageInfo?: InputMaybe<PageInfo>;
  sortInfo?: InputMaybe<SortInfo>;
};


export type QueryRegistrationArgs = {
  id: Scalars['ObjectId'];
};


export type QueryRegistrationsArgs = {
  filterInput?: InputMaybe<RegistrationFilterInput>;
  pageInfo?: InputMaybe<PageInfo>;
  search?: InputMaybe<Scalars['String']>;
  sortInfo?: InputMaybe<SortInfo>;
};


export type QueryReminderArgs = {
  id: Scalars['ObjectId'];
};


export type QueryRemindersArgs = {
  filterInput?: InputMaybe<ReminderFilterInput>;
  pageInfo?: InputMaybe<PageInfo>;
  search?: InputMaybe<Scalars['String']>;
  sortInfo?: InputMaybe<SortInfo>;
};


export type QueryRewardArgs = {
  id: Scalars['ObjectId'];
};


export type QueryRewardsArgs = {
  filterInput?: InputMaybe<RewardFilterInput>;
  pageInfo?: InputMaybe<PageInfo>;
  search?: InputMaybe<Scalars['String']>;
  sortInfo?: InputMaybe<SortInfo>;
};


export type QueryRewardsHistoryArgs = {
  filterInput?: InputMaybe<RewardHistoryFilterInput>;
  pageInfo?: InputMaybe<PageInfo>;
  sortInfo?: InputMaybe<SortInfo>;
};


export type QuerySchoolArgs = {
  id: Scalars['ObjectId'];
};


export type QuerySchoolsArgs = {
  showArchived?: InputMaybe<Scalars['Boolean']>;
  showHided?: InputMaybe<Scalars['Boolean']>;
};


export type QuerySeatsAnalyticsByAcademicLevelArgs = {
  filterInput?: InputMaybe<ClassFilterInput>;
};


export type QueryStatementOfAccountArgs = {
  parentId: Scalars['ObjectId'];
};


export type QueryStatementOfAccountNumberArgs = {
  parentId: Scalars['ObjectId'];
};


export type QueryStatementOfAccountsArgs = {
  filter?: InputMaybe<StatementOfAccountFilter>;
  pageInfo?: InputMaybe<PageInfo>;
  sortInfo?: InputMaybe<SortInfo>;
};


export type QueryStudentArgs = {
  id: Scalars['ObjectId'];
};


export type QueryStudentMerchandiseArgs = {
  pageInfo?: InputMaybe<PageInfo>;
  sortInfo?: InputMaybe<SortInfo>;
  studentId: Scalars['ObjectId'];
};


export type QueryStudentReportFilterArgs = {
  id: Scalars['ObjectId'];
};


export type QueryStudentReportFiltersArgs = {
  userId: Scalars['ObjectId'];
};


export type QueryStudentsArgs = {
  filterInput?: InputMaybe<StudentFilterInput>;
  pageInfo?: InputMaybe<PageInfo>;
  search?: InputMaybe<Scalars['String']>;
  sortInfo?: InputMaybe<SortInfo>;
};


export type QuerySubmissionAuditLogsArgs = {
  filterInput?: InputMaybe<SubmissionAuditLogFilterInput>;
  pageInfo?: InputMaybe<PageInfo>;
  search?: InputMaybe<Scalars['String']>;
  sortInfo?: InputMaybe<SortInfo>;
};


export type QuerySubmissionTrackingArgs = {
  classId: Scalars['ObjectId'];
  enrollmentType?: InputMaybe<EnrollmentType>;
  filter?: InputMaybe<SubmissionTrackingFilter>;
};


export type QuerySystemNotificationArgs = {
  id: Scalars['ObjectId'];
};


export type QuerySystemNotificationsArgs = {
  filterInput?: InputMaybe<SystemNotificationFilterInput>;
  pageInfo?: InputMaybe<PageInfo>;
  search?: InputMaybe<Scalars['String']>;
  sortInfo?: InputMaybe<SortInfo>;
};


export type QueryTaskArgs = {
  id: Scalars['ObjectId'];
};


export type QueryTasksArgs = {
  filterInput?: InputMaybe<TaskFilterInput>;
  pageInfo?: InputMaybe<PageInfo>;
  search?: InputMaybe<Scalars['String']>;
  sortInfo?: InputMaybe<SortInfo>;
};


export type QueryTeacherArgs = {
  id: Scalars['ObjectId'];
};


export type QueryTeachersArgs = {
  filterInput?: InputMaybe<TeacherFilterInput>;
  pageInfo?: InputMaybe<PageInfo>;
  search?: InputMaybe<Scalars['String']>;
  sortInfo?: InputMaybe<SortInfo>;
};


export type QueryTemplateArgs = {
  id: Scalars['ObjectId'];
};


export type QueryTemplatesArgs = {
  filterInput?: InputMaybe<TemplateFilterInput>;
  pageInfo?: InputMaybe<PageInfo>;
  search?: InputMaybe<Scalars['String']>;
  sortInfo?: InputMaybe<SortInfo>;
};


export type QueryTopicArgs = {
  id: Scalars['ObjectId'];
};


export type QueryTopicsArgs = {
  courseId: Scalars['ObjectId'];
};


export type QueryUpcomingLessonArgs = {
  input?: InputMaybe<UpcomingLessonInput>;
};


export type QueryUserArgs = {
  id: Scalars['ObjectId'];
};


export type QueryUserCustomiseColumnArgs = {
  id: Scalars['ObjectId'];
};


export type QueryUserCustomiseColumnsArgs = {
  filterInput?: InputMaybe<UserCustomiseColumnFilterInput>;
  pageInfo?: InputMaybe<PageInfo>;
  search?: InputMaybe<Scalars['String']>;
  sortInfo?: InputMaybe<SortInfo>;
};


export type QueryUserLogsArgs = {
  pageInfo?: InputMaybe<PageInfo>;
  sortInfo?: InputMaybe<SortInfo>;
  userId: Scalars['ObjectId'];
};


export type QueryUserNotesArgs = {
  pageInfo?: InputMaybe<PageInfo>;
  sortInfo?: InputMaybe<SortInfo>;
  userId: Scalars['ObjectId'];
};


export type QueryUsersArgs = {
  filter?: InputMaybe<UserFilterInput>;
  pageInfo?: InputMaybe<PageInfo>;
  search?: InputMaybe<Scalars['String']>;
  sortInfo?: InputMaybe<SortInfo>;
};


export type QueryValidateEmailArgs = {
  email: Scalars['String'];
  excludeUserId?: InputMaybe<Scalars['ObjectId']>;
};


export type QueryValidateTeacherAndVenueArgs = {
  input: ValidateTeacherAndVenueInput;
};


export type QueryValidateTeacherAndVenueWithClassArgs = {
  input: ClassValidationVenueOrTeacherInput;
};


export type QueryVenueArgs = {
  input: QueryVenueInput;
};


export type QueryVenuesArgs = {
  showArchived?: InputMaybe<Scalars['Boolean']>;
};


export type QueryWhatsappAccountArgs = {
  id: Scalars['ObjectId'];
};


export type QueryWhatsappAccountsArgs = {
  filterInput?: InputMaybe<WhatsappAccountFilterInput>;
  pageInfo?: InputMaybe<PageInfo>;
  search?: InputMaybe<Scalars['String']>;
  sortInfo?: InputMaybe<SortInfo>;
};


export type QueryWhatsappMessageArgs = {
  id: Scalars['ObjectId'];
};


export type QueryWhatsappMessageCountsArgs = {
  createDate?: InputMaybe<DateTimeRangeInput>;
  id?: InputMaybe<Scalars['ObjectId']>;
};


export type QueryWhatsappMessagesArgs = {
  filterInput?: InputMaybe<WhatsappMessageFilterInput>;
  pageInfo?: InputMaybe<PageInfo>;
  search?: InputMaybe<Scalars['String']>;
  sortInfo?: InputMaybe<SortInfo>;
};


export type QueryWorkSheetArgs = {
  id: Scalars['ObjectId'];
};


export type QueryWorkSheetsArgs = {
  filterInput?: InputMaybe<WorkSheetItemInput>;
  pageInfo?: InputMaybe<PageInfo>;
  search?: InputMaybe<Scalars['String']>;
  sortInfo?: InputMaybe<SortInfo>;
};

export type QueryVenueInput = {
  /** Id of the branch where the venue is located */
  branchId: Scalars['ObjectId'];
  /** Name of the Venue, this is the combination of the venue unit and classroom number e.g 02-5A CR1 */
  name: Scalars['String'];
};

export type Receipt = {
  __typename?: 'Receipt';
  _id: Scalars['ObjectId'];
  amount?: Maybe<Scalars['Decimal']>;
  amountPaidSnapshot?: Maybe<Scalars['Decimal']>;
  contact?: Maybe<Parent>;
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  emailStatus?: Maybe<ReminderStatus>;
  invoicePaidSnapshots?: Maybe<Array<InvoicePaidSnapshot>>;
  isDeleted: Scalars['Boolean'];
  isNotified?: Maybe<Array<NotificationChannel>>;
  lastModifiedBy?: Maybe<User>;
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  lastModifiedUser?: Maybe<User>;
  parentId?: Maybe<Scalars['ObjectId']>;
  paymentId: Scalars['ObjectId'];
  paymentRecord?: Maybe<Payment>;
  referenceNumber: Scalars['String'];
  status: VoidableStatus;
  waStatus?: Maybe<ReminderStatus>;
};

export type ReceiptFilterInput = {
  dateRange?: InputMaybe<DateTimeRangeInput>;
  id?: InputMaybe<Scalars['ObjectId']>;
  isNotified?: InputMaybe<Array<NotificationChannel>>;
  lessonDateRange?: InputMaybe<DateTimeRangeInput>;
  name?: InputMaybe<Scalars['String']>;
  parentId?: InputMaybe<Scalars['ObjectId']>;
  paymentMethods?: InputMaybe<Array<Scalars['String']>>;
  status?: InputMaybe<Array<VoidableStatus>>;
};

export type ReceiptPage = {
  __typename?: 'ReceiptPage';
  items: Array<Receipt>;
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export type RecordExamScoreInput = {
  academicLevelId: Scalars['ObjectId'];
  examType: Scalars['String'];
  marksPercentage: Scalars['Float'];
  mcq?: InputMaybe<ScoreRecordInput>;
  practical?: InputMaybe<ScoreRecordInput>;
  qeq?: InputMaybe<ScoreRecordInput>;
  scoreGrade: Scalars['String'];
  stream: Scalars['String'];
  studentId: Scalars['ObjectId'];
  subject: Scalars['String'];
  totalMarks: ScoreRecordInput;
  year: Scalars['Int'];
};

export type ReferenceData = {
  __typename?: 'ReferenceData';
  _id: Scalars['ObjectId'];
  category: ReferenceDataCategory;
  countryCode?: Maybe<Scalars['String']>;
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  isArchived: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  key: Scalars['String'];
  lastModifiedBy?: Maybe<User>;
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  lastModifiedUser?: Maybe<User>;
  order?: Maybe<Scalars['Int']>;
  value: Scalars['String'];
};

export enum ReferenceDataCategory {
  AcademicStream = 'AcademicStream',
  ExamType = 'ExamType',
  FeeType = 'FeeType',
  Gender = 'Gender',
  NameOfBank = 'NameOfBank',
  PaymentProcessor = 'PaymentProcessor',
  RewardCategory = 'RewardCategory',
  Salutation = 'Salutation',
  Stream = 'Stream',
  Subject = 'Subject'
}

export type Refund = {
  __typename?: 'Refund';
  _id: Scalars['ObjectId'];
  adminFee?: Maybe<Scalars['Decimal']>;
  amount?: Maybe<Scalars['Decimal']>;
  bankAccountNumber?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  chequeNumber?: Maybe<Scalars['String']>;
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  emailStatus?: Maybe<ReminderStatus>;
  isDeleted: Scalars['Boolean'];
  isVoid?: Maybe<Scalars['Boolean']>;
  lastDigits?: Maybe<Scalars['String']>;
  lastModifiedBy?: Maybe<User>;
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  lastModifiedUser?: Maybe<User>;
  parent?: Maybe<Parent>;
  parentId: Scalars['ObjectId'];
  payNowNumber?: Maybe<Scalars['String']>;
  paymentProcessor?: Maybe<Scalars['String']>;
  processedDate?: Maybe<Scalars['DateTime']>;
  reason?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  referenceNumber: Scalars['String'];
  refundMethod?: Maybe<Scalars['String']>;
  refundedDate?: Maybe<Scalars['DateTime']>;
  waStatus?: Maybe<ReminderStatus>;
};

export type RefundFilterInput = {
  dateRange?: InputMaybe<DateTimeRangeInput>;
  isVoid?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  parentId?: InputMaybe<Scalars['ObjectId']>;
  refundMethods?: InputMaybe<Array<Scalars['String']>>;
};

export type RefundPage = {
  __typename?: 'RefundPage';
  items: Array<Refund>;
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export type RegenerateRegistrationInput = {
  classId: Scalars['ObjectId'];
  currentStep: Scalars['Int'];
  dueDate?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['ObjectId'];
  linkRegistrationId?: InputMaybe<Scalars['ObjectId']>;
  preferredLearningArrangement?: InputMaybe<LearningArrangementType>;
  remarks?: InputMaybe<Scalars['String']>;
  selectedLessons?: InputMaybe<Array<SelectedLessonInput>>;
  startDate: Scalars['DateTime'];
  studentId: Scalars['ObjectId'];
  type: RegistrationType;
};

export type RegisterStudentFromParentInput = {
  academicLevelId: Scalars['ObjectId'];
  alias: Scalars['String'];
  avatarUrl?: InputMaybe<Scalars['String']>;
  birthDate?: InputMaybe<Scalars['DateTime']>;
  fullName: Scalars['String'];
  gender: Scalars['String'];
  primarySchoolId?: InputMaybe<Scalars['ObjectId']>;
  primaryStream?: InputMaybe<Scalars['String']>;
  secondarySchoolId?: InputMaybe<Scalars['ObjectId']>;
  secondaryStream?: InputMaybe<Scalars['String']>;
};

export type RegisterStudentInput = {
  academicLevelId: Scalars['ObjectId'];
  alias: Scalars['String'];
  alternateUserId?: InputMaybe<Scalars['String']>;
  alternativeEmails?: InputMaybe<Array<Scalars['String']>>;
  avatarUrl?: InputMaybe<Scalars['String']>;
  birthDate?: InputMaybe<Scalars['DateTime']>;
  consent: ConsentInput;
  email?: InputMaybe<Scalars['String']>;
  fullName: Scalars['String'];
  gender: Scalars['String'];
  homePhone?: InputMaybe<TelephoneInput>;
  mobile?: InputMaybe<TelephoneInput>;
  parentIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  primaryParentId: Scalars['ObjectId'];
  primarySchoolId?: InputMaybe<Scalars['ObjectId']>;
  primaryStream?: InputMaybe<Scalars['String']>;
  remarks?: InputMaybe<Scalars['String']>;
  secondarySchoolId?: InputMaybe<Scalars['ObjectId']>;
  secondaryStream?: InputMaybe<Scalars['String']>;
  userId: Scalars['String'];
};

export type RegisterStudentOutput = {
  __typename?: 'RegisterStudentOutput';
  academicLevelId: Scalars['ObjectId'];
  alias: Scalars['String'];
  alternateUserId?: Maybe<Scalars['String']>;
  alternativeEmails?: Maybe<Array<Scalars['String']>>;
  avatarUrl?: Maybe<Scalars['String']>;
  birthDate?: Maybe<Scalars['DateTime']>;
  consent: ConsentOutput;
  email?: Maybe<Scalars['String']>;
  fullName: Scalars['String'];
  gender: Scalars['String'];
  homePhone?: Maybe<TelephoneOutput>;
  mobile?: Maybe<TelephoneOutput>;
  parentIds?: Maybe<Array<Scalars['ObjectId']>>;
  primaryParentId: Scalars['ObjectId'];
  primarySchoolId?: Maybe<Scalars['ObjectId']>;
  primaryStream?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  secondarySchoolId?: Maybe<Scalars['ObjectId']>;
  secondaryStream?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
};

export type Registration = {
  __typename?: 'Registration';
  _id: Scalars['ObjectId'];
  approvalDate?: Maybe<Scalars['DateTime']>;
  class?: Maybe<Class>;
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  currentStep?: Maybe<Scalars['Float']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  effectedWaitingLists?: Maybe<Array<Registration>>;
  emailStatus?: Maybe<ReminderStatus>;
  enrollment?: Maybe<Enrollment>;
  hasTransferred?: Maybe<Scalars['Boolean']>;
  incompleteDeadline: Scalars['DateTime'];
  invoice?: Maybe<Invoice>;
  invoices?: Maybe<Array<Invoice>>;
  isCancellable?: Maybe<Scalars['Boolean']>;
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<User>;
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  lastModifiedUser?: Maybe<User>;
  linkRegistration?: Maybe<Registration>;
  name?: Maybe<Scalars['String']>;
  processedBy?: Maybe<User>;
  processedDateTime?: Maybe<Scalars['DateTime']>;
  remarks?: Maybe<Scalars['String']>;
  reminders?: Maybe<Array<Reminder>>;
  selectedLessons?: Maybe<Array<SelectedLesson>>;
  startDate?: Maybe<Scalars['DateTime']>;
  status: RegistrationsStatus;
  student: Student;
  type: RegistrationType;
  waStatus?: Maybe<ReminderStatus>;
};

export type RegistrationFilterInput = {
  academicLevelIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  classCode?: InputMaybe<Scalars['String']>;
  classIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  classTypes?: InputMaybe<Array<ClassType>>;
  courseIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  createdBy?: InputMaybe<Scalars['ObjectId']>;
  dateRange?: InputMaybe<DateTimeRangeInput>;
  lessonDateRange?: InputMaybe<DateTimeRangeInput>;
  name?: InputMaybe<Scalars['String']>;
  parentName?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Array<RegistrationsStatus>>;
  studentId?: InputMaybe<Scalars['ObjectId']>;
  teacherIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  types?: InputMaybe<Array<RegistrationType>>;
  venueIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  weekDays?: InputMaybe<Array<WeekDay>>;
};

export type RegistrationPage = {
  __typename?: 'RegistrationPage';
  items: Array<Registration>;
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export enum RegistrationType {
  Regular = 'Regular',
  Reservation = 'Reservation',
  Trial = 'Trial',
  WaitingList = 'WaitingList'
}

export enum RegistrationsStatus {
  Approved = 'Approved',
  Cancelled = 'Cancelled',
  Complete = 'Complete',
  Declined = 'Declined',
  Draft = 'Draft',
  Expired = 'Expired',
  ForApproval = 'ForApproval',
  Holding = 'Holding',
  Incomplete = 'Incomplete',
  Merge = 'Merge',
  Paid = 'Paid',
  PaymentOverdue = 'PaymentOverdue',
  Pending = 'Pending',
  PendingPayment = 'PendingPayment',
  Ready = 'Ready',
  TrialPending = 'TrialPending',
  Waiting = 'Waiting'
}

export type Relationship = {
  __typename?: 'Relationship';
  _id: Scalars['ObjectId'];
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<User>;
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  lastModifiedUser?: Maybe<User>;
  parentId1: Scalars['ObjectId'];
  parentId2: Scalars['ObjectId'];
};

export type Reminder = {
  __typename?: 'Reminder';
  _id: Scalars['ObjectId'];
  admin?: Maybe<Admin>;
  adminId?: Maybe<Scalars['ObjectId']>;
  arrangement?: Maybe<Arrangement>;
  arrangementId?: Maybe<Scalars['ObjectId']>;
  attachmentStatus?: Maybe<ReminderStatus>;
  attendance?: Maybe<Attendance>;
  attendanceId?: Maybe<Scalars['ObjectId']>;
  class?: Maybe<Class>;
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  destination?: Maybe<Scalars['String']>;
  file?: Maybe<ReminderFile>;
  invoiceIds?: Maybe<Array<Scalars['ObjectId']>>;
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<User>;
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  lastModifiedUser?: Maybe<User>;
  message?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['ObjectId']>;
  receiptId?: Maybe<Scalars['ObjectId']>;
  receiver?: Maybe<Parent>;
  refundId?: Maybe<Scalars['ObjectId']>;
  registration?: Maybe<Registration>;
  registrationId?: Maybe<Scalars['ObjectId']>;
  scheduledDate?: Maybe<Scalars['DateTime']>;
  sendDate?: Maybe<Scalars['DateTime']>;
  sendStatus?: Maybe<Scalars['String']>;
  sender?: Maybe<Scalars['String']>;
  sendgridEmailStatus?: Maybe<Scalars['String']>;
  sendgridMessageId?: Maybe<Scalars['String']>;
  status?: Maybe<ReminderStatus>;
  student?: Maybe<Student>;
  subject?: Maybe<Scalars['String']>;
  template?: Maybe<Template>;
  templateId?: Maybe<Scalars['ObjectId']>;
  type?: Maybe<ReminderType>;
  whatsappMessage?: Maybe<WhatsappMessage>;
  whatsappMessageId?: Maybe<Scalars['ObjectId']>;
};

export type ReminderFile = {
  __typename?: 'ReminderFile';
  content: Scalars['String'];
  disposition: Scalars['String'];
  fileName: Scalars['String'];
  type: Scalars['String'];
};

export type ReminderFilterInput = {
  dateRange?: InputMaybe<DateTimeRangeInput>;
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Array<ReminderStatus>>;
  templateTypes?: InputMaybe<Array<TemplateType>>;
  types?: InputMaybe<Array<ReminderType>>;
};

export type ReminderPage = {
  __typename?: 'ReminderPage';
  items: Array<Reminder>;
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export type ReminderPreview = {
  __typename?: 'ReminderPreview';
  emailMessage?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  whatsappMessage?: Maybe<Scalars['String']>;
};

export enum ReminderStatus {
  Cancelled = 'Cancelled',
  Delivered = 'Delivered',
  Failed = 'Failed',
  NotSent = 'NotSent',
  Pending = 'Pending',
  Processing = 'Processing',
  Read = 'Read',
  Sent = 'Sent'
}

export enum ReminderType {
  Email = 'Email',
  Whatsapp = 'Whatsapp'
}

export type ReorderLessonFlowItemsInput = {
  lessonFlowItemIds?: InputMaybe<Array<Scalars['ObjectId']>>;
};

export type ReplyFeedbackInput = {
  id: Scalars['ObjectId'];
  text: Scalars['String'];
};

export type Request = {
  processedAt?: Maybe<Scalars['DateTime']>;
  processedBy?: Maybe<User>;
};

export type ResetDefaultInput = {
  tableName?: InputMaybe<Scalars['String']>;
};

export type RestoreLessonFlowItemVersion = {
  lessonFlowId: Scalars['ObjectId'];
  lessonNumber: Scalars['Int'];
  version: Scalars['Int'];
};

export enum ResultOutcome {
  CanAttend = 'CanAttend',
  CanAttendWithConditional = 'CanAttendWithConditional',
  NotEligible = 'NotEligible'
}

export type Reward = {
  __typename?: 'Reward';
  _id: Scalars['ObjectId'];
  avatar: Scalars['String'];
  category: Scalars['String'];
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  galleries?: Maybe<Array<Scalars['String']>>;
  isArchived: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  isVisible: Scalars['Boolean'];
  lastModifiedBy?: Maybe<User>;
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  lastModifiedUser?: Maybe<User>;
  name: Scalars['String'];
  points: Scalars['Float'];
  stock: Scalars['Float'];
};

export type RewardDetail = {
  __typename?: 'RewardDetail';
  orderUrl?: Maybe<Scalars['String']>;
  quantity: Scalars['Int'];
  reward?: Maybe<Reward>;
  rewardId: Scalars['ObjectId'];
  trackingNumber?: Maybe<Scalars['String']>;
};

/** Reward redemption order information */
export type RewardDetailInput = {
  /** Count of reward items in order */
  quantity: Scalars['Int'];
  /** Reward item id associated with the order */
  rewardId: Scalars['ObjectId'];
  /** Optional order tracking number */
  trackingNumber?: InputMaybe<Scalars['String']>;
};

export type RewardFilterInput = {
  categories?: InputMaybe<Array<Scalars['String']>>;
  isVisible?: InputMaybe<Scalars['Boolean']>;
  points?: InputMaybe<Array<NumberRangeInput>>;
  showArchived?: InputMaybe<Scalars['Boolean']>;
  stock?: InputMaybe<Array<NumberRangeInput>>;
};

export type RewardHistory = {
  __typename?: 'RewardHistory';
  _id: Scalars['ObjectId'];
  adjustment: Scalars['Int'];
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** Optional gradable (Exercise or Homework) linked to reward transaction */
  gradableId: Scalars['ObjectId'];
  isArchived: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<User>;
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  lastModifiedUser?: Maybe<User>;
  orderDetail?: Maybe<RewardDetail>;
  orderHistory?: Maybe<Array<OrderHistory>>;
  remarks?: Maybe<Scalars['String']>;
  rewardPoints: Array<RewardPoints>;
  rewardPointsIds: Array<Scalars['ObjectId']>;
  status: RewardHistoryStatus;
  student: Student;
  studentId: Scalars['ObjectId'];
  type: RewardHistoryType;
};

export type RewardHistoryFilterInput = {
  /** Filter by course ID */
  courseId?: InputMaybe<Scalars['ObjectId']>;
  dateRange?: InputMaybe<DateRangeInput>;
  /** Filter Reward History by isArchived status */
  isArchived?: InputMaybe<Scalars['Boolean']>;
  /** Filter Reward History to toggle show archived or show all records. */
  showArchived?: InputMaybe<Scalars['Boolean']>;
  /** Filter Reward History by one or more status */
  statuses?: InputMaybe<Array<RewardHistoryStatus>>;
  /** Filter Reward History by one or more student IDs */
  studentIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  /** Filter Reward History by student name search */
  studentName?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<RewardHistoryType>;
};

export type RewardHistoryPage = {
  __typename?: 'RewardHistoryPage';
  items: Array<RewardHistory>;
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export enum RewardHistoryStatus {
  Cancelled = 'Cancelled',
  Collected = 'Collected',
  HandedToCourier = 'HandedToCourier',
  OrderPending = 'OrderPending',
  Preparing = 'Preparing',
  ReadyForCollection = 'ReadyForCollection'
}

export enum RewardHistoryType {
  ManualAdjustment = 'ManualAdjustment',
  PointRefund = 'PointRefund',
  Redemption = 'Redemption',
  Reward = 'Reward'
}

export type RewardInfo = {
  __typename?: 'RewardInfo';
  /** A List of reward mechanics containing criteria on minimum and maximum scores and how many points will be awarded to each criteria. */
  rewardMechanics?: Maybe<Array<RewardMechanic>>;
  /** This will determine wether a `Reward` is `Active` or `Inactive` which can be used for awarding points to a student based on the mechanics. It could be used to determine if the mechanics in this reward will be applied or not. */
  status?: Maybe<RewardStatus>;
};

/** A Property of Exercise and Homework that contains information about Rewards. */
export type RewardInfoInput = {
  /** A List of reward mechanics containing criteria on minimum and maximum scores and how many points will be awarded to each criteria. */
  rewardMechanics?: InputMaybe<Array<RewardMechanicInput>>;
  /** The status set from Rewards Management. */
  status?: InputMaybe<Scalars['String']>;
};

export type RewardMechanic = {
  __typename?: 'RewardMechanic';
  /** The points that will be awarded if the minimum and maximum conditions are met. */
  award?: Maybe<Scalars['Float']>;
  /** Number used as the current Band number for the reward mechanic. */
  band?: Maybe<Scalars['Float']>;
  /** This holds the maximum and minimum score that determines wether a score is eligible for award. */
  score?: Maybe<EligibleScore>;
};

/** This will contain the Score Eligibility and Award score for the Reward. */
export type RewardMechanicInput = {
  /** The points to award the student. */
  award: Scalars['Int'];
  /** Number used as the current Band count for the reward mechanic. */
  band: Scalars['Int'];
  /** Contains the minimum and maximum score for the mechanic to be active. */
  score: EligibleScoreInput;
};

export type RewardPage = {
  __typename?: 'RewardPage';
  items: Array<Reward>;
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export type RewardPoints = {
  __typename?: 'RewardPoints';
  _id: Scalars['ObjectId'];
  /** Reward Point attributed to a gradable */
  gradableId?: Maybe<Scalars['ObjectId']>;
  points: Scalars['Int'];
  status: RewardPointsStatus;
  used: Scalars['Int'];
};

/** Reward points input to add/deduct by student */
export type RewardPointsItemInput = {
  /** Amount of reward points */
  points: Scalars['Float'];
  /** Student ID on where reward points will be added */
  studentId: Scalars['ObjectId'];
};

export enum RewardPointsStatus {
  Active = 'Active',
  Expired = 'Expired'
}

export enum RewardStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type RewardsHistoryCounter = {
  __typename?: 'RewardsHistoryCounter';
  accumulated: Scalars['Int'];
  redeemed: Scalars['Int'];
};

export type SaveAsNewLessonFlowInput = {
  academicYearId: Scalars['ObjectId'];
  lessonFlowId: Scalars['ObjectId'];
  name: Scalars['String'];
};

export type SaveExerciseInput = {
  create?: InputMaybe<Array<CreateExerciseInput>>;
  delete?: InputMaybe<Array<IdInput>>;
  update?: InputMaybe<Array<UpdateExerciseInput>>;
};

export type SaveHomeworkInput = {
  create?: InputMaybe<Array<CreateHomeworkInput>>;
  delete?: InputMaybe<Array<IdInput>>;
  update?: InputMaybe<Array<UpdateHomeworkInput>>;
};

export type SaveSubmissionsInput = {
  classId: Scalars['ObjectId'];
  create?: InputMaybe<Array<CreateSubmissionInput>>;
  delete?: InputMaybe<Array<DeleteSubmissionInput>>;
  update?: InputMaybe<Array<UpdateSubmissionInput>>;
};

export type SaveTopicsInput = {
  create: Array<CreateTopicInput>;
  delete: Array<DeleteTopicInput>;
  update: Array<UpdateTopicInput>;
};

export type School = {
  __typename?: 'School';
  _id: Scalars['ObjectId'];
  alias?: Maybe<Scalars['String']>;
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  educationStage: EducationStage;
  isArchived: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  isVisible: Scalars['Boolean'];
  lastModifiedBy?: Maybe<User>;
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  lastModifiedUser?: Maybe<User>;
  name: Scalars['String'];
  systemId?: Maybe<Scalars['String']>;
};

export type ScoreRecord = {
  __typename?: 'ScoreRecord';
  score: Scalars['Float'];
  total: Scalars['Float'];
};

export type ScoreRecordInput = {
  score: Scalars['Float'];
  total: Scalars['Float'];
};

export type ScoreStats = {
  __typename?: 'ScoreStats';
  average?: Maybe<Scalars['Float']>;
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
};

export type Seat = {
  __typename?: 'Seat';
  end?: Maybe<Scalars['DateTime']>;
  seatInfo?: Maybe<Array<ClassSeat>>;
  start?: Maybe<Scalars['DateTime']>;
};

export type SeatAnalytics = {
  __typename?: 'SeatAnalytics';
  enrollmentRating: Scalars['Int'];
  totalAvailableSlots: Scalars['Int'];
  totalStudents: Scalars['Int'];
};

export enum SeatType {
  Bypass = 'Bypass',
  Makeup = 'Makeup',
  Regular = 'Regular'
}

export type SelectedLesson = {
  __typename?: 'SelectedLesson';
  asMakeUp?: Maybe<Scalars['Boolean']>;
  byPass?: Maybe<Scalars['Boolean']>;
  lesson: Lesson;
  makeUpLesson?: Maybe<Lesson>;
};

export type SelectedLessonInput = {
  asMakeUp?: InputMaybe<Scalars['Boolean']>;
  byPass?: InputMaybe<Scalars['Boolean']>;
  lessonId: Scalars['ObjectId'];
  makeUpLessonId?: InputMaybe<Scalars['ObjectId']>;
};

export type SelectedReason = {
  __typename?: 'SelectedReason';
  checked?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
};

export type SelectedReasonInput = {
  checked?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
};

export type SelectionData = {
  __typename?: 'SelectionData';
  academicWeek?: Maybe<AcademicWeek>;
  lessonNumber?: Maybe<Scalars['Int']>;
  lessonStart?: Maybe<Scalars['DateTime']>;
};

export type SendReminderInput = {
  arrangementId?: InputMaybe<Scalars['ObjectId']>;
  emailInput?: InputMaybe<EmailReminderInput>;
  invoiceId?: InputMaybe<Scalars['ObjectId']>;
  receiptId?: InputMaybe<Scalars['ObjectId']>;
  refundId?: InputMaybe<Scalars['ObjectId']>;
  registrationId?: InputMaybe<Scalars['ObjectId']>;
  types: Array<ReminderType>;
  whatsappInput?: InputMaybe<WhatsappReminderInput>;
};

export type SendTestReminderInput = {
  recipient?: InputMaybe<Scalars['String']>;
  senderId?: InputMaybe<Scalars['ObjectId']>;
  templateId: Scalars['ObjectId'];
  type: ReminderType;
  whatsappRecipient?: InputMaybe<TelephoneInput>;
};

export type SetLessonFlowItemExceptionsInput = {
  exceptedClassIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  lessonFlowItemId: Scalars['ObjectId'];
};

export type SimulationCapacityInput = {
  classId: Scalars['ObjectId'];
  endDate?: InputMaybe<Scalars['DateTime']>;
  quantity: Scalars['Int'];
  startDate?: InputMaybe<Scalars['DateTime']>;
  type: CapacitySimulationType;
};

export enum SortDirection {
  Asc = 'Asc',
  Desc = 'Desc'
}

export type SortInfo = {
  direction: SortDirection;
  field: Scalars['String'];
};

export type StatementOfAccount = {
  __typename?: 'StatementOfAccount';
  creditRemain?: Maybe<Scalars['Decimal']>;
  creditUsed?: Maybe<Scalars['Decimal']>;
  credits?: Maybe<Array<Credit>>;
  hasUnpaidInvoice?: Maybe<Scalars['Boolean']>;
  id: Scalars['ObjectId'];
  invoices?: Maybe<Array<Invoice>>;
  overdue?: Maybe<Scalars['Decimal']>;
  parent: Parent;
  receipts?: Maybe<Array<Receipt>>;
  refund?: Maybe<Scalars['Decimal']>;
  refunds?: Maybe<Array<Refund>>;
  totalPaid?: Maybe<Scalars['Decimal']>;
  unpaid?: Maybe<Scalars['Decimal']>;
};

export type StatementOfAccountFilter = {
  invoiceStatuses?: InputMaybe<Array<InvoiceStatus>>;
  search?: InputMaybe<Scalars['String']>;
};

export type StatementOfAccountNumber = {
  __typename?: 'StatementOfAccountNumber';
  balanceHistories?: Maybe<Scalars['Int']>;
  invoices?: Maybe<Scalars['Int']>;
  receipts?: Maybe<Scalars['Int']>;
  refunds?: Maybe<Scalars['Int']>;
};

export type StatementOfAccountPage = {
  __typename?: 'StatementOfAccountPage';
  items: Array<StatementOfAccount>;
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export type Student = {
  __typename?: 'Student';
  _id: Scalars['ObjectId'];
  academicLevel?: Maybe<AcademicLevel>;
  academicMatters?: Maybe<Array<Parent>>;
  alias?: Maybe<Scalars['String']>;
  alternateUserId?: Maybe<Scalars['String']>;
  alternativeEmails?: Maybe<Array<Scalars['String']>>;
  attendances?: Maybe<Array<Attendance>>;
  avatarUrl?: Maybe<Scalars['String']>;
  birthDate?: Maybe<Scalars['Date']>;
  consent?: Maybe<Consent>;
  courses?: Maybe<Array<Course>>;
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  eligibleArrangements?: Maybe<Array<ArrangementType>>;
  email?: Maybe<Scalars['String']>;
  enrollmentMatters?: Maybe<Array<Parent>>;
  enrollments?: Maybe<Array<Enrollment>>;
  examScores?: Maybe<Array<ExamScore>>;
  fullName: Scalars['String'];
  gender?: Maybe<Scalars['String']>;
  group?: Maybe<Scalars['String']>;
  homePhone?: Maybe<Telephone>;
  isActive: Scalars['Boolean'];
  isArchived: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<User>;
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  lastModifiedUser?: Maybe<User>;
  lessonArrangement?: Maybe<Scalars['String']>;
  makeUpCredits?: Maybe<Array<MakeUpCredit>>;
  mobile?: Maybe<Telephone>;
  parents?: Maybe<Array<Parent>>;
  primaryParent?: Maybe<Parent>;
  primarySchool?: Maybe<School>;
  primaryStream?: Maybe<Scalars['String']>;
  /** Field that resolves to registrations linked to student */
  registrations?: Maybe<Array<Registration>>;
  relationName?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  report?: Maybe<StudentReport>;
  rewardPoints?: Maybe<Array<RewardPoints>>;
  rewardPointsCounter?: Maybe<PointCounter>;
  secondarySchool?: Maybe<School>;
  secondaryStream?: Maybe<Scalars['String']>;
  siblings?: Maybe<Array<Student>>;
  term?: Maybe<Term>;
  userId?: Maybe<Scalars['String']>;
};


export type StudentAttendancesArgs = {
  filterInput?: InputMaybe<StudentAttendanceFilterInput>;
};


export type StudentEnrollmentsArgs = {
  filterInput?: InputMaybe<StudentEnrollmentFilterInput>;
  hasPaid?: InputMaybe<Scalars['Boolean']>;
};


export type StudentRelationNameArgs = {
  id?: InputMaybe<Scalars['ObjectId']>;
};


export type StudentReportArgs = {
  filter: StudentReportFilterInput;
};


export type StudentTermArgs = {
  year: Scalars['Int'];
};

export type StudentAttendanceFilterInput = {
  attendanceTypes?: InputMaybe<Array<AttendanceType>>;
  classIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  courseId?: InputMaybe<Scalars['ObjectId']>;
  datetimeRange?: InputMaybe<DateTimeRangeInput>;
  status?: InputMaybe<Array<AttendanceStatus>>;
};

/** Paginated response for List Students with Award Points detail */
export type StudentAwardDetailsPage = {
  __typename?: 'StudentAwardDetailsPage';
  items: Array<StudentAwardPointsDetail>;
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

/** Object extending StudentWithSubmissionGrade with a gradable score and reward points applicable for awarding */
export type StudentAwardPointsDetail = {
  __typename?: 'StudentAwardPointsDetail';
  _id: Scalars['ObjectId'];
  academicLevel?: Maybe<AcademicLevel>;
  academicMatters?: Maybe<Array<Parent>>;
  alias?: Maybe<Scalars['String']>;
  alternateUserId?: Maybe<Scalars['String']>;
  alternativeEmails?: Maybe<Array<Scalars['String']>>;
  /** Applicable points that can be applied to Student */
  applicablePoints: Scalars['Float'];
  attendances?: Maybe<Array<Attendance>>;
  avatarUrl?: Maybe<Scalars['String']>;
  /** Awarded points that is already applied to Student */
  awardedPoints: Scalars['Float'];
  birthDate?: Maybe<Scalars['Date']>;
  consent?: Maybe<Consent>;
  courses?: Maybe<Array<Course>>;
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  eligibleArrangements?: Maybe<Array<ArrangementType>>;
  email?: Maybe<Scalars['String']>;
  enrollmentMatters?: Maybe<Array<Parent>>;
  enrollments?: Maybe<Array<Enrollment>>;
  examScores?: Maybe<Array<ExamScore>>;
  fullName: Scalars['String'];
  gender?: Maybe<Scalars['String']>;
  group?: Maybe<Scalars['String']>;
  homePhone?: Maybe<Telephone>;
  isActive: Scalars['Boolean'];
  isArchived: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<User>;
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  lastModifiedUser?: Maybe<User>;
  lessonArrangement?: Maybe<Scalars['String']>;
  makeUpCredits?: Maybe<Array<MakeUpCredit>>;
  mobile?: Maybe<Telephone>;
  parents?: Maybe<Array<Parent>>;
  primaryParent?: Maybe<Parent>;
  primarySchool?: Maybe<School>;
  primaryStream?: Maybe<Scalars['String']>;
  /** Field that resolves to registrations linked to student */
  registrations?: Maybe<Array<Registration>>;
  relationName?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  report?: Maybe<StudentReport>;
  rewardPoints?: Maybe<Array<RewardPoints>>;
  rewardPointsCounter?: Maybe<PointCounter>;
  /** Score that is computed for awarding based on reward mechanics */
  score: Scalars['Float'];
  secondarySchool?: Maybe<School>;
  secondaryStream?: Maybe<Scalars['String']>;
  siblings?: Maybe<Array<Student>>;
  /** Submission record attributed to student */
  submission?: Maybe<Submission>;
  term?: Maybe<Term>;
  /** Overall score for gradable */
  totalScore: Scalars['Float'];
  userId?: Maybe<Scalars['String']>;
};


/** Object extending StudentWithSubmissionGrade with a gradable score and reward points applicable for awarding */
export type StudentAwardPointsDetailAttendancesArgs = {
  filterInput?: InputMaybe<StudentAttendanceFilterInput>;
};


/** Object extending StudentWithSubmissionGrade with a gradable score and reward points applicable for awarding */
export type StudentAwardPointsDetailEnrollmentsArgs = {
  filterInput?: InputMaybe<StudentEnrollmentFilterInput>;
  hasPaid?: InputMaybe<Scalars['Boolean']>;
};


/** Object extending StudentWithSubmissionGrade with a gradable score and reward points applicable for awarding */
export type StudentAwardPointsDetailRelationNameArgs = {
  id?: InputMaybe<Scalars['ObjectId']>;
};


/** Object extending StudentWithSubmissionGrade with a gradable score and reward points applicable for awarding */
export type StudentAwardPointsDetailReportArgs = {
  filter: StudentReportFilterInput;
};


/** Object extending StudentWithSubmissionGrade with a gradable score and reward points applicable for awarding */
export type StudentAwardPointsDetailTermArgs = {
  year: Scalars['Int'];
};

/** Object extending Student with a submission record */
export type StudentBulkAwardsErrorResult = {
  __typename?: 'StudentBulkAwardsErrorResult';
  /** Error message on award operation */
  errorMessage: Scalars['String'];
  /** Object ID of Student */
  studentId: Scalars['ObjectId'];
};

/** Object extending Student with a submission record */
export type StudentBulkAwardsResult = {
  __typename?: 'StudentBulkAwardsResult';
  /** Errors in bulk awards operation */
  errors: Array<StudentBulkAwardsErrorResult>;
  /** Student */
  students: Array<Student>;
};

export type StudentEnrollmentFilterInput = {
  academicWeek?: InputMaybe<AcademicWeekInput>;
  classIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  classStatus?: InputMaybe<Array<ClassStatus>>;
  courseType?: InputMaybe<Array<CourseType>>;
  enrollmentTypes?: InputMaybe<Array<EnrollmentType>>;
  isArrangementRequired?: InputMaybe<Scalars['Boolean']>;
  showCancelled?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Array<EnrollmentStatus>>;
};

export type StudentFilterInput = {
  academicLevelIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  academicYearIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  attendanceType?: InputMaybe<Array<AttendanceType>>;
  birthDateRange?: InputMaybe<DateTimeRangeInput>;
  /** Optional column names that will be returned in Student data */
  columns?: InputMaybe<Array<Scalars['String']>>;
  courseIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  /** Date range filter */
  dateRange?: InputMaybe<DateTimeRangeInput>;
  /** Optional gradableId filter on student's rewarded points */
  gradableId?: InputMaybe<Scalars['ObjectId']>;
  lessonDays?: InputMaybe<Array<WeekDay>>;
  mobile?: InputMaybe<TelephoneInput>;
  name?: InputMaybe<Scalars['String']>;
  parentId?: InputMaybe<Scalars['ObjectId']>;
  promotionId?: InputMaybe<Scalars['ObjectId']>;
  studentIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  teacherIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  venueIds?: InputMaybe<Array<Scalars['ObjectId']>>;
};

export type StudentPage = {
  __typename?: 'StudentPage';
  items: Array<Student>;
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export type StudentReport = {
  __typename?: 'StudentReport';
  current: Array<Enrollment>;
  future: Array<Enrollment>;
  past: Array<Enrollment>;
};

export type StudentReportFilter = {
  __typename?: 'StudentReportFilter';
  _id: Scalars['ObjectId'];
  academicLevelIds?: Maybe<Array<Scalars['ObjectId']>>;
  academicLevels?: Maybe<Array<AcademicLevel>>;
  academicYearIds?: Maybe<Array<Scalars['ObjectId']>>;
  academicYears?: Maybe<Array<AcademicYear>>;
  courseIds?: Maybe<Array<Scalars['ObjectId']>>;
  courses?: Maybe<Array<Course>>;
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  dateRange: DateTimeRange;
  dateRangeType: StudentReportFilterDateRangeType;
  deletedAt?: Maybe<Scalars['DateTime']>;
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<User>;
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  lastModifiedUser?: Maybe<User>;
  lessonDays?: Maybe<Array<WeekDay>>;
  name?: Maybe<Scalars['String']>;
  show?: Maybe<Array<StudentReportFilterShowGroup>>;
  teacherIds?: Maybe<Array<Scalars['ObjectId']>>;
  teachers?: Maybe<Array<Teacher>>;
  user?: Maybe<User>;
  userId: Scalars['ObjectId'];
  venueIds?: Maybe<Array<Scalars['ObjectId']>>;
  venues?: Maybe<Array<Venue>>;
};

export enum StudentReportFilterDateRangeType {
  Day = 'Day',
  Range = 'Range',
  Week = 'Week'
}

export type StudentReportFilterInput = {
  academicLevelIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  academicYearIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  courseIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  dateRange: DateTimeRangeInput;
  dateRangeType: StudentReportFilterDateRangeType;
  lessonDays?: InputMaybe<Array<WeekDay>>;
  name?: InputMaybe<Scalars['String']>;
  show?: InputMaybe<Array<StudentReportFilterShowGroup>>;
  teacherIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  venueIds?: InputMaybe<Array<Scalars['ObjectId']>>;
};

export enum StudentReportFilterShowGroup {
  Current = 'Current',
  Future = 'Future',
  Past = 'Past'
}

export type Submission = {
  __typename?: 'Submission';
  _id: Scalars['ObjectId'];
  breakdown?: Maybe<MarkBreakDown>;
  /** Lookup class object from classId property */
  class?: Maybe<Class>;
  classId: Scalars['ObjectId'];
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  enrollment?: Maybe<Enrollment>;
  enrollmentId: Scalars['ObjectId'];
  gradable?: Maybe<GradableUnion>;
  gradableId: Scalars['ObjectId'];
  grade?: Maybe<Scalars['Float']>;
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<User>;
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  lastModifiedUser?: Maybe<User>;
};

export type SubmissionAuditLogFilterInput = {
  dateTimeRange?: InputMaybe<DateRangeInput>;
  descriptions?: InputMaybe<Array<AuditLogDescriptionType>>;
  fieldNames?: InputMaybe<Array<Scalars['String']>>;
  operationTypes?: InputMaybe<Array<AuditLogOperationType>>;
  principalId?: InputMaybe<Scalars['ObjectId']>;
  principalType?: InputMaybe<Array<UserRole>>;
  resourceTypes?: InputMaybe<Array<AuditLogResourceType>>;
};

export type SubmissionDateRange = {
  end?: InputMaybe<Scalars['DateTime']>;
  start?: InputMaybe<Scalars['DateTime']>;
};

export type SubmissionTracking = {
  __typename?: 'SubmissionTracking';
  content?: Maybe<Array<Content>>;
  enrollment?: Maybe<Enrollment>;
};

export type SubmissionTrackingFilter = {
  academicLevels?: InputMaybe<Array<Scalars['ObjectId']>>;
  /** Will be used to filter class that has this academicYearId */
  academicYearId?: InputMaybe<Scalars['ObjectId']>;
  activities?: InputMaybe<Activities>;
  /** Search by gradable's worksheet topic nand and worksheet title */
  activityName?: InputMaybe<Scalars['String']>;
  dateRange?: InputMaybe<SubmissionDateRange>;
  enrollmentType?: InputMaybe<Array<EnrollmentType>>;
  isGraded?: InputMaybe<Scalars['Boolean']>;
  lessonDays?: InputMaybe<Array<WeekDay>>;
  workSheetId?: InputMaybe<Scalars['ObjectId']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  healthServiceChanged: Scalars['Boolean'];
  postIncompleteArrangement: Arrangement;
  postIncompleteRegistration: Registration;
  postLessonFlowUpdate: LessonFlowUpdate;
  systemNotification: SystemNotification;
  whatsappAccountLoginFailed: WhatsappAccount;
  whatsappAccountStatusChanged: WhatsappAccount;
  whatsappMessageSendFailed: WhatsappMessage;
};


export type SubscriptionPostIncompleteArrangementArgs = {
  type: ArrangementType;
};


export type SubscriptionPostLessonFlowUpdateArgs = {
  lessonFlowId: Scalars['ObjectId'];
};

export type Suitability = {
  __typename?: 'Suitability';
  description?: Maybe<Scalars['String']>;
  isForTrial?: Maybe<Scalars['Boolean']>;
};

export type SuitabilityInput = {
  description?: InputMaybe<Scalars['String']>;
  isForTrial?: InputMaybe<Scalars['Boolean']>;
};

export type SystemNotification = {
  __typename?: 'SystemNotification';
  _id?: Maybe<Scalars['ObjectId']>;
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<User>;
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  lastModifiedUser?: Maybe<User>;
  message: Scalars['String'];
  read: Scalars['Boolean'];
  relatedUser?: Maybe<User>;
  relatedUserId: Scalars['ObjectId'];
  subType?: Maybe<SystemNotificationSubType>;
  taskIds?: Maybe<Array<Scalars['ObjectId']>>;
  tasks?: Maybe<Array<Task>>;
  title?: Maybe<Scalars['String']>;
  type: SystemNotificationType;
  whatsappMessage?: Maybe<WhatsappMessage>;
  whatsappMessageId?: Maybe<Scalars['ObjectId']>;
};

export type SystemNotificationFilterInput = {
  relatedUserId?: InputMaybe<Scalars['ObjectId']>;
  type?: InputMaybe<SystemNotificationType>;
};

export type SystemNotificationPage = {
  __typename?: 'SystemNotificationPage';
  items: Array<SystemNotification>;
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export enum SystemNotificationSubType {
  AsyncRequestFailed = 'AsyncRequestFailed',
  WhatsappAbnormalOffline = 'WhatsappAbnormalOffline',
  WhatsappLoginFailed = 'WhatsappLoginFailed',
  WhatsappLogoutFailed = 'WhatsappLogoutFailed',
  WhatsappMessageSendFailed = 'WhatsappMessageSendFailed',
  WhatsappOffline = 'WhatsappOffline',
  WhatsappOnline = 'WhatsappOnline'
}

export enum SystemNotificationType {
  CommunicationTask = 'CommunicationTask',
  ProcessAutomation = 'ProcessAutomation'
}

export type Task = {
  __typename?: 'Task';
  _id: Scalars['ObjectId'];
  assignedClassIds?: Maybe<Array<Scalars['ObjectId']>>;
  assignedClasses?: Maybe<Array<Class>>;
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  deadline: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  isArchived: Scalars['Boolean'];
  isCompleted?: Maybe<Scalars['Boolean']>;
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<User>;
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  lastModifiedUser?: Maybe<User>;
  mentioned?: Maybe<Array<User>>;
  name: Scalars['String'];
};

export type TaskFilterInput = {
  assignedClassIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  deadline?: InputMaybe<Scalars['DateTime']>;
  ids?: InputMaybe<Array<Scalars['ObjectId']>>;
  isCompleted?: InputMaybe<Scalars['Boolean']>;
  mentionedIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  name?: InputMaybe<Scalars['String']>;
};

export type TaskPage = {
  __typename?: 'TaskPage';
  items: Array<Task>;
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export type Teacher = {
  __typename?: 'Teacher';
  _id: Scalars['ObjectId'];
  alias?: Maybe<Scalars['String']>;
  alternateUserId?: Maybe<Scalars['String']>;
  avatarUrl?: Maybe<Scalars['String']>;
  branches?: Maybe<Array<Branch>>;
  colorCode: Scalars['String'];
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  department?: Maybe<Department>;
  designation?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  fullName: Scalars['String'];
  gender?: Maybe<Scalars['String']>;
  initials?: Maybe<Scalars['String']>;
  isArchived: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<User>;
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  lastModifiedUser?: Maybe<User>;
  lessons?: Maybe<Array<Lesson>>;
  linkUser?: Maybe<User>;
  mobile: Telephone;
  officePhone?: Maybe<Telephone>;
  salutation: Scalars['String'];
  userId?: Maybe<Scalars['String']>;
};

export type TeacherAndVenueValidateResult = {
  __typename?: 'TeacherAndVenueValidateResult';
  teacher: Scalars['String'];
  venue: Scalars['String'];
};

export type TeacherFilterInput = {
  gender?: InputMaybe<Scalars['String']>;
  mobile?: InputMaybe<TelephoneInput>;
  name?: InputMaybe<Scalars['String']>;
};

export type TeacherPage = {
  __typename?: 'TeacherPage';
  items: Array<Teacher>;
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export type Telephone = {
  __typename?: 'Telephone';
  countryCode: Scalars['String'];
  number: Scalars['String'];
};

export type TelephoneInput = {
  countryCode: Scalars['String'];
  number: Scalars['String'];
};

export type TelephoneOutput = {
  __typename?: 'TelephoneOutput';
  countryCode: Scalars['String'];
  number: Scalars['String'];
};

export type Template = {
  __typename?: 'Template';
  _id: Scalars['ObjectId'];
  arrangementType?: Maybe<TemplateArrangementType>;
  content?: Maybe<Scalars['String']>;
  courseType?: Maybe<Scalars['String']>;
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  emailMessage?: Maybe<Scalars['String']>;
  isArchived: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<User>;
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  lastModifiedUser?: Maybe<User>;
  sendFrequency?: Maybe<Scalars['Float']>;
  status: TemplateStatus;
  subject?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type: TemplateType;
  whatsAppMessage?: Maybe<Scalars['String']>;
};

export enum TemplateArrangementType {
  Additionals = 'Additionals',
  Makeups = 'Makeups',
  Regularregistration = 'Regularregistration',
  Reservations = 'Reservations',
  Transfers = 'Transfers',
  Trialregistration = 'Trialregistration',
  Waitinglist = 'Waitinglist',
  Withdrawals = 'Withdrawals'
}

export type TemplateFilterInput = {
  arrangementTypes?: InputMaybe<Array<TemplateArrangementType>>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<TemplateStatus>;
  type?: InputMaybe<TemplateType>;
};

export type TemplatePage = {
  __typename?: 'TemplatePage';
  items: Array<Template>;
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export enum TemplateStatus {
  Complete = 'Complete',
  Draft = 'Draft'
}

export enum TemplateType {
  Confirmation = 'Confirmation',
  CopyList = 'CopyList',
  Disclaimer = 'Disclaimer',
  LessonReminder = 'LessonReminder',
  PaymentAdvice = 'PaymentAdvice',
  PaymentReminder = 'PaymentReminder',
  ReceiptReminder = 'ReceiptReminder',
  Refund = 'Refund',
  TermsConditions = 'TermsConditions'
}

export type Term = {
  __typename?: 'Term';
  _id: Scalars['ObjectId'];
  academicLevels?: Maybe<Array<AcademicLevel>>;
  academicYear: AcademicYear;
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<User>;
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  lastModifiedUser?: Maybe<User>;
  term1Period: DateRange;
  term2Period: DateRange;
  term3Period: DateRange;
  term4Period: DateRange;
  year: Scalars['Int'];
};

export type TimeRangeInput = {
  end: Scalars['Time'];
  start: Scalars['Time'];
};

export type TimeRangeOutput = {
  __typename?: 'TimeRangeOutput';
  end: Scalars['Time'];
  start: Scalars['Time'];
};

export type TokenSet = {
  __typename?: 'TokenSet';
  access_token?: Maybe<Scalars['String']>;
  expires_at?: Maybe<Scalars['Float']>;
  expires_in?: Maybe<Scalars['Float']>;
  id_token?: Maybe<Scalars['String']>;
  refresh_token?: Maybe<Scalars['String']>;
  scope?: Maybe<Scalars['String']>;
  session_state?: Maybe<Scalars['String']>;
  token_type?: Maybe<Scalars['String']>;
};

export type Topic = {
  __typename?: 'Topic';
  _id: Scalars['ObjectId'];
  courseId: Scalars['ObjectId'];
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<User>;
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  lastModifiedUser?: Maybe<User>;
  name: Scalars['String'];
  /** The worksheets assigned under the current topic. */
  worksheets?: Maybe<Array<WorkSheet>>;
};

export type TransferCancelInfo = {
  __typename?: 'TransferCancelInfo';
  firstFullLesson?: Maybe<Lesson>;
  isFullFromClass: Scalars['Boolean'];
  isFullFromLesson: Scalars['Boolean'];
};

export enum TrialFollowUpStatus {
  Enrolled = 'Enrolled',
  FollowUpSent = 'FollowUpSent',
  Pending = 'Pending',
  SeatReleased = 'SeatReleased'
}

export type UpcomingLessonInput = {
  classId?: InputMaybe<Scalars['ObjectId']>;
  date?: InputMaybe<Scalars['DateTime']>;
  teacherId?: InputMaybe<Scalars['ObjectId']>;
};

export type UpdateAcademicLevelInput = {
  alias?: InputMaybe<Scalars['String']>;
  educationStage?: InputMaybe<EducationStage>;
  id: Scalars['ObjectId'];
  isArchived?: InputMaybe<Scalars['Boolean']>;
  isVisible?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateAcademicYearInput = {
  academicWeekEnd?: InputMaybe<WeekDay>;
  academicWeekStart?: InputMaybe<WeekDay>;
  id: Scalars['ObjectId'];
  period?: InputMaybe<DateRangeInput>;
};

export type UpdateAdminInput = {
  alias?: InputMaybe<Scalars['String']>;
  avatarUrl?: InputMaybe<Scalars['String']>;
  branchIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  departmentId?: InputMaybe<Scalars['ObjectId']>;
  designation?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  fullName?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  id: Scalars['ObjectId'];
  initials?: InputMaybe<Scalars['String']>;
  mobile?: InputMaybe<TelephoneInput>;
  officePhone?: InputMaybe<TelephoneInput>;
  salutation?: InputMaybe<Scalars['String']>;
};

export type UpdateAttendanceCollectionInput = {
  attendanceId: Scalars['ObjectId'];
  isCollection: Scalars['Boolean'];
};

export type UpdateAttendanceLessonModeInput = {
  attendanceId: Scalars['ObjectId'];
  lessonMode?: InputMaybe<LearningArrangementType>;
};

export type UpdateBranchInput = {
  address?: InputMaybe<AddressInput>;
  alias?: InputMaybe<Scalars['String']>;
  id: Scalars['ObjectId'];
  isArchived?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateClassInput = {
  academicWeekNumber?: InputMaybe<Scalars['Int']>;
  academicYearId?: InputMaybe<Scalars['ObjectId']>;
  classCode?: InputMaybe<Scalars['String']>;
  defaultLessonDay?: InputMaybe<WeekDay>;
  defaultLessonEnd?: InputMaybe<Scalars['DateTime']>;
  defaultLessonStart?: InputMaybe<Scalars['DateTime']>;
  digitalClassAccessCredentials?: InputMaybe<DigitalClassAccessCredentialsInput>;
  id: Scalars['ObjectId'];
  isAdHoc?: InputMaybe<Scalars['Boolean']>;
  learningArrangement?: InputMaybe<LearningArrangementType>;
  /** ID of the Lesson Flow to be assigned to the class. */
  lessonFlowId?: InputMaybe<Scalars['ObjectId']>;
  lessonPlan?: InputMaybe<Scalars['String']>;
  maximumClassCapacity?: InputMaybe<Scalars['Int']>;
  maximumMakeUpCapacity?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  runNumber?: InputMaybe<Scalars['Int']>;
  shorthand?: InputMaybe<Scalars['String']>;
  teacherId?: InputMaybe<Scalars['ObjectId']>;
  venueId?: InputMaybe<Scalars['ObjectId']>;
};

/** Input used to Update collect details of a LessonFlowItem. */
export type UpdateCollectInput = {
  /** The ID of the collect to update. */
  collectId: Scalars['ObjectId'];
  /** The text of the collect generated from a rich text editor. */
  description: Scalars['String'];
  /** The ID of the LessonFlowItem containing the collect to find. */
  lessonFlowItemId: Scalars['ObjectId'];
};

export type UpdateCourseInput = {
  academicLevelIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  academicStreams?: InputMaybe<Array<Scalars['String']>>;
  avatarUrl?: InputMaybe<Scalars['String']>;
  courseFee?: InputMaybe<CourseFeeInput>;
  description?: InputMaybe<Scalars['String']>;
  disclaimer?: InputMaybe<Array<Scalars['String']>>;
  duration?: InputMaybe<Scalars['Float']>;
  id: Scalars['ObjectId'];
  isSkipScheduledHoliday?: InputMaybe<Scalars['Boolean']>;
  learningResourceUrl?: InputMaybe<Scalars['String']>;
  /** ID of the Lesson Flow to be assigned to the class. */
  lessonFlowId?: InputMaybe<Scalars['ObjectId']>;
  makeUpCredits?: InputMaybe<MakeUpCreditInput>;
  name?: InputMaybe<Scalars['String']>;
  nameShort?: InputMaybe<Scalars['String']>;
  numberOfLessons?: InputMaybe<Scalars['Int']>;
  termsAndConditions?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<CourseType>;
};

export type UpdateDepartmentInput = {
  centreId?: InputMaybe<Scalars['ObjectId']>;
  designations?: InputMaybe<Array<Scalars['String']>>;
  id: Scalars['ObjectId'];
  isArchived?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateDiscountInput = {
  discountAmount?: InputMaybe<Scalars['Decimal']>;
  discountName?: InputMaybe<Scalars['String']>;
  discountRate?: InputMaybe<Scalars['Decimal']>;
  discountType?: InputMaybe<Scalars['String']>;
  id: Scalars['ObjectId'];
  isVisible?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateEligibilityFlowInput = {
  id: Scalars['ObjectId'];
  startNode: FlowNodeInput;
};

export type UpdateEnrollmentGroupInput = {
  group?: InputMaybe<EnrollmentGroupType>;
  id: Scalars['ObjectId'];
};

export type UpdateEnrollmentPreferredLearningArrangementInput = {
  id: Scalars['ObjectId'];
  preferredLearningArrangement: LearningArrangementType;
};

/** Input in updating an enrollment record's suitability information */
export type UpdateEnrollmentSuitabilityInput = {
  /** Input in updating an enrollment record's suitability information */
  enrollmentId: Scalars['ObjectId'];
  /** Remarks on the suitability of the student's enrollment */
  remarks?: InputMaybe<Scalars['String']>;
  /** Input in updating an enrollment record's suitability information */
  status?: InputMaybe<EnrollmentSuitabilityStatus>;
};

export type UpdateEnrollmentTrialFollowUpStatusInput = {
  id: Scalars['ObjectId'];
  trialFollowUpStatus: TrialFollowUpStatus;
};

export type UpdateExamScoreInput = {
  academicLevelId: Scalars['ObjectId'];
  examType?: InputMaybe<Scalars['String']>;
  id: Scalars['ObjectId'];
  marksPercentage?: InputMaybe<Scalars['Float']>;
  mcq?: InputMaybe<ScoreRecordInput>;
  practical?: InputMaybe<ScoreRecordInput>;
  qeq?: InputMaybe<ScoreRecordInput>;
  scoreGrade?: InputMaybe<Scalars['String']>;
  stream?: InputMaybe<Scalars['String']>;
  studentId: Scalars['ObjectId'];
  subject?: InputMaybe<Scalars['String']>;
  totalMarks?: InputMaybe<ScoreRecordInput>;
  year: Scalars['Int'];
};

export type UpdateExerciseInput = {
  /** An HTML string that is taken from Braft-Editor. */
  content?: InputMaybe<Scalars['String']>;
  /** The duration of the `exercise` in minutes. */
  duration?: InputMaybe<Scalars['Int']>;
  id: Scalars['ObjectId'];
  /** The index of a `lesson`. This is used to link similar lessons for applied `lessonFlow`s to the `lessonflowItem`s. */
  lessonNumber?: InputMaybe<Scalars['Int']>;
  /** This determines wether the `exercise` requires to be submitted. */
  needSubmit?: InputMaybe<Scalars['Boolean']>;
  /** The a number to set what position in a list the `exercise` is located. */
  order?: InputMaybe<Scalars['Int']>;
  /** Contains the `status` and `mechanics` of an `Activity` which in this case is an `Exercise`. */
  reward?: InputMaybe<RewardInfoInput>;
  /** This is the breakdown of the achievable scores for the `exercise`. The sum of the breakdown must be equal to the `totalGrade` */
  totalBreakdown?: InputMaybe<MarkBreakDownInput>;
  /** This is the total grade that can be achieved in the `exercise`. If `totalBreakdown` exists, it must be equal to the sum of the breakdown. */
  totalGrade?: InputMaybe<Scalars['Int']>;
  /** The ID of the `worksheet` to referrence for the `exercise`. */
  workSheetId?: InputMaybe<Scalars['ObjectId']>;
};

export type UpdateFeatureAccessInput = {
  enabled?: InputMaybe<Scalars['Boolean']>;
  extendParams?: InputMaybe<FeatureAccessExtendInput>;
  keywords: Scalars['String'];
};

export type UpdateFeatureAccessesInput = {
  enabled?: InputMaybe<Scalars['Boolean']>;
  extendParams?: InputMaybe<FeatureAccessExtendInput>;
  keywords?: InputMaybe<Array<Scalars['String']>>;
};

export type UpdateFeedbackInput = {
  id: Scalars['ObjectId'];
  isResolved?: InputMaybe<Scalars['Boolean']>;
  quoted?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
};

export type UpdateFinanceInput = {
  id: Scalars['ObjectId'];
  isVisible?: InputMaybe<Scalars['Boolean']>;
  paymentMethod?: InputMaybe<Scalars['String']>;
};

export type UpdateGroupAssignmentInput = {
  id: Scalars['ObjectId'];
  items?: InputMaybe<Array<GroupAssignmentItemInput>>;
};

/** Arguments to be used when updating a handout. */
export type UpdateHandoutInput = {
  /** The URL of the file, if the handout is a file. */
  file?: InputMaybe<HandoutFileInput>;
  /** The ID of the handout to update. */
  id: Scalars['ObjectId'];
  /** The instructions for the handout. This is formatted in Markdown */
  instructions?: InputMaybe<Scalars['String']>;
};

export type UpdateHolidayInput = {
  id: Scalars['ObjectId'];
  name?: InputMaybe<Scalars['String']>;
  period?: InputMaybe<DateRangeInput>;
  type?: InputMaybe<Scalars['String']>;
};

export type UpdateHomeworkInput = {
  /** An HTML string that is taken from Braft-Editor. */
  content?: InputMaybe<Scalars['String']>;
  /** The duration of the `homework` in minutes. */
  duration?: InputMaybe<Scalars['Int']>;
  id: Scalars['ObjectId'];
  /** The index of a `lesson`. This is used to link similar lessons for applied `lessonFlow`s to the `lessonflowItem`s. */
  lessonNumber?: InputMaybe<Scalars['Int']>;
  /** This determines wether the `exercise` requires to be submitted. */
  needSubmit?: InputMaybe<Scalars['Boolean']>;
  /** The a number to set what position in a list the `exercise` is located. */
  order?: InputMaybe<Scalars['Int']>;
  /** Contains the `status` and `mechanics` of an `Ac tivity` which in this case is a `Homework`. */
  reward?: InputMaybe<RewardInfoInput>;
  /** This is the breakdown of the achievable scores for the `exercise`. The sum of the breakdown must be equal to the `totalGrade` */
  totalBreakdown?: InputMaybe<MarkBreakDownInput>;
  /** This is the total grade that can be achieved in the `exercise`. If `totalBreakdown` exists, it must be equal to the sum of the breakdown. */
  totalGrade?: InputMaybe<Scalars['Int']>;
  /** The ID of the `worksheet` to referrence for the `exercise`. */
  workSheetId?: InputMaybe<Scalars['ObjectId']>;
};

export type UpdateInvoiceInput = {
  dueDate?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['ObjectId'];
};

export type UpdateInvoiceItemInput = {
  description: Scalars['String'];
  lineItemId: Scalars['ObjectId'];
  quantity: Scalars['Int'];
  unitAmount: Scalars['Decimal'];
};

export type UpdateLessonAttendanceInput = {
  lessonId: Scalars['ObjectId'];
  status: AttendanceStatus;
  studentIds: Array<Scalars['ObjectId']>;
};

/** An input used to provide values for updating a lesson flow. Currently being used for setting Lesson Flow Items. */
export type UpdateLessonFlowInput = {
  /** Current Lesson Flow ID to update */
  lessonFlowId: Scalars['ObjectId'];
  /** An array of Lesson Flow Item Ids */
  lessonFlowItemIds?: InputMaybe<Array<Scalars['ObjectId']>>;
};

export type UpdateLessonFlowItemInput = {
  /** The academic week object to assign to the lessonFlowItem. */
  academicWeek?: InputMaybe<LessonFlowAcademicWeekInput>;
  exceptedClassIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  exerciseInputs?: InputMaybe<SaveExerciseInput>;
  homeworkInputs?: InputMaybe<SaveHomeworkInput>;
  lessonFlowItemId: Scalars['ObjectId'];
  name?: InputMaybe<Scalars['String']>;
  suitability?: InputMaybe<SuitabilityInput>;
};

export type UpdateLessonInput = {
  classId?: InputMaybe<Scalars['ObjectId']>;
  id: Scalars['ObjectId'];
  lessonEnd: Scalars['DateTime'];
  lessonNotes?: InputMaybe<Scalars['String']>;
  lessonStart: Scalars['DateTime'];
  teacherId?: InputMaybe<Scalars['ObjectId']>;
  updateForSubsequentLessons?: InputMaybe<Scalars['Boolean']>;
  venueId?: InputMaybe<Scalars['ObjectId']>;
};

export type UpdateLogInput = {
  id: Scalars['ObjectId'];
  log: Scalars['String'];
};

export type UpdateMailMergeInput = {
  id: Scalars['ObjectId'];
  templateId: Scalars['ObjectId'];
};

export type UpdateMakeUpRemarksAndUseCreditInput = {
  id: Scalars['ObjectId'];
  remarks?: InputMaybe<Scalars['String']>;
  useCredits?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateMerchandiseInput = {
  dateIssued?: InputMaybe<Scalars['DateTime']>;
  distributionChannel?: InputMaybe<Scalars['String']>;
  id: Scalars['ObjectId'];
  item?: InputMaybe<Scalars['String']>;
  remarks?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<MerchandiseStatus>;
};

export type UpdateParentInput = {
  address?: InputMaybe<UserAddressInput>;
  alias?: InputMaybe<Scalars['String']>;
  alternateUserId?: InputMaybe<Scalars['String']>;
  alternativeEmails?: InputMaybe<Array<Scalars['String']>>;
  avatarUrl?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  fullName?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  homePhone?: InputMaybe<TelephoneInput>;
  id: Scalars['ObjectId'];
  linkedWhatsappAccountId?: InputMaybe<Scalars['ObjectId']>;
  mobile?: InputMaybe<TelephoneInput>;
  officePhone?: InputMaybe<TelephoneInput>;
  remarks?: InputMaybe<Scalars['String']>;
  salutation?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
  whatsAppName?: InputMaybe<Scalars['String']>;
};

export type UpdateParentProfileInput = {
  avatarUrl?: InputMaybe<Scalars['String']>;
  fullName?: InputMaybe<Scalars['String']>;
  id: Scalars['ObjectId'];
  mobile?: InputMaybe<TelephoneInput>;
  salutation?: InputMaybe<Scalars['String']>;
};

export type UpdateParentRemarksInput = {
  id: Scalars['ObjectId'];
  remarks: Scalars['String'];
};

export type UpdatePaymentRecordInput = {
  bankName?: InputMaybe<Scalars['String']>;
  chequeNumber?: InputMaybe<Scalars['String']>;
  depositDate?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['ObjectId'];
  lastDigits?: InputMaybe<Scalars['String']>;
  /** Updated Payment method to overwrite in record */
  method?: InputMaybe<Scalars['String']>;
  /** The date when the payment was made */
  paymentDate?: InputMaybe<Scalars['DateTime']>;
  paymentProcessor?: InputMaybe<Scalars['String']>;
  reference?: InputMaybe<Scalars['String']>;
  remarks?: InputMaybe<Scalars['String']>;
};

export type UpdatePromotionInput = {
  academicLevelIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  id: Scalars['ObjectId'];
  promoteToLevelId: Scalars['ObjectId'];
  scheduledDate?: InputMaybe<Scalars['DateTime']>;
  selectedStudentIds?: InputMaybe<Array<Scalars['ObjectId']>>;
};

export type UpdateReferenceDataInput = {
  id: Scalars['ObjectId'];
  isArchived?: InputMaybe<Scalars['Boolean']>;
  order?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['String']>;
};

export type UpdateRegistrationInput = {
  classIds?: InputMaybe<Scalars['ObjectId']>;
  currentStep?: InputMaybe<Scalars['Int']>;
  dueDate?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['ObjectId'];
  invoiceId?: InputMaybe<Scalars['ObjectId']>;
  linkRegistrationId?: InputMaybe<Scalars['ObjectId']>;
  preferredLearningArrangement?: InputMaybe<LearningArrangementType>;
  remarks?: InputMaybe<Scalars['String']>;
  selectedLessons?: InputMaybe<Array<SelectedLessonInput>>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  type?: InputMaybe<RegistrationType>;
};

export type UpdateRelationshipInput = {
  destId: Scalars['ObjectId'];
  relationName?: InputMaybe<Scalars['String']>;
  sourceId: Scalars['ObjectId'];
};

export type UpdateRewardCatalogInput = {
  avatar?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  galleries?: InputMaybe<Array<Scalars['String']>>;
  id: Scalars['ObjectId'];
  isVisible?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  points?: InputMaybe<Scalars['Int']>;
  stock?: InputMaybe<Scalars['Int']>;
};

export type UpdateRewardsHistoryInput = {
  id: Scalars['ObjectId'];
  orderUrl?: InputMaybe<Scalars['String']>;
  remarks?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<RewardHistoryStatus>;
  trackingNumber?: InputMaybe<Scalars['String']>;
};

export type UpdateSchoolInput = {
  alias?: InputMaybe<Scalars['String']>;
  educationStage?: InputMaybe<EducationStage>;
  id: Scalars['ObjectId'];
  isArchived?: InputMaybe<Scalars['Boolean']>;
  isVisible?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateStudentInput = {
  academicLevelId?: InputMaybe<Scalars['ObjectId']>;
  academicMatterIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  alias?: InputMaybe<Scalars['String']>;
  alternateUserId?: InputMaybe<Scalars['String']>;
  alternativeEmails?: InputMaybe<Array<Scalars['String']>>;
  avatarUrl?: InputMaybe<Scalars['String']>;
  birthDate?: InputMaybe<Scalars['DateTime']>;
  consent?: InputMaybe<ConsentInput>;
  email?: InputMaybe<Scalars['String']>;
  enrollmentMatterIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  fullName?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  homePhone?: InputMaybe<TelephoneInput>;
  id: Scalars['ObjectId'];
  mobile?: InputMaybe<TelephoneInput>;
  parentIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  primaryParentId?: InputMaybe<Scalars['ObjectId']>;
  primarySchoolId?: InputMaybe<Scalars['ObjectId']>;
  primaryStream?: InputMaybe<Scalars['String']>;
  remarks?: InputMaybe<Scalars['String']>;
  secondarySchoolId?: InputMaybe<Scalars['ObjectId']>;
  secondaryStream?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type UpdateStudentRemarksInput = {
  id: Scalars['ObjectId'];
  remarks: Scalars['String'];
};

export type UpdateStudentReportFilterInput = {
  id: Scalars['ObjectId'];
  name: Scalars['String'];
};

export type UpdateSubmissionInput = {
  breakdown?: InputMaybe<MarkBreakDownInput>;
  /** Flag to set if update operation involves removing/ nulling a breakdown value. */
  deleteFlag?: InputMaybe<Scalars['Boolean']>;
  grade?: InputMaybe<Scalars['Int']>;
  submissionId: Scalars['ObjectId'];
};

export type UpdateTaskInput = {
  assignedClassIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  deadline?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['ObjectId'];
  isCompleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateTeacherInput = {
  alias?: InputMaybe<Scalars['String']>;
  avatarUrl?: InputMaybe<Scalars['String']>;
  branchIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  colorCode?: InputMaybe<Scalars['String']>;
  departmentId?: InputMaybe<Scalars['ObjectId']>;
  designation?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  fullName?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  id: Scalars['ObjectId'];
  initials?: InputMaybe<Scalars['String']>;
  mobile?: InputMaybe<TelephoneInput>;
  officePhone?: InputMaybe<TelephoneInput>;
  salutation?: InputMaybe<Scalars['String']>;
};

export type UpdateTemplateInput = {
  arrangementType?: InputMaybe<TemplateArrangementType>;
  content?: InputMaybe<Scalars['String']>;
  courseType?: InputMaybe<CourseType>;
  emailMessage?: InputMaybe<Scalars['String']>;
  id: Scalars['ObjectId'];
  status: TemplateStatus;
  subject?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<TemplateType>;
  whatsAppMessage?: InputMaybe<Scalars['String']>;
};

export type UpdateTermInput = {
  academicLevelIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  id: Scalars['ObjectId'];
  term1Period?: InputMaybe<DateRangeInput>;
  term2Period?: InputMaybe<DateRangeInput>;
  term3Period?: InputMaybe<DateRangeInput>;
  term4Period?: InputMaybe<DateRangeInput>;
};

export type UpdateTopicInput = {
  courseId: Scalars['ObjectId'];
  name: Scalars['String'];
  topicId: Scalars['ObjectId'];
};

export type UpdateUserCustomiseColumnInput = {
  columnName: Scalars['String'];
  columnShowName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ObjectId']>;
  isRequired: Scalars['Boolean'];
  isVisible: Scalars['Boolean'];
  ownerId?: InputMaybe<Scalars['ObjectId']>;
  tableName: Scalars['String'];
  tableShowName?: InputMaybe<Scalars['String']>;
  weight: Scalars['Int'];
};

export type UpdateUserPermissionInput = {
  id: Scalars['ObjectId'];
  userRole: UserRole;
};

export type UpdateVenueInput = {
  /** Address of the Venue */
  address: AddressInput;
  /** Id of the branch where the venue is located */
  branchId?: InputMaybe<Scalars['ObjectId']>;
  /** The maximum number of students that a venue can hold */
  capacity?: InputMaybe<Scalars['Int']>;
  /** Id of the venue */
  id: Scalars['ObjectId'];
  /** Indicator that the venue is archived, archiving the venue means it cannot be associated to a class */
  isArchived?: InputMaybe<Scalars['Boolean']>;
  /** Name of the Venue, this is the combination of the venue unit and classroom number e.g 02-5A CR1 */
  name: Scalars['String'];
};

export type UpdateWhatsappAccountInput = {
  id: Scalars['ObjectId'];
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateWorksheetInput = {
  abbreviation?: InputMaybe<Scalars['String']>;
  academicYearId?: InputMaybe<Scalars['ObjectId']>;
  courseId?: InputMaybe<Scalars['ObjectId']>;
  id: Scalars['ObjectId'];
  reminders?: InputMaybe<WorkSheetRemindersInput>;
  title?: InputMaybe<Scalars['String']>;
  topicId?: InputMaybe<Scalars['ObjectId']>;
  workSheetUrl?: InputMaybe<Scalars['String']>;
};

export enum UploadFilePath {
  ImagesAvatar = 'ImagesAvatar',
  ImagesCourse = 'ImagesCourse',
  ImagesLog = 'ImagesLog',
  ImagesReward = 'ImagesReward',
  ReminderFiles = 'ReminderFiles'
}

export type UploadMailMergeMergeListInput = {
  file: Scalars['Upload'];
  id: Scalars['ObjectId'];
};

export type UpsertUserCustomiseColumnInput = {
  columnName: Scalars['String'];
  columnShowName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ObjectId']>;
  isRequired: Scalars['Boolean'];
  isVisible: Scalars['Boolean'];
  ownerId?: InputMaybe<Scalars['ObjectId']>;
  tableName: Scalars['String'];
  tableShowName?: InputMaybe<Scalars['String']>;
  weight: Scalars['Int'];
};

export type UseRewardPointsInput = {
  orderDetail: RewardDetailInput;
  points: Scalars['Int'];
  remarks?: InputMaybe<Scalars['String']>;
  studentId: Scalars['ObjectId'];
};

export type User = {
  __typename?: 'User';
  _id: Scalars['ObjectId'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  isArchived: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  ref?: Maybe<UserProfile>;
  sub?: Maybe<Scalars['String']>;
  userRoles?: Maybe<Array<UserRole>>;
};

export type UserAddress = {
  __typename?: 'UserAddress';
  country?: Maybe<Scalars['String']>;
  gateBuilding?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  streetAddress?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
};

export type UserAddressInput = {
  country?: InputMaybe<Scalars['String']>;
  gateBuilding?: InputMaybe<Scalars['String']>;
  link?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  streetAddress?: InputMaybe<Scalars['String']>;
  unit?: InputMaybe<Scalars['String']>;
};

export type UserAddressOutput = {
  __typename?: 'UserAddressOutput';
  country?: Maybe<Scalars['String']>;
  gateBuilding?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  streetAddress?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
};

export type UserCustomiseColumn = {
  __typename?: 'UserCustomiseColumn';
  _id: Scalars['ObjectId'];
  columnName: Scalars['String'];
  columnShowName?: Maybe<Scalars['String']>;
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  isDeleted: Scalars['Boolean'];
  isRequired: Scalars['Boolean'];
  isVisible: Scalars['Boolean'];
  lastModifiedBy?: Maybe<User>;
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  lastModifiedUser?: Maybe<User>;
  ownerId?: Maybe<Scalars['ObjectId']>;
  tableName: Scalars['String'];
  tableShowName?: Maybe<Scalars['String']>;
  weight: Scalars['Float'];
};

export type UserCustomiseColumnFilterInput = {
  columnName?: InputMaybe<Scalars['String']>;
  columnShowName?: InputMaybe<Scalars['String']>;
  isRequired?: InputMaybe<Scalars['Boolean']>;
  isVisible?: InputMaybe<Scalars['Boolean']>;
  ownerId?: InputMaybe<Scalars['ObjectId']>;
  tableName?: InputMaybe<Scalars['String']>;
  tableShowName?: InputMaybe<Scalars['String']>;
  weight?: InputMaybe<Scalars['Int']>;
};

export type UserCustomiseColumnPage = {
  __typename?: 'UserCustomiseColumnPage';
  items: Array<UserCustomiseColumn>;
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export type UserFilterInput = {
  ref?: InputMaybe<Scalars['ObjectId']>;
  userRole?: InputMaybe<UserRole>;
};

export type UserPage = {
  __typename?: 'UserPage';
  items: Array<User>;
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export type UserProfile = Admin | Parent | Student | Teacher;

export enum UserRole {
  Admin = 'Admin',
  Parent = 'Parent',
  Student = 'Student',
  SuperAdmin = 'SuperAdmin',
  Teacher = 'Teacher',
  TeacherSupervisor = 'TeacherSupervisor'
}

export type ValidateTeacherAndVenueInput = {
  dateTimeRanges: Array<DateTimeRangeInput>;
  excludeClassId?: InputMaybe<Scalars['ObjectId']>;
  excludeLessonId?: InputMaybe<Scalars['ObjectId']>;
  teacherId?: InputMaybe<Scalars['ObjectId']>;
  venueId?: InputMaybe<Scalars['ObjectId']>;
};

export type ValidateWhatsappNumberInput = {
  accountId?: InputMaybe<Scalars['ObjectId']>;
  number: TelephoneInput;
};

export type Venue = {
  __typename?: 'Venue';
  _id: Scalars['ObjectId'];
  address: Address;
  branch: Branch;
  capacity?: Maybe<Scalars['Float']>;
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  isArchived: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<User>;
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  lastModifiedUser?: Maybe<User>;
  name: Scalars['String'];
  systemId?: Maybe<Scalars['String']>;
};

export type VisibilityFinanceInput = {
  id: Scalars['ObjectId'];
  isVisible: Scalars['Boolean'];
};

export type Visible = {
  isVisible: Scalars['Boolean'];
};

export type VoidInput = {
  id: Scalars['ObjectId'];
  voidRemarks?: InputMaybe<Scalars['String']>;
};

export type VoidPaymentRecordInput = {
  paymentId: Scalars['ObjectId'];
  remarks?: InputMaybe<Scalars['String']>;
};

export enum VoidableStatus {
  Normal = 'Normal',
  Void = 'Void'
}

export enum WeekDay {
  Friday = 'Friday',
  Monday = 'Monday',
  Saturday = 'Saturday',
  Sunday = 'Sunday',
  Thursday = 'Thursday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday'
}

export type WhatsappAccount = {
  __typename?: 'WhatsappAccount';
  _id: Scalars['ObjectId'];
  contacts?: Maybe<Array<WhatsappContact>>;
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  currentUserId?: Maybe<Scalars['ObjectId']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  hasFailedMessages?: Maybe<Scalars['Boolean']>;
  isArchived: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<User>;
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  lastModifiedUser?: Maybe<User>;
  lastSentDateTime?: Maybe<Scalars['DateTime']>;
  lastSentOffset?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  number?: Maybe<Telephone>;
  platform?: Maybe<ReminderType>;
  processingStatus: WhatsappProcessingStatus;
  status: WhatsappAccountStatus;
  statusDate?: Maybe<Scalars['DateTime']>;
  statusDetail?: Maybe<Scalars['String']>;
};

export type WhatsappAccountFilterInput = {
  accountIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  processingStatus?: InputMaybe<Array<WhatsappProcessingStatus>>;
  status?: InputMaybe<Array<WhatsappAccountStatus>>;
};

export type WhatsappAccountPage = {
  __typename?: 'WhatsappAccountPage';
  items: Array<WhatsappAccount>;
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export enum WhatsappAccountStatus {
  Handoff = 'Handoff',
  Offline = 'Offline',
  Online = 'Online',
  Warning = 'Warning'
}

export type WhatsappContact = {
  __typename?: 'WhatsappContact';
  name: Scalars['String'];
  number: Scalars['String'];
};

export type WhatsappMessage = {
  __typename?: 'WhatsappMessage';
  _id: Scalars['ObjectId'];
  arrangement?: Maybe<Arrangement>;
  attachmentStatus?: Maybe<WhatsappMessageStatus>;
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  failedCount?: Maybe<Scalars['Int']>;
  flowType?: Maybe<TemplateArrangementType>;
  isDeleted: Scalars['Boolean'];
  isQueueNext?: Maybe<Scalars['Boolean']>;
  lastModifiedBy?: Maybe<User>;
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  lastModifiedUser?: Maybe<User>;
  message: Scalars['String'];
  parent?: Maybe<Parent>;
  platform: ReminderType;
  queueOrder?: Maybe<Scalars['Int']>;
  recipient: Scalars['String'];
  registration?: Maybe<Registration>;
  sender: WhatsappAccount;
  sentDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<WhatsappMessageStatus>;
  statusDate?: Maybe<Scalars['DateTime']>;
  template?: Maybe<Template>;
};

export type WhatsappMessageCounts = {
  __typename?: 'WhatsappMessageCounts';
  cancelled: Scalars['Int'];
  delivered: Scalars['Int'];
  failed: Scalars['Int'];
  paused: Scalars['Int'];
  processing: Scalars['Int'];
  queued: Scalars['Int'];
  read: Scalars['Int'];
  sent: Scalars['Int'];
};

export type WhatsappMessageFilterInput = {
  createDate?: InputMaybe<DateTimeRangeInput>;
  senderId?: InputMaybe<Scalars['ObjectId']>;
  status?: InputMaybe<Array<WhatsappMessageStatus>>;
};

export type WhatsappMessagePage = {
  __typename?: 'WhatsappMessagePage';
  items: Array<WhatsappMessage>;
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export enum WhatsappMessageStatus {
  Cancelled = 'Cancelled',
  Delivered = 'Delivered',
  Failed = 'Failed',
  Paused = 'Paused',
  Processing = 'Processing',
  Queued = 'Queued',
  Read = 'Read',
  Sent = 'Sent'
}

export enum WhatsappProcessingStatus {
  Paused = 'Paused',
  Ready = 'Ready'
}

export type WhatsappReminderInput = {
  flowType?: InputMaybe<TemplateArrangementType>;
  isIncludeAttachment?: InputMaybe<Scalars['Boolean']>;
  message?: InputMaybe<Scalars['String']>;
  receiverParentIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  senderId?: InputMaybe<Scalars['ObjectId']>;
  templateId: Scalars['ObjectId'];
};

export type WithdrawReasons = {
  __typename?: 'WithdrawReasons';
  otherReason?: Maybe<Scalars['String']>;
  reasons?: Maybe<Array<SelectedReason>>;
  remarks?: Maybe<Scalars['String']>;
  suggestion?: Maybe<Scalars['String']>;
};

export type WithdrawReasonsInput = {
  otherReason?: InputMaybe<Scalars['String']>;
  reasons?: InputMaybe<Array<SelectedReasonInput>>;
  remarks?: InputMaybe<Scalars['String']>;
  suggestion?: InputMaybe<Scalars['String']>;
};

export type WorkSheet = {
  __typename?: 'WorkSheet';
  _id: Scalars['ObjectId'];
  abbreviation: Scalars['String'];
  academicYear?: Maybe<AcademicYear>;
  academicYearId: Scalars['ObjectId'];
  course?: Maybe<Course>;
  courseId: Scalars['ObjectId'];
  createDateTime: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  isArchived: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<User>;
  lastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  lastModifiedUser?: Maybe<User>;
  title: Scalars['String'];
  topic?: Maybe<Topic>;
  topicId?: Maybe<Scalars['ObjectId']>;
  workSheetUrl?: Maybe<Scalars['String']>;
};

export type WorkSheetItemInput = {
  academicLevelId?: InputMaybe<Scalars['ObjectId']>;
  /** Filter by Academic Year ID */
  academicYearId?: InputMaybe<Scalars['ObjectId']>;
  courseId?: InputMaybe<Scalars['ObjectId']>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  topicId?: InputMaybe<Scalars['ObjectId']>;
  workSheetId?: InputMaybe<Scalars['ObjectId']>;
};

export type WorkSheetPage = {
  __typename?: 'WorkSheetPage';
  items: Array<WorkSheet>;
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export type WorkSheetReminderInput = {
  regular?: InputMaybe<WorksheetReminderSubTypeInput>;
  trial?: InputMaybe<WorksheetReminderSubTypeInput>;
};

export type WorkSheetRemindersInput = {
  portal?: InputMaybe<WorkSheetReminderInput>;
  whatsApp?: InputMaybe<WorkSheetReminderInput>;
};

export type WorksheetReminderSubTypeInput = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
};

export type XeroAccount = {
  __typename?: 'XeroAccount';
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<AccountType>;
};

export type XeroInvoiceDateInput = {
  month?: InputMaybe<Scalars['Int']>;
  year?: InputMaybe<Scalars['Int']>;
};

export type XeroInvoiceInfo = {
  __typename?: 'XeroInvoiceInfo';
  amount?: Maybe<Scalars['Decimal']>;
  lastSyncedDate?: Maybe<Scalars['DateTime']>;
  month: Scalars['Int'];
  url?: Maybe<Scalars['String']>;
  xeroInvoiceId?: Maybe<Scalars['String']>;
  year: Scalars['Int'];
};

export type XeroPrepayment = {
  __typename?: 'XeroPrepayment';
  allocatedAmount?: Maybe<Scalars['Decimal']>;
  amount: Scalars['Decimal'];
  id?: Maybe<Scalars['String']>;
  lastSyncedDate?: Maybe<Scalars['DateTime']>;
  lineItemId: Scalars['ObjectId'];
};

export enum XeroSyncStatus {
  Completed = 'Completed',
  Failed = 'Failed',
  Pending = 'Pending'
}

export type XeroTenant = {
  __typename?: 'XeroTenant';
  authEventId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  tenantId?: Maybe<Scalars['String']>;
  tenantName?: Maybe<Scalars['String']>;
  tenantType?: Maybe<Scalars['Float']>;
};

export type GetEligibilityFlowWithCourseIdQueryVariables = Exact<{
  courseId: Scalars['ObjectId'];
}>;


export type GetEligibilityFlowWithCourseIdQuery = { __typename?: 'Query', getEligibilityFlowWithCourseId?: { __typename: 'EligibilityFlow', _id: any, courseId: any } | null | undefined };

export type GetAcademicLevelsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAcademicLevelsQuery = { __typename?: 'Query', academicLevels?: Array<{ __typename?: 'AcademicLevel', key: any, label: string }> | null | undefined };

export type CreateEligibilityFlowMutationVariables = Exact<{
  input: CreateEligibilityFlowInput;
}>;


export type CreateEligibilityFlowMutation = { __typename?: 'Mutation', createEligibilityFlow?: { __typename?: 'EligibilityFlow', _id: any, courseId: any, createDateTime: any, deletedAt?: any | null | undefined, isDeleted: boolean, lastModifiedDateTime?: any | null | undefined } | null | undefined };


export const GetEligibilityFlowWithCourseIdDocument = gql`
    query getEligibilityFlowWithCourseId($courseId: ObjectId!) {
  getEligibilityFlowWithCourseId(courseId: $courseId) {
    _id
    courseId
    __typename
  }
}
    `;

/**
 * __useGetEligibilityFlowWithCourseIdQuery__
 *
 * To run a query within a React component, call `useGetEligibilityFlowWithCourseIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEligibilityFlowWithCourseIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEligibilityFlowWithCourseIdQuery({
 *   variables: {
 *      courseId: // value for 'courseId'
 *   },
 * });
 */
export function useGetEligibilityFlowWithCourseIdQuery(baseOptions: Apollo.QueryHookOptions<GetEligibilityFlowWithCourseIdQuery, GetEligibilityFlowWithCourseIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEligibilityFlowWithCourseIdQuery, GetEligibilityFlowWithCourseIdQueryVariables>(GetEligibilityFlowWithCourseIdDocument, options);
      }
export function useGetEligibilityFlowWithCourseIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEligibilityFlowWithCourseIdQuery, GetEligibilityFlowWithCourseIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEligibilityFlowWithCourseIdQuery, GetEligibilityFlowWithCourseIdQueryVariables>(GetEligibilityFlowWithCourseIdDocument, options);
        }
export type GetEligibilityFlowWithCourseIdQueryHookResult = ReturnType<typeof useGetEligibilityFlowWithCourseIdQuery>;
export type GetEligibilityFlowWithCourseIdLazyQueryHookResult = ReturnType<typeof useGetEligibilityFlowWithCourseIdLazyQuery>;
export type GetEligibilityFlowWithCourseIdQueryResult = Apollo.QueryResult<GetEligibilityFlowWithCourseIdQuery, GetEligibilityFlowWithCourseIdQueryVariables>;
export const GetAcademicLevelsDocument = gql`
    query getAcademicLevels {
  academicLevels(showArchived: true, showHided: true) {
    key: _id
    label: name
  }
}
    `;

/**
 * __useGetAcademicLevelsQuery__
 *
 * To run a query within a React component, call `useGetAcademicLevelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAcademicLevelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAcademicLevelsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAcademicLevelsQuery(baseOptions?: Apollo.QueryHookOptions<GetAcademicLevelsQuery, GetAcademicLevelsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAcademicLevelsQuery, GetAcademicLevelsQueryVariables>(GetAcademicLevelsDocument, options);
      }
export function useGetAcademicLevelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAcademicLevelsQuery, GetAcademicLevelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAcademicLevelsQuery, GetAcademicLevelsQueryVariables>(GetAcademicLevelsDocument, options);
        }
export type GetAcademicLevelsQueryHookResult = ReturnType<typeof useGetAcademicLevelsQuery>;
export type GetAcademicLevelsLazyQueryHookResult = ReturnType<typeof useGetAcademicLevelsLazyQuery>;
export type GetAcademicLevelsQueryResult = Apollo.QueryResult<GetAcademicLevelsQuery, GetAcademicLevelsQueryVariables>;
export const CreateEligibilityFlowDocument = gql`
    mutation createEligibilityFlow($input: CreateEligibilityFlowInput!) {
  createEligibilityFlow(input: $input) {
    _id
    courseId
    createDateTime
    deletedAt
    isDeleted
    lastModifiedDateTime
  }
}
    `;
export type CreateEligibilityFlowMutationFn = Apollo.MutationFunction<CreateEligibilityFlowMutation, CreateEligibilityFlowMutationVariables>;

/**
 * __useCreateEligibilityFlowMutation__
 *
 * To run a mutation, you first call `useCreateEligibilityFlowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEligibilityFlowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEligibilityFlowMutation, { data, loading, error }] = useCreateEligibilityFlowMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEligibilityFlowMutation(baseOptions?: Apollo.MutationHookOptions<CreateEligibilityFlowMutation, CreateEligibilityFlowMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEligibilityFlowMutation, CreateEligibilityFlowMutationVariables>(CreateEligibilityFlowDocument, options);
      }
export type CreateEligibilityFlowMutationHookResult = ReturnType<typeof useCreateEligibilityFlowMutation>;
export type CreateEligibilityFlowMutationResult = Apollo.MutationResult<CreateEligibilityFlowMutation>;
export type CreateEligibilityFlowMutationOptions = Apollo.BaseMutationOptions<CreateEligibilityFlowMutation, CreateEligibilityFlowMutationVariables>;