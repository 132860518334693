import { useApolloClient } from "@apollo/client";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import {
  useGetAcademicLevelsLazyQuery,
  useGetAcademicLevelsQuery,
} from "../generated/graphql";
import { useAppStore } from "../mobx";
import ContentTitle from "./ContentTitle";
import Flow from "./Flow";
import StartNode from "./StartNode/Node";

function ToolContent() {
  const { updateLevels } = useAppStore();
  const { data } = useGetAcademicLevelsQuery();
  useEffect(() => {
    if (data) {
      updateLevels(data.academicLevels ?? []);
    }
  }, [data]);
  return (
    <div
      className="bg-gray-300 flex flex-1 rounded-default flex-col p-6"
      style={{ minWidth: "600px" }}
    >
      <ContentTitle />
      <Flow />
    </div>
  );
}

export default observer(ToolContent);
