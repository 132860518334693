import {
  ApolloClient,
  ApolloLink,
  from,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from "@apollo/client";
let client: ApolloClient<NormalizedCacheObject> | null = null;
export let accessToken: string;
export let refreshToken: string;
export let expireDate: number;
export let isRereshing: boolean = false;

const authLink = (): ApolloLink => {
  return new ApolloLink((operation, forward) => {
    operation.setContext((context: Record<string, any>) => {
      const headers = {
        ...context.headers,
        Authorization: `Bearer ${accessToken}`,
      };
      return {
        headers: headers,
      };
    });
    return forward(operation);
  });
};

const httpLink = new HttpLink({
  // uri: "https://manage-develop.thepilab.com/api/graphql",
  uri: "http://localhost:7001/graphql",
});

const createApolloClient = (
  token: string
): ApolloClient<NormalizedCacheObject> => {
  accessToken = token;
  const links: ApolloLink[] = [authLink(), httpLink];
  client = new ApolloClient({
    link: from(links),
    cache: new InMemoryCache(),
  });
  return client;
};

export default createApolloClient;
