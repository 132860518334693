import { pull } from "lodash";
import { makeAutoObservable, runInAction, toJS } from "mobx";
import {
  RawNodeDatum,
  TreeNodeDatum,
} from "react-d3-tree/lib/types/types/common";
import {
  EligibilityFlowEnrollmentComparatorType,
  FlowEnrollmentCheckExpression,
  FlowLevelCheckExpression,
  FlowNode,
  FlowNodeType,
  FlowResult,
  LogicalOperator,
} from "../generated/graphql";

import { changeValueWithLevel, changeValueWithType } from "./utils";
// import { makePersistable } from "mobx-persist-store";

export enum LevelType {
  Level = "Level",
  TimePeriod = "Time Period",
  PreRequisite = "Pre-requisite",
  Enrollment = "Enrollment",
  ContentOverlaps = "Content Overlaps",
}
export interface LevelProps {
  label: string;
  key: string;
}
export default class AppStore {
  constructor() {
    // 建议使用这种方式，自动识别类型，不需要再加前缀
    makeAutoObservable(this);
  }
  currentNodes: RawNodeDatum = {
    name: "start",
    children: [],
    attributes: {
      type: "",
      levelCheckExpressions: "",
      enrollmentCheckExpressions: "",
    },
  };
  levels: LevelProps[] = [];
  currentCourseId: string = "623c35d5f5a7f80038c5be49";
  enrollmentCheckExpressions: FlowEnrollmentCheckExpression[] = [];
  failNode: FlowNode | null = null;
  levelCheckExpressions: FlowLevelCheckExpression[] = [];
  ogicalOperator: LogicalOperator | null = null;
  passNode: FlowNode | null = null;
  result: FlowResult | null = null;
  type: FlowNodeType | null = null;
  showLevelDropDown: boolean = false;
  enrollments = [
    {
      label: "Attended Before",
      key: EligibilityFlowEnrollmentComparatorType.AttendanceBefore,
    },
    {
      label: "Currently Attending",
      key: EligibilityFlowEnrollmentComparatorType.CurrentlyAttending,
    },
    {
      label: "Never Attended",
      key: EligibilityFlowEnrollmentComparatorType.NeverAttended,
    },
    {
      label: "Will Attend",
      key: EligibilityFlowEnrollmentComparatorType.WillAttend,
    },
  ];
  updateLevels = (newlevels: LevelProps[]) => {
    this.levels = newlevels;
  };

  setLevelDropDown = (show: boolean) => {
    this.showLevelDropDown = show;
  };
  setType = (type: LevelType) => {
    switch (type) {
      case LevelType.Level:
        this.type = FlowNodeType.Level;
        break;
      case LevelType.Enrollment:
        this.type = FlowNodeType.Enrollment;
        break;
      default:
        break;
    }
  };
  searchTree = (
    element: RawNodeDatum,
    matchingTitle: string
  ): RawNodeDatum | null => {
    if (element.name === matchingTitle) {
      return element;
    } else if (element.children != null) {
      var i;
      var result = null;
      for (i = 0; result == null && i < element.children.length; i++) {
        result = this.searchTree(element.children[i], matchingTitle);
      }
      return result;
    }
    return null;
  };
  addlevelCheckExpressions = (id: string) => {
    this.levelCheckExpressions = [
      ...this.levelCheckExpressions,
      { academicLevelId: id },
    ];
  };
  addFlowStep = (type: FlowNodeType, parentId: string, _id: string) => {};

  updateNodeWithType = (nodeDatum: TreeNodeDatum, newType: FlowNodeType) => {
    const node = { ...this.currentNodes };
    const newNode = changeValueWithType(
      toJS(node),
      nodeDatum.name,
      newType as string
    );
    if (newNode) {
      this.currentNodes = newNode;
      console.log("newNode====================================");
      console.log(toJS(newNode));
      console.log("====================================");
    }
  };
  updateNodeWithLevelId = (nodeDatum: TreeNodeDatum, newLevel: string) => {
    // let currentNode = this.searchTree(this.currentNodes, nodeDatum.name);
    const node = { ...this.currentNodes };
    const newNode = changeValueWithLevel(
      toJS(node),
      "levelCheckExpressions",
      nodeDatum.name,
      newLevel
    );
    if (newNode) {
      this.currentNodes = newNode;
      console.log("newNode====================================");
      console.log(toJS(newNode));
      console.log("====================================");
    }
  };
  updateNodeWithEnrollmentId = (nodeDatum: TreeNodeDatum, newLevel: string) => {
    // let currentNode = this.searchTree(this.currentNodes, nodeDatum.name);
    const node = { ...this.currentNodes };
    const newNode = changeValueWithLevel(
      toJS(node),
      "enrollmentCheckExpressions",
      nodeDatum.name,
      newLevel
    );
    console.log("newNode====================================");
    console.log(toJS(newNode));
    console.log("====================================");
    if (newNode) {
      this.currentNodes = newNode;
    }
  };
}
export const appStore = new AppStore();
